import RateDisplay from "@components/Cards/RateDisplay";
import useFormat from "@lib/hooks/use-format";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import React from "react";
import { getTotalValueOfAllRows, getCorrelation } from "./calculations";

type Props<T> = {
  compared?: boolean;
} & (
  | {
      info: CellContext<T, number>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number>;
      calculateTotals: true;
    }
);

export type CurrencyCalculatorType = (
  value: number,
  numDigits?: number
) => string;

const getDisplayString = (
  value: number,
  formatNumber: CurrencyCalculatorType
) => {
  return formatNumber(value, 0);
};

function PageviewTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
}: Props<T>) {
  const { formatNumber } = useFormat();
  let comparedValue = 0;
  let value = 0;
  if (calculateTotals) {
    const allValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value: row.original?.[info.column.id],
        compared: row.original?.compared?.[info.column?.id],
      };
    });
    const totals = getTotalValueOfAllRows({
      values: allValues,
      roundBeforeAdding: true,
    });

    value = totals.value;
    comparedValue = totals.compared ?? 0;
  } else {
    value = info.getValue();
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }
  }
  if (compared) {
    const rate = getCorrelation(value, comparedValue);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1">
          <p>{getDisplayString(value, formatNumber)}</p>
          <p className="text-[11px] leading-tight opacity-70">
            vs {getDisplayString(comparedValue, formatNumber)}
          </p>
        </div>
        <RateDisplay rate={rate} isIncreasing={rate > 0} size="small" />
      </div>
    );
  }
  return getDisplayString(value, formatNumber);
}

export default PageviewTableDisplay;
