import {
  ColumnItemType,
  TableTypes,
} from "@components/Commons/ColumnSelector/ColumnSelectorDialog";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { JSONreplacer, JSONreviver } from "@lib/util-functions/string";
import { dashboardModeAtom, selectedColumnsAtom } from "atoms";
import {
  AVAILABLE_COLUMNS_PER_TABLE,
  COLUMN_OPTIONS,
  GENERAL_COLUMN_OPTIONS,
  maxMetricCountForTables,
} from "constants/constants";
import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import useSelectedStores from "./use-selected-stores";
import { useAuth } from "@lib/api-hooks";

type Props = {
  table: TableTypes;
};

const useSelectedColumns = ({ table }: Props) => {
  const { selectedOrganisationData } = useSelectedStores();
  const { data } = useAuth();
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const columnOptions =
    dashboardMode === "general" ? GENERAL_COLUMN_OPTIONS : COLUMN_OPTIONS;
  const columnsToExclude: Array<(typeof columnOptions)[number]["value"]> =
    useMemo(() => {
      const columns: Array<(typeof columnOptions)[number]["value"]> = [];
      if (
        !selectedOrganisationData?.featureFlags?.google_conversions_enabled &&
        !data?.payload?.ema.includes("@tracify.ai")
      ) {
        columns.push("platformConversions", "allPlatformConversions");
      }
      // nothing to exclude atm but keep for future use
      return columns;
    }, [
      data?.payload?.ema,
      selectedOrganisationData?.featureFlags?.google_conversions_enabled,
    ]);

  const allowedTableValues = useMemo(
    () =>
      AVAILABLE_COLUMNS_PER_TABLE.get(table)?.filter(
        (el) => !columnsToExclude.includes(el)
      ),
    [columnsToExclude, table]
  );

  const filteredTableOptions = useMemo(
    () =>
      allowedTableValues
        ? columnOptions.filter((el) => allowedTableValues?.includes(el.value))
        : columnOptions,
    [allowedTableValues, columnOptions]
  );

  const [selectedColumns, setSelectedColumns] = useAtom(selectedColumnsAtom);

  const selectedColumnsOrder = useMemo<ColumnItemType[]>(() => {
    const columns =
      (selectedColumns
        .get(table)
        ?.map((el) => {
          const elInOptions = filteredTableOptions.find(
            (option) => option.value === el
          );
          if (!elInOptions) return null;
          return {
            ...elInOptions,
            id: elInOptions?.value,
          } as ColumnItemType;
        })
        .filter((el) => Boolean(el)) as ColumnItemType[]) ?? [];
    if (
      (!columns || columns.length > maxMetricCountForTables[table]) &&
      (table === "facebookCreativeAnalysis" || table === "creativeAnalysis")
    ) {
      return filteredTableOptions
        .map((option) => {
          if (
            [
              "spend",
              "purchaseCount",
              "purchaseAmount",
              "impressions",
              "ctr",
            ].includes(option.value)
          ) {
            return {
              ...option,
              id: option?.value,
            } as ColumnItemType;
          }
        })
        .filter((el) => Boolean(el)) as ColumnItemType[];
    }
    return columns;
  }, [filteredTableOptions, selectedColumns, table]);

  const additionalMetrics = useMemo<ColumnItemType[]>(
    () =>
      filteredTableOptions
        .filter((el) => !selectedColumns.get(table)?.includes(el.value))
        .map((el) => ({ ...el, id: el.value })),
    [filteredTableOptions, selectedColumns, table]
  );

  const selectedColumnsForTable = useMemo(
    () =>
      selectedColumns
        .get(table)
        ?.filter((el) => !columnsToExclude.includes(el)) ?? [],
    [columnsToExclude, selectedColumns, table]
  );

  useEffect(() => {
    const localColumns = getLocalStorage()?.getItem(`selectedColumns`);
    const defaultOrder = [
      "spend",
      "purchaseAmount",
      "roas",
      "purchaseCount",
      "cac",
      "cpo",
      "aov",
      "cr",
      "addtocart",
      "productview",
      "pageview",
    ];

    let parsedColumns: Map<TableTypes, Array<ColumnItemType>> | undefined;
    if (localColumns) {
      parsedColumns = JSON.parse(localColumns, JSONreviver);
    }
    if (parsedColumns instanceof Map) {
      const currentTable = parsedColumns.get(table);
      if (currentTable && currentTable.length > 0) {
        const addedColumns: string[] = [];
        const filteredColumns = currentTable
          ?.map((column) => {
            let includedOption: ColumnItemType | undefined;
            for (const option of filteredTableOptions) {
              if (option.value === column.value) {
                includedOption = {
                  label: option.label,
                  id: option.value,
                  value: option.value,
                } as ColumnItemType;
                break;
              }
            }
            if (
              includedOption &&
              !addedColumns.includes(includedOption.value)
            ) {
              addedColumns.push(includedOption.value);
              return includedOption;
            }
          })
          .filter((el) => !!el?.value) as ColumnItemType[];

        const orderMap = new Map(
          defaultOrder.map((item, index) => [item, index])
        );

        if (filteredColumns.length > 0) {
          parsedColumns.set(table, filteredColumns);
          getLocalStorage()?.setItem(
            `selectedColumns`,
            JSON.stringify(parsedColumns, JSONreplacer)
          );
        }

        const columnValueArray = filteredColumns?.map((el) => el.value);
        setSelectedColumns((current) => {
          const map = new Map([...current]);
          map.set(table, columnValueArray);
          return map;
        });
      } else {
        parsedColumns?.set(table, selectedColumnsOrder);
        getLocalStorage()?.setItem(
          `selectedColumns`,
          JSON.stringify(parsedColumns, JSONreplacer)
        );
      }
    } else {
      const orderMap = new Map(
        defaultOrder.map((item, index) => [item, index])
      );

      const initialSetup = [...selectedColumnsOrder].sort((a, b) => {
        // Get the order index from orderMap, use a large number if not found to sort unknowns at the end
        const orderA = orderMap.get(a.value) ?? Number.MAX_SAFE_INTEGER;
        const orderB = orderMap.get(b.value) ?? Number.MAX_SAFE_INTEGER;

        return orderA - orderB;
      });

      const newState = new Map([[table, initialSetup]]);
      getLocalStorage()?.setItem(
        `selectedColumns`,
        JSON.stringify(newState, JSONreplacer)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetNewColumnOrder = (
    columns: {
      value: (typeof filteredTableOptions)[number]["value"];
      label: (typeof filteredTableOptions)[number]["label"];
      id: (typeof filteredTableOptions)[number]["value"];
    }[]
  ) => {
    const localColumns = getLocalStorage()?.getItem(`selectedColumns`);
    if (localColumns) {
      const currentState: Map<TableTypes, Array<ColumnItemType>> = JSON.parse(
        localColumns,
        JSONreviver
      );
      currentState?.set(table, columns);

      getLocalStorage()?.setItem(
        `selectedColumns`,
        JSON.stringify(currentState, JSONreplacer)
      );
    } else {
      const newState = new Map([[table, columns]]);
      getLocalStorage()?.setItem(
        `selectedColumns`,
        JSON.stringify(newState, JSONreplacer)
      );
    }

    setSelectedColumns((current) => {
      const map = new Map([...current]);
      map.set(
        table,
        columns.map((el) => el.value)
      );
      return map;
    });
  };

  return {
    selectedColumns: selectedColumnsForTable,
    selectedColumnsOrder,
    additionalMetrics,
    setSelectedColumns,
    onSetNewColumnOrder,
    filteredTableOptions,
  };
};

export default useSelectedColumns;
