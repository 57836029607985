import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToInfluencers } from "./transformations";
import { Influencer } from "./types";
import { ChiefAPIError } from "@api/types/backendTypes";
import useSelectedStores from "@lib/hooks/use-selected-stores";

export const UseInfluencersPropsSchema = z.object({
  organisationId: z.string({ required_error: "Organisation ID is required." }),
  csids: z.array(z.string()).optional(),
});

export const FetchInfluencersSchema = UseInfluencersPropsSchema.extend({
  token: z.string({ required_error: "User Session not available" }),
});

export type UseInfluencersProps = z.infer<typeof UseInfluencersPropsSchema>;

export type FetchInfluencersProps = z.infer<typeof FetchInfluencersSchema>;

export async function fetchInfluencers({
  token,
  organisationId,
  csids,
}: FetchInfluencersProps): Promise<Array<Influencer>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  if (!organisationId) {
    throw new Error("Organisation ID is required.");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/list?organisation_id=${organisationId}`,
      {
        params: {
          limit: 100000,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return transformToInfluencers(response.data.items ?? [], csids);
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ChiefAPIError;
        const firstErr = apiError.detail[0];
        throw new Error(firstErr?.msg ?? axiosError.message);
      }
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseInfluencersOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Influencer>) => void) | undefined;
};

export function useInfluencers(
  props: UseInfluencersProps,
  options?: UseInfluencersOptions
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  return useQuery({
    queryKey: [
      "influencers",
      { organisationId: props.organisationId, selectedStoreIds },
    ],
    queryFn: () =>
      fetchInfluencers({
        ...props,
        token: data?.token!,
        csids: props.csids ? props.csids : selectedStoreIds,
      }),

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5000, // 5 seconds needed to overwrite global settings
    gcTime: 5000, // 5 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
