/* eslint-disable no-unused-vars */
export interface AdsFilterInterface {
  id: string;
  label: string;
  inputLabel?: string;
  value: any;
  betweenValue?: any;
  filter: FilterTypesEnum;
  index: number;
}

export enum FilterTypesEnum {
  equals = "equals",
  lt = "lt",
  gt = "gt",
  in = "in",
  contains = "contains",
  contains_not = "contains_not",
  between = "between",
  not = "not",
}
