export function flatten(array: any[]): any[] {
  return array.reduce((acc, e) => {
    if (Array.isArray(e)) {
      // if the element is an array, fall flatten on it again and then take the returned value and concat it.
      return acc.concat(flatten(e));
    } else {
      // otherwise just concat the value.
      return acc.concat(e);
    }
  }, []); // initial value for the accumulator is []
}

export function getRoasLabel(newVsReturning: string) {
  const prefix = newVsReturning === "new" ? "Acquisition " : "";

  return `${prefix} ROAS`;
}

export const getTotalValue = <T>(data: T[], field: keyof T) => {
  return data.reduce((acc, curr) => acc + Number(curr[field] ?? 0), 0);
};
