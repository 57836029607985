import { SnippetTypes } from "@lib/util-types";

export type TooltipColors = {
  bgColor: string;
  borderColor: string;
  color: string;
};

export const getColors = (type: SnippetTypes): TooltipColors => {
  const colors: { [key in SnippetTypes]: string } = {
    default: "bg-gray-200 dark:bg-gray-700",
    primary: "bg-primary",
    warning: "bg-yellow-500",
    error: "bg-red-500",
    secondary: "bg-secondary",
    dark: "bg-foreground",
    lite: "bg-gray-200 dark:bg-gray-700",
  };
  const borderColors: { [key in SnippetTypes]: string } = {
    default: "border-transparent border-b-gray-700",
    primary: "border-transparent border-b-primary",
    warning: "border-transparent border-b-yellow-500",
    error: "border-transparent border-b-red-500",
    secondary: "border-transparent border-b-secondary",
    dark: "border-transparent border-b-foreground",
    lite: "border-transparent border-b-gray-700",
  };
  const color =
    type === "lite" || type === "default"
      ? "text-foreground"
      : "text-background";

  return {
    color,
    bgColor: colors[type],
    borderColor: borderColors[type],
  };
};
