import { useSettings } from "@lib/api-hooks/useSettings/useSettings";
import React, { useEffect, useState } from "react";

const availableLocale = [
  {
    value: "en-US",
    name: "Decimal point",
    example: "1,000,000.00",
    suffix: false,
  },
  {
    value: "de-DE",
    name: "Decimal comma",
    example: "1.000.000,00",
    suffix: true,
  },
];

function useLocale() {
  const { settingsQuery, updateSettings } = useSettings();

  const { data, isLoading } = settingsQuery;

  const handleChangeLocale = async (locale: string) => {
    const response = await updateSettings({ ...data, number_format: locale });

    if (response && response.data) {
      settingsQuery.refetch();
    }
  };

  return {
    availableLocale,
    currentLocale: data?.number_format,
    handleChangeLocale,
    isLocaleLoading: isLoading,
  };
}

export default useLocale;
