/**
 * Check if object is empty or not.
 *
 * @param {Object} object
 * @return {boolean} boolean wheter object is empty or not
 */
export const isEmpty = (object: any) => {
  return (
    !object ||
    (Object.entries(object).length === 0 && object.constructor === Object)
  );
};

export function roughSizeOfObject(object: any) {
  const objectList = [];
  const stack = [object];
  let bytes = 0;

  while (stack.length) {
    const value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (value instanceof Map) {
      const array = Array.from(value.entries());

      // eslint-disable-next-line guard-for-in
      for (const i in array) {
        stack.push(array[i][0]);
        stack.push(array[i][1]);
      }
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      // eslint-disable-next-line guard-for-in
      for (const i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
}
