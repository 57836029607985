import React from "react";
import s from "./card.module.css";

interface Props {
  className?: string;
  tight?: boolean;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type CardContentProps = Props & NativeAttrs;

const CardContent: React.FC<React.PropsWithChildren<CardContentProps>> = ({
  className = "",
  children,
  tight = false,
  ...props
}) => {
  return (
    <div
      className={`${s.content} w-full ${
        tight ? "p-0" : "p-4 md:p-6"
      } ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default CardContent;
