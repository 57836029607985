import {
  BaseScore,
  BaseScoreWithCreative,
  CustomerJourneyBaseAnalytics,
} from "@api/types/backendTypes";
import {
  ADS_MANAGER_FILTERS,
  CONVERSION_PATH_FILTERS,
  CREATIVES_FILTERS,
  ECOMMERCE_TO_GENERAL_LABEL_MAPPING,
  STRINGS_TO_REMOVE_FROM_NAMES,
} from "constants/constants";
import {
  AdsFilterInterface,
  FilterTypesEnum,
} from "interface/AdsFilterInterface";
import levenshtein from "fast-levenshtein";
import { useAtom } from "jotai";
import { availableDashboardModes } from "@lib/hooks/constants";
export const simpleAdsGroupFilter = (source: BaseScoreWithCreative[]) => {
  let x;
  let y;
  const _source = source.map((el) => {
    let nameToMatch = el.name;
    for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
      nameToMatch = nameToMatch.replaceAll(string, "");
    }
    // remove all duplicate spaces
    nameToMatch = nameToMatch
      .split(" ")
      .filter((el) => el !== "")
      .join(" ");
    return { ...el, nameToMatch };
  });
  const matches = [];
  const matchedIds: string[] = [];
  for (x = _source.length - 1; x >= 0; x--) {
    const output = _source.splice(x, 1);
    for (y = _source.length - 1; y >= 0; y--) {
      if (
        output[0].nameToMatch.toLowerCase() ===
          _source[y].nameToMatch.toLowerCase() &&
        !matchedIds.includes(_source[y].refId)
      ) {
        output.push(_source[y]);
        matchedIds.push(_source[y].refId);
        _source.splice(y, 1);
        x--;
      }
    }
    matches.push(output);
  }
  return matches;
};

export const levenshteinNameFilter = (source: BaseScore[], maximum = 0) => {
  const _source = source.map((el) => {
    let name = el.name;
    for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
      name = name.replaceAll(string, "");
    }
    // remove all duplicate spaces
    name = name
      .split(" ")
      .filter((el) => el !== "")
      .join(" ");
    return name;
  });
  const matches = [];
  let x;
  let y;
  for (x = _source.length - 1; x >= 0; x--) {
    const output = _source.splice(x, 1);
    for (y = _source.length - 1; y >= 0; y--) {
      if (levenshtein.get(output[0], _source[y]) <= maximum) {
        output.push(_source[y]);
        _source.splice(y, 1);
        x--;
      }
    }
    matches.push(output);
  }
  return matches;
};

export const filterConversionPathsData = (
  data: CustomerJourneyBaseAnalytics["conversionPaths"][string][],
  selectedFilters: AdsFilterInterface[]
) => {
  let newData = structuredClone(data) as typeof data;
  selectedFilters.forEach((filter) => {
    if (filter.filter === FilterTypesEnum.contains) {
      const filteredData = Object.values(newData).filter((el) => {
        const key = filter.id as keyof typeof el;
        return typeof el[key] === "string"
          ? (el[key] as string)
              ?.toLowerCase()
              .includes(filter.value?.toLowerCase())
          : null;
      });
      return filteredData;
    } else if (FilterTypesEnum.between === filter.filter) {
      const filteredData = newData.filter((el) => {
        const key = filter.id as keyof typeof el;
        const multiplier = filter.inputLabel?.includes("%") ? 0.01 : 1;
        const value = el[key];
        if (typeof value !== "number") return false;
        return (
          value >=
            Math.min(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue) * multiplier
            ) &&
          value <=
            Math.max(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue) * multiplier
            )
        );
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.gt === filter.filter) {
      const filteredData = newData.filter((el) => {
        const key = filter.id as keyof typeof el;
        const multiplier = filter.inputLabel?.includes("%") ? 0.01 : 1;
        const value = el[key];
        if (typeof value !== "number") return false;
        return (
          value >
            Math.min(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue)
            ) &&
          value <=
            Math.max(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue)
            )
        );
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.lt === filter.filter) {
      const filteredData = newData.filter((el) => {
        const key = filter.id as keyof typeof el;
        const multiplier = filter.inputLabel?.includes("%") ? 0.01 : 1;
        const value = el[key];
        if (typeof value !== "number") return false;
        return (
          value >=
            Math.min(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue)
            ) &&
          value <
            Math.max(
              parseFloat(filter.value) * multiplier,
              parseFloat(filter.betweenValue)
            )
        );
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.equals === filter.filter) {
      if (
        filter.id === "firstTouchpoint" ||
        filter.id === "lastTouchpoint" ||
        filter.id === "path"
      ) {
        // these are select filters, which can have multiple values selected which are separated by a comma
        const allSelections = filter.value
          ?.toLowerCase()
          .split(",")
          .filter((el: string) => el !== "");

        const filteredData = newData.filter((el) => {
          const key = filter.id as keyof typeof el;
          if (key === "path") {
            let hasMatch = false;
            for (const selection of allSelections) {
              if (el[key].includes(selection)) {
                hasMatch = true;
                break;
              }
            }
            return hasMatch;
          }
          return allSelections.includes(`${el[key]}`?.toLowerCase());
        });
        newData = [...filteredData];
      } else {
        const filteredData = newData.filter((el) => {
          const key = filter.id as keyof typeof el;
          return `${el[key]}`?.toLowerCase() === filter.value?.toLowerCase();
        });
        newData = [...filteredData];
      }
    }
  });
  return newData;
};

export const filterAdsTableData = (
  data: { [key: string]: any }[],
  selectedFilters: AdsFilterInterface[],
  minMaxValues: {
    min: { [key: string]: number };
    max: { [key: string]: number };
  },
  tableType: string
) => {
  let newData = data ? [...data] : [];
  Object.values(selectedFilters).forEach((filterEl) => {
    const filter = { ...filterEl };
    if (filter.inputLabel === "%") {
      if (filter.value) {
        filter.value = filter.value / 100;
      }
      if (filter.betweenValue && filter.filter === "between") {
        filter.betweenValue = filter.betweenValue / 100;
      }
    }
    if (filter.filter === FilterTypesEnum.contains) {
      const filteredData = newData.filter((el) => {
        if (filter.id === "campaignIds") {
          if (tableType === "adsets" || tableType === "ads") {
            return (
              el.parentCampaign?.refId &&
              (filter.value.split(",").includes(el.parentCampaign?.refId) ||
                el.parentCampaign?.refId.includes(filter.value))
            );
          } else {
            return (
              el.refId &&
              (filter.value.split(",").includes(el.refId) ||
                el.refId.includes(filter.value))
            );
          }
        } else if (filter.id === "adsetIds") {
          if (tableType === "adsets") {
            return (
              el.refId &&
              (filter.value.split(",").includes(el.refId) ||
                el.refId.includes(filter.value))
            );
          } else if (tableType === "ads") {
            return (
              el.parentAdset?.refId &&
              (filter.value.split(",").includes(el.parentAdset?.refId) ||
                el.parentAdset?.refId.includes(filter.value))
            );
          }
        } else if (filter.id === "adIds") {
          if (tableType === "ads") {
            return (
              el.refId &&
              (filter.value.split(",").includes(el.refId) ||
                el.refId.includes(filter.value))
            );
          }
        } else if (filter.id === "campaigns") {
          const nameBool =
            el.type === "campaign" &&
            el.name?.toLowerCase().includes(filter.value?.toLowerCase());
          const parentNameBool = el.parentCampaign?.name
            ?.toLowerCase()
            .includes(filter.value?.toLowerCase());

          const groupNameBool = Boolean(
            el.campaigns?.find(
              (el: string) =>
                el?.toLowerCase().includes(filter.value?.toLowerCase())
            )
          );

          return nameBool || parentNameBool || groupNameBool;
        } else if (filter.id === "adsets") {
          const nameBool =
            el.type === "adset" &&
            el.name?.toLowerCase().includes(filter.value?.toLowerCase());
          const parentNameBool = el.parentAdset?.name
            ?.toLowerCase()
            .includes(filter.value?.toLowerCase());

          const groupNameBool = Boolean(
            el.adsets?.find(
              (el: string) =>
                el?.toLowerCase().includes(filter.value?.toLowerCase())
            )
          );

          let childNameBool = false;

          if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              if (childNameBool === false) {
                childNameBool = childAdset.name
                  ?.toLowerCase()
                  .includes(filter.value?.toLowerCase());
              }
            }
          }

          return nameBool || parentNameBool || childNameBool || groupNameBool;
        } else if (filter.id === "ads") {
          let nameBool =
            el.type === "ad" &&
            el.name?.toLowerCase().includes(filter.value?.toLowerCase());

          // necessary for Meta/TikTok Creatives Table ads which are grouped by name and have to show the same ads in the ads manager when clicked on
          if (!nameBool) {
            let removedName = el.name?.toLowerCase();
            for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
              removedName = removedName.replaceAll(string, "");
            }
            removedName = removedName
              .split(" ")
              .filter((el: string) => el !== "")
              .join(" ");
            nameBool = removedName.includes(filter.value?.toLowerCase());
          }

          let childNameBool = false;

          if (el.type === "adset") {
            for (const childAd of el.subRows) {
              if (childNameBool === false) {
                childNameBool = childAd.name
                  ?.toLowerCase()
                  .includes(filter.value?.toLowerCase());

                // necessary for Meta/TikTok Creatives Table ads which are grouped by name and have to show the same ads in the ads manager when clicked on
                if (childNameBool === false) {
                  let removedName = childAd.name?.toLowerCase();
                  for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
                    removedName = removedName.replaceAll(string, "");
                  }
                  removedName = removedName
                    .split(" ")
                    .filter((el: string) => el !== "")
                    .join(" ");
                  childNameBool = removedName.includes(
                    filter.value?.toLowerCase()
                  );
                }
              }
            }
          } else if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              for (const childAd of childAdset.subRows) {
                if (childNameBool === false) {
                  childNameBool = childAd.name
                    ?.toLowerCase()
                    .includes(filter.value?.toLowerCase());
                  if (childNameBool === false) {
                    let removedName = childAd.name?.toLowerCase();
                    for (const string of STRINGS_TO_REMOVE_FROM_NAMES) {
                      removedName = removedName.replaceAll(string, "");
                    }
                    removedName = removedName
                      .split(" ")
                      .filter((el: string) => el !== "")
                      .join(" ");
                    childNameBool = removedName.includes(
                      filter.value?.toLowerCase()
                    );
                  }
                }
              }
            }
          }

          return nameBool || childNameBool;
        } else if (filter.id === "advertisingChannelType") {
          if (tableType === "adsets" || tableType === "ads") {
            return (
              el.parentCampaign?.advertisingChannelType &&
              (filter.value
                .split(",")
                .includes(el.parentCampaign?.advertisingChannelType) ||
                el.parentCampaign?.advertisingChannelType.includes(
                  filter.value
                ))
            );
          } else {
            return (
              el.advertisingChannelType &&
              (filter.value.split(",").includes(el.advertisingChannelType) ||
                el.advertisingChannelType.includes(filter.value))
            );
          }
        }

        return el && typeof el[filter.id] === "string"
          ? el[filter.id]?.toLowerCase().includes(filter.value?.toLowerCase())
          : null;
      });
      newData = [...filteredData];
    } else if (filter.filter === FilterTypesEnum.contains_not) {
      const filteredData = newData.filter((el) => {
        if (filter.id === "campaigns") {
          const nameBool =
            el.type === "campaign" &&
            !el.name?.toLowerCase().includes(filter.value?.toLowerCase());
          if (el.type === "campaign") return nameBool;

          const parentNameBool = !el.parentCampaign?.name
            ?.toLowerCase()
            .includes(filter.value?.toLowerCase());
          return parentNameBool;
        } else if (filter.id === "adsets") {
          if (el.type === "adset")
            return !el.name
              ?.toLowerCase()
              .includes(filter.value?.toLowerCase());
          if (el.type === "ad") {
            return !el.parentAdset?.name
              ?.toLowerCase()
              .includes(filter.value?.toLowerCase());
          }

          let childNameBool = false;

          if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              if (childNameBool === false) {
                childNameBool = childAdset.name
                  ?.toLowerCase()
                  .includes(filter.value?.toLowerCase());
              }
            }
          }

          return !childNameBool;
        } else if (filter.id === "ads") {
          if (el.type === "ad") {
            return !el.name
              ?.toLowerCase()
              .includes(filter.value?.toLowerCase());
          }

          let childNameBool = false;

          if (el.type === "adset") {
            for (const childAd of el.subRows) {
              if (childNameBool === false) {
                childNameBool = childAd.name
                  ?.toLowerCase()
                  .includes(filter.value?.toLowerCase());
              }
            }
          } else if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              for (const childAd of childAdset.subRows) {
                if (childNameBool === false) {
                  childNameBool = childAd.name
                    ?.toLowerCase()
                    .includes(filter.value?.toLowerCase());
                }
              }
            }
          }

          return !childNameBool;
        }

        return !el[filter.id]
          ?.toLowerCase()
          .includes(filter.value?.toLowerCase());
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.between === filter.filter) {
      const filteredData = newData.filter((el) => {
        return (
          el[filter.id] >=
            Math.min(
              parseFloat(filter.value),
              parseFloat(filter.betweenValue)
            ) &&
          el[filter.id] <=
            Math.max(parseFloat(filter.value), parseFloat(filter.betweenValue))
        );
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.gt === filter.filter) {
      const filteredData = newData.filter((el) => {
        return (
          el[filter.id] >
            Math.min(
              parseFloat(filter.value),
              parseFloat(filter.betweenValue)
            ) &&
          el[filter.id] <=
            Math.max(parseFloat(filter.value), parseFloat(filter.betweenValue))
        );
      });
      newData = [...filteredData];
    } else if (FilterTypesEnum.lt === filter.filter) {
      const filteredData = newData.filter((el) => {
        return (
          el[filter.id] >=
            Math.min(
              parseFloat(filter.value),
              parseFloat(filter.betweenValue)
            ) &&
          el[filter.id] <
            Math.max(parseFloat(filter.value), parseFloat(filter.betweenValue))
        );
      });
      newData = [...filteredData];
    } else if (filter.filter === FilterTypesEnum.equals) {
      const filteredData = newData.filter((el) => {
        if (filter.id === "campaigns") {
          const nameBool =
            el.type === "campaign" &&
            el.name?.toLowerCase().trim() ===
              filter.value?.toLowerCase().trim();
          const parentNameBool =
            el.parentCampaign?.name?.toLowerCase().trim() ===
            filter.value?.toLowerCase().trim();

          return nameBool || parentNameBool;
        } else if (filter.id === "adsets") {
          const nameBool =
            el.type === "adset" &&
            el.name?.toLowerCase().trim() ===
              filter.value?.toLowerCase().trim();
          const parentNameBool =
            el.parentAdset?.name?.toLowerCase().trim() ===
            filter.value?.toLowerCase().trim();

          let childNameBool = false;

          if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              if (childNameBool === false) {
                childNameBool =
                  childAdset.name?.toLowerCase().trim() ===
                  filter.value?.toLowerCase().trim();
              }
            }
          }

          return nameBool || parentNameBool || childNameBool;
        } else if (filter.id === "ads") {
          const nameBool =
            el.type === "ad" &&
            el.name?.toLowerCase().trim() ===
              filter.value?.toLowerCase().trim();

          let childNameBool = false;

          if (el.type === "adset") {
            for (const childAd of el.subRows) {
              if (childNameBool === false) {
                childNameBool =
                  childAd.name?.toLowerCase().trim() ===
                  filter.value?.toLowerCase().trim();
              }
            }
          } else if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              for (const childAd of childAdset.subRows) {
                if (childNameBool === false) {
                  childNameBool =
                    childAd.name?.toLowerCase().trim() ===
                    filter.value?.toLowerCase().trim();
                }
              }
            }
          }

          return nameBool || childNameBool;
        }

        return (
          `${el[filter.id]}`?.toLowerCase() === filter.value?.toLowerCase()
        );
      });
      newData = [...filteredData];
    } else if (filter.filter === FilterTypesEnum.not) {
      const filteredData = newData.filter((el) => {
        if (filter.id === "campaigns") {
          const nameBool =
            el.type === "campaign" &&
            el.name?.toLowerCase().trim() !==
              filter.value?.toLowerCase().trim();
          const parentNameBool =
            el.parentCampaign?.name?.toLowerCase().trim() !==
            filter.value?.toLowerCase().trim();

          return nameBool || parentNameBool;
        } else if (filter.id === "adsets") {
          const nameBool =
            el.type === "adset" &&
            el.name?.toLowerCase().trim() !==
              filter.value?.toLowerCase().trim();
          const parentNameBool =
            el.parentAdset?.name?.toLowerCase().trim() !==
            filter.value?.toLowerCase().trim();

          let childNameBool = false;

          if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              if (childNameBool === false) {
                childNameBool =
                  childAdset.name?.toLowerCase().trim() !==
                  filter.value?.toLowerCase().trim();
              }
            }
          }

          return nameBool || parentNameBool || childNameBool;
        } else if (filter.id === "ads") {
          const nameBool =
            el.type === "ad" &&
            el.name?.toLowerCase().trim() !==
              filter.value?.toLowerCase().trim();

          let childNameBool = false;

          if (el.type === "adset") {
            for (const childAd of el.subRows) {
              if (childNameBool === false) {
                childNameBool =
                  childAd.name?.toLowerCase().trim() !==
                  filter.value?.toLowerCase().trim();
              }
            }
          } else if (el.type === "campaign") {
            for (const childAdset of el.subRows) {
              for (const childAd of childAdset.subRows) {
                if (childNameBool === false) {
                  childNameBool =
                    childAd.name?.toLowerCase().trim() !==
                    filter.value?.toLowerCase().trim();
                }
              }
            }
          }

          return nameBool || childNameBool;
        }
        return (
          `${el[filter.id]}`?.toLowerCase() !== filter.value?.toLowerCase()
        );
      });
      newData = [...filteredData];
    }
  });
  return newData;
};

export const formattedFilterType = (filterType: FilterTypesEnum) => {
  if (filterType === FilterTypesEnum.gt) {
    return "is greater than";
  } else if (filterType === FilterTypesEnum.lt) {
    return "is lower than";
  } else if (filterType === FilterTypesEnum.between) {
    return "is between";
  } else if (filterType === FilterTypesEnum.contains_not) {
    return "does not contain";
  } else return `${filterType}`;
};

export const formatFilterToText = (
  filter: AdsFilterInterface,
  position: "start" | "end" | "startExtraSpace",
  dashboardMode = "ecommerce" as (typeof availableDashboardModes)[number]["value"]
) => {
  const filterText = formattedFilterType(filter.filter);
  const inputLabel =
    position === "startExtraSpace"
      ? `${filter.inputLabel || ""} `
      : position === "start"
        ? filter.inputLabel || ""
        : "";

  return (
    <span className="text-sm" key={filter.id}>
      {dashboardMode === "general"
        ? ECOMMERCE_TO_GENERAL_LABEL_MAPPING[
            filter.label as keyof typeof ECOMMERCE_TO_GENERAL_LABEL_MAPPING
          ] ?? filter.label
        : filter.label}{" "}
      <span className="text-foreground-soft">{filterText}</span>{" "}
      <span className="font-semibold">
        {inputLabel}
        {filter.value}
        {position === "end" ? ` ${filter.inputLabel || ""}` : ""}
      </span>
      {filter.filter === FilterTypesEnum.between && filter.betweenValue && (
        <span className="font-semibold">
          {" "}
          and {inputLabel}
          {filter.betweenValue}
          {position === "end" ? ` ${filter.inputLabel || ""}` : ""}
        </span>
      )}
    </span>
  );
};

export const formatFilterToQuery = (filters: AdsFilterInterface[]) => {
  if (!Array.isArray(filters)) return "";
  const filterArray = filters.map((filter) => {
    if (filter.betweenValue) {
      return encodeURIComponent(
        `${filter.id}\u001e${filter.filter}\u001e${filter.value}\u001e${filter.betweenValue}`
      );
    }
    return encodeURIComponent(
      `${filter.id}\u001e${filter.filter}\u001e${filter.value}`
    );
  });
  return filterArray.join("\u001d");
};

export const formatQueryToFilter = (filterSearch: string) => {
  const splitQuery = filterSearch?.split("\u001d");
  if (!splitQuery?.length) return [];

  const filterArray =
    splitQuery?.map((filterText, index) => {
      const search = decodeURIComponent(filterText);
      const searchArr = search.split("\u001e");
      if (searchArr.length < 3) {
        return {} as AdsFilterInterface;
      }
      const id = searchArr[0];
      const filter = searchArr[1] as unknown as FilterTypesEnum;
      const value = searchArr[2];
      const betweenValue = searchArr[3] ?? "";

      const allFilters = [
        ...ADS_MANAGER_FILTERS,
        ...CONVERSION_PATH_FILTERS,
        ...CREATIVES_FILTERS,
      ];
      const filterDetails = allFilters.find((el) => el.value === id);
      if (!filterDetails) {
        return {} as AdsFilterInterface;
      }
      const queryFilter: AdsFilterInterface = {
        id,
        filter,
        value,
        betweenValue,
        label: filterDetails.label,
        inputLabel: filterDetails.inputLabel,
        index,
      };
      return queryFilter;
    }) ?? [];
  return filterArray
    .filter((el) => !!el.id)
    .map((el, index) => ({ ...el, index }));
};
