import {
  comparedAtom,
  endTimeAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  startTimeAtom,
  timeRangeKeyAtom,
} from "atoms";
import { START_DATE_RANGE_SELECT_OPTIONS_BASIC } from "constants/constants";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useMemo } from "react";

export function useTimeRangeLabel() {
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const [timeRangeKey] = useAtom(timeRangeKeyAtom);
  const [compared] = useAtom(comparedAtom);
  const [previousStartTime] = useAtom(previousStartTimeAtom);
  const [previousEndTime] = useAtom(previousEndTimeAtom);
  const timeRangeLabel = useMemo(
    () =>
      START_DATE_RANGE_SELECT_OPTIONS_BASIC.find(
        (el) => el.value === timeRangeKey
      )?.label ?? timeRangeKey,
    [timeRangeKey]
  );

  const isSameStartEndDate = dayjs(endTime).isSame(startTime, "day");

  const label = `${timeRangeLabel}: ${dayjs(startTime).format("MMM DD, YYYY")}
       ${
         !isSameStartEndDate
           ? " - " + dayjs(endTime).format("MMM DD, YYYY")
           : ""
       }`;

  let comparedLabel = null;
  if (compared) {
    comparedLabel = `vs ${dayjs(previousStartTime).format("MMM DD, YYYY")} ${
      !isSameStartEndDate
        ? " - " + dayjs(previousEndTime).format("MMM DD, YYYY")
        : ""
    }`;
  }

  return { label, comparedLabel };
}
