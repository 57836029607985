import RateDisplay from "@components/Cards/RateDisplay";
import useCurrency from "@lib/hooks/use-currency";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import React from "react";
import { getTotalValueOfAllRows, getCorrelation } from "./calculations";

type Props<T> = {
  compared?: boolean;
  highlightMinMaxValues?: boolean;
} & (
  | {
      info: CellContext<T, number | undefined>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number | undefined>;
      calculateTotals: true;
    }
);
export type CurrencyCalculatorType = (
  value: number | undefined,
  numDigits: number,
  displaySymbol?: boolean
) => string;

const getDisplayString = (
  value: number,
  currencyCalculator: CurrencyCalculatorType
) => {
  return currencyCalculator(value, 2);
};

function AovTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
  highlightMinMaxValues,
}: Props<T>) {
  const { calculateCurrencyConvertion } = useCurrency();
  let comparedValue = 0;
  let value = 0;
  if (calculateTotals) {
    const allOrderValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value: row.original?.["purchaseCount"],
        compared: row.original?.compared?.["purchaseCount"],
      };
    });
    const allRevenueValues = info.column
      .getFacetedRowModel()
      .rows.map((row) => {
        return {
          value: row.original?.["purchaseAmount"],
          compared: row.original?.compared?.["purchaseAmount"],
        };
      });
    const orderTotals = getTotalValueOfAllRows({
      values: allOrderValues,
    });
    const revenueTotals = getTotalValueOfAllRows({
      values: allRevenueValues,
    });

    value =
      revenueTotals.value && orderTotals.value
        ? revenueTotals.value / orderTotals.value
        : 0;
    comparedValue =
      revenueTotals.compared && orderTotals.compared
        ? revenueTotals.compared / orderTotals.compared
        : 0;
  } else {
    value = info.getValue() ?? 0;
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }
  }
  let valueClassNames = "";
  if (highlightMinMaxValues) {
    const values = info.column.getFacetedMinMaxValues();
    if (
      values &&
      ((Array.isArray(values[0]) && values[0][0] === value) ||
        values[0] === value)
    ) {
      valueClassNames =
        "bg-[#C7E6C8] text-secondary dark:text-gray-800 py-0.5 px-1 mb-0.5 text-center rounded-md";
    } else if (
      values &&
      ((Array.isArray(values[1]) && values[1][0] === value) ||
        values[1] === value)
    ) {
      valueClassNames =
        "bg-[#47B04B] dark:text-white text-black py-0.5 px-1 mb-0.5 text-center rounded-md";
    }
  }
  if (compared) {
    const rate = getCorrelation(value, comparedValue, 2);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1 flex items-start flex-col">
          <p className={valueClassNames}>
            {getDisplayString(value, calculateCurrencyConvertion)}
          </p>
          <p className="text-[11px] leading-tight opacity-70">
            vs {getDisplayString(comparedValue, calculateCurrencyConvertion)}
          </p>
        </div>
        <RateDisplay rate={rate} isIncreasing={rate > 0} size="small" />
      </div>
    );
  }
  return getDisplayString(value, calculateCurrencyConvertion);
}

export default AovTableDisplay;
