import { Store } from "../../api/types/backendTypes";

/**
 * Transforms given store to display name.
 *
 * @param {Store} store Store to render to display name
 * @return {string} display name of the store
 */
export const storeToDisplayName = (store: Store) => {
  return `${store.name} - ${store.description} (${store?.url})`;
};
