import { useEffect, useState } from "react";

export function useAttributionSettingsOpen() {
  const [settingsOpenState, setSettingsOpenState] = useState({
    attributionModel: false,
    attributionWindow: false,
    reportTime: false,
    dataSelect: false,
    storeSelect: false,
    channelSelect: false,
  });

  useEffect(() => {
    const attributionWindowSelect = document.querySelector(
      "#attribution-window-select"
    );
    const attributionModelSelect = document.querySelector(
      "#attribution-model-select"
    );
    const reportTimeSelect = document.querySelector("#report-time-select");
    const channelsSelect = document.querySelector(
      "#selected-marketing-channels-select"
    );
    const datepickerPopover = document.querySelector("#date-select-popover");
    const storeSelector = document.querySelector("#store-selector-select");
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (
          mutation.type === "attributes" &&
          (mutation.attributeName === "data-headlessui-state" ||
            mutation.attributeName === "data-state")
        ) {
          const openState = (mutation.target as Element).getAttribute(
            mutation.attributeName
          );
          let currentSettingValue = "";
          if (mutation.target.isSameNode(attributionModelSelect)) {
            currentSettingValue = "attributionModel";
          } else if (mutation.target.isSameNode(attributionWindowSelect)) {
            currentSettingValue = "attributionWindow";
          } else if (mutation.target.isSameNode(reportTimeSelect)) {
            currentSettingValue = "reportTime";
          } else if (mutation.target.isSameNode(channelsSelect)) {
            currentSettingValue = "channelSelect";
          } else if (mutation.target.isSameNode(datepickerPopover)) {
            currentSettingValue = "dataSelect";
          } else if (mutation.target.isSameNode(storeSelector)) {
            currentSettingValue = "storeSelect";
          }

          if (currentSettingValue) {
            if (openState === "open")
              setSettingsOpenState((curr) => ({
                ...curr,
                [currentSettingValue]: true,
              }));
            else
              setSettingsOpenState((curr) => ({
                ...curr,
                [currentSettingValue]: false,
              }));
          }
        }
      });
    });

    if (attributionWindowSelect) {
      observer.observe(attributionWindowSelect, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }
    if (attributionModelSelect) {
      observer.observe(attributionModelSelect, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }
    if (reportTimeSelect) {
      observer.observe(reportTimeSelect, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }
    if (channelsSelect) {
      observer.observe(channelsSelect, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }
    if (datepickerPopover) {
      observer.observe(datepickerPopover, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }
    if (storeSelector) {
      observer.observe(storeSelector, {
        attributes: true, // configure it to listen to attribute changes
        attributeFilter: ["data-state", "data-headlessui-state"],
      });
    }

    return () => observer.disconnect();
  }, []);

  return Object.values(settingsOpenState).some((el) => el);
}
