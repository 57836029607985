import { Placement } from "@lib/util-types";
import React, { useMemo } from "react";
import { getIconPosition } from "./placement";

interface Props {
  placement: Placement;
  bgColor: string;
  shadow: boolean;
}

const TooltipIcon: React.FC<Props> = ({ placement, bgColor, shadow }) => {
  const { transform, top, left, right, bottom } = useMemo(
    () => getIconPosition(placement, 3),
    [placement]
  );

  return (
    <span className={`w-0 h-0 ${bgColor} absolute`}>
      <style jsx>{`
        span {
          border-width: 0 12px 14px 12px;
          transform: ${transform};
          top: ${top};
          left: ${left};
          bottom: ${bottom};
          right: ${right};
        }
      `}</style>
    </span>
  );
};

export default React.memo(TooltipIcon);
