export const tuple = <T extends string[]>(...args: T) => args;

const buttonTypes = tuple(
  "default",
  "outline",
  "primary",
  "secondary",
  "warning",
  "error",
  "abort",
  "text"
);

const selectTypes = tuple(
  "default",
  "lite",
  "red",
  "green",
  "yellow",
  "blue",
  "ai"
);

const normalSizes = tuple("small", "medium", "large");
const buttonNormalSizes = tuple("tiny", "small", "medium", "large");

const normalTypes = tuple(
  "default",
  "primary",
  "secondary",
  "warning",
  "error"
);

const themeTypes = tuple("dark", "light");

const snippetTypes = tuple(
  "default",
  "primary",
  "secondary",
  "warning",
  "error",
  "dark",
  "lite"
);

const sidebarTypes = tuple("left", "right");

const cardTypes = tuple("default", "dark", "primary", "disabled");
const linkTypes = tuple("default", "medium", "small");

const copyTypes = tuple("default", "slient", "prevent");

const triggerTypes = tuple("hover", "click");

const placement = tuple(
  "top",
  "topStart",
  "topEnd",
  "left",
  "leftStart",
  "leftEnd",
  "bottom",
  "bottomStart",
  "bottomEnd",
  "right",
  "rightStart",
  "rightEnd"
);

const dividerAlign = tuple("start", "center", "end", "left", "right");

const dividerSpacesY = tuple("my-0", "my-2", "my-4", "my-8", "my-16");
const dividerSpacesX = tuple("mx-0", "mx-2", "mx-4", "mx-8", "mx-16");

export type ButtonTypes = (typeof buttonTypes)[number];
export type SelectTypes = (typeof selectTypes)[number];

export type SidebarTypes = (typeof sidebarTypes)[number];

export type LinkTypes = (typeof linkTypes)[number];

export type NormalSizes = (typeof normalSizes)[number];
export type ButtonNormalSizes = (typeof buttonNormalSizes)[number];

export type NormalTypes = (typeof normalTypes)[number];

export type ThemeTypes = (typeof themeTypes)[number];

export type SnippetTypes = (typeof snippetTypes)[number];

export type CardTypes = (typeof cardTypes)[number];

export type CopyTypes = (typeof copyTypes)[number];

export type TriggerTypes = (typeof triggerTypes)[number];

export type Placement = (typeof placement)[number];

export type DividerAlign = (typeof dividerAlign)[number];
export type DividerSpacesY = (typeof dividerSpacesY)[number];
export type DividerSpacesX = (typeof dividerSpacesX)[number];
