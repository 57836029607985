import React from "react";
import { useSpring, a } from "@react-spring/web";
import useMeasure from "react-use-measure";

interface Props {
  isExpanded?: boolean;
}

export type ExpandProps = Props;

const Expand: React.FC<React.PropsWithChildren<ExpandProps>> = ({
  isExpanded = false,
  children,
}) => {
  const [ref, { height: viewHeight }] = useMeasure();

  const animProps = useSpring({
    height: isExpanded ? viewHeight : 0,
    config: { tension: 250, friction: 32, clamp: true, duration: 150 },
    opacity: isExpanded ? 1 : 0,
  });

  return (
    <a.div className="container" style={{ overflow: "hidden", ...animProps }}>
      <div ref={ref} className={"content "}>
        {children}
      </div>
    </a.div>
  );
};

export default Expand;
