import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import React from "react";

const InfluencerOutdatedTooltip = () => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-gray-500" />
      </TooltipTrigger>
      <TooltipContent>
        <p className="w-96">
          There is a new Influencer Module available which uses new
          automatically generated tracking links. Please adjust to the new
          tracking links. Your current tracking links from influencers using the
          old links will become invalid at some point in the future.
        </p>
      </TooltipContent>
    </Tooltip>
  );
};

export default InfluencerOutdatedTooltip;
