import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { getColors } from "./styles";
import {
  getPosition,
  TooltipPosition,
  defaultTooltipPosition,
} from "./placement";
import TooltipIcon from "./tooltip-icon";
import { Placement, SnippetTypes } from "@lib/util-types";
import usePortal from "@lib/hooks/use-portal";
import useResize from "@lib/hooks/use-resize";
import useClickAnyWhere from "@lib/hooks/use-click-anywhere";
import CssTransition from "@ui/shared/css-transition";

interface Props {
  parent?: MutableRefObject<HTMLElement | null> | undefined;
  placement: Placement;
  type: SnippetTypes;
  visible: boolean;
  hideArrow: boolean;
  offset: number;
  className?: string;
}

interface ReactiveDomReact {
  top: number;
  bottom: number;
  left: number;
  right: number;
  width: number;
  height: number;
}

const defaultRect: ReactiveDomReact = {
  top: -1000,
  left: -1000,
  right: -1000,
  bottom: -1000,
  width: 0,
  height: 0,
};

const getRect = (
  ref: MutableRefObject<HTMLElement | null>
): ReactiveDomReact => {
  if (!ref || !ref.current) return defaultRect;
  const rect = ref.current.getBoundingClientRect();
  return {
    ...rect,
    width: rect.width || rect.right - rect.left,
    height: rect.height || rect.bottom - rect.top,
    top: rect.top + document.documentElement.scrollTop,
    bottom: rect.bottom + document.documentElement.scrollTop,
    left: rect.left + document.documentElement.scrollLeft,
    right: rect.right + document.documentElement.scrollLeft,
  };
};

const TooltipContent: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  parent,
  visible,
  offset,
  placement,
  type,
  className,
  hideArrow,
}) => {
  const el = usePortal("tooltip");
  const selfRef = useRef<HTMLDivElement>(null);
  const [rect, setRect] = useState<TooltipPosition>(defaultTooltipPosition);
  const colors = useMemo(() => getColors(type), [type]);
  const hasShadow = type === "default";
  const updateRect = useCallback(() => {
    if (parent) {
      const position = getPosition(placement, getRect(parent), offset);
      setRect(position);
    }
  }, [offset, parent, placement]);

  useResize(updateRect);
  useClickAnyWhere(() => updateRect());

  useEffect(() => {
    updateRect();
  }, [updateRect, visible]);

  const preventHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };
  if (!parent) return null;
  if (!el) return null;
  return createPortal(
    <CssTransition visible={visible}>
      <div
        className={`tooltip-content rounded-lg p-0 absolute border border-gray-600  ${
          colors.bgColor
        } ${colors.color} ${
          hasShadow ? "shadow-2xl shadow-gray-900" : ""
        } ${className}`}
        ref={selfRef}
        onClick={preventHandler}
      >
        <div className="inner py-2 px-4">
          {!hideArrow && (
            <TooltipIcon
              placement={placement}
              bgColor={colors.borderColor}
              shadow={hasShadow}
            />
          )}
          {children}
        </div>
        <style jsx>{`
          .tooltip-content {
            transform: ${rect.transform};
            top: ${rect.top};
            left: ${rect.left};
            z-index: 1020;
          }
        `}</style>
      </div>
    </CssTransition>,
    el
  );
};

export default TooltipContent;
