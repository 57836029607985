import { AdAccountsAPIResponse, AdSubAccountsAPIResponse } from "./types";

export const transformToAdSubAccounts = (data: AdSubAccountsAPIResponse) => {
  return data.map((el) => ({
    id: el.account_id,
    name: el.name,
    source: el.source,
  }));
};

export const transformToAccounts = (data: AdAccountsAPIResponse) => {
  return data.map((el) => ({
    id: el.account_id,
    name: el.name,
    source: el.source,
    currency: el.currency,
    lastScrapeDate: el.last_scrape_date,
    lastFullCampaignScrape: el.last_full_campaign_scrape,
    lastFullAdsetScrape: el.last_full_adset_scrape,
    lastFullAdScrape: el.last_full_ad_scrape,
    lastUpdatedAt: el.last_updated_at,
    isSelfOnboarded: el.is_self_onboarded,
    timeZone: el.time_zone,
    managementAccountId: el.management_account_id,
  }));
};
