import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";

export const BaseCreateCooperationSchema = z.object({
  name: z.string({ required_error: "Name is required" }),
  influencer_id: z.string({ required_error: "Influencer is required" }),
  placement_type: z.string({ required_error: "Placement Type is required" }),
  source: z.string().optional(),
  scheduled_for: z
    .string({ required_error: "Scheduled For is required" })
    .datetime(),
  commission: z
    .string()
    .refine((value) => {
      if (!value) return true;
      const cleanedNumber = value.replace(".", ""); // Remove the decimal point
      const isValidFormat = /^(0|[1-9]\d{0,8})(\.\d{1,2})?$/.test(value);
      const hasNoMoreThan9Digits = /^(\d{1,9}(\.\d{0,2})?)$/.test(
        cleanedNumber
      );
      if (isValidFormat && hasNoMoreThan9Digits) {
        return true;
      } else if (!isValidFormat) {
        return false;
      }
    }, "Value must be a number in the format of X.XX. Ensure that there are maximum 9 digits in total.")
    .optional(),
  currency: z.string({ required_error: "Currency is required" }),
  labels: z.array(z.string()),
  variable_commission: z
    .string()
    .refine((value) => {
      if (!value) return true;
      return RegExp(/^(0|[1-9]\d*)(\.\d+)?$/).test(`${value}`);
    }, "Value must be a number in the format of X.XX")
    .optional(),
  variable_commission_type: z.string().optional(),
});

export const CreateCooperationSchema = BaseCreateCooperationSchema.refine(
  (data) => {
    if (
      data.variable_commission_type === "percentage" &&
      parseFloat(data.variable_commission ?? "0") > 100
    ) {
      return false;
    }
    return true;
  },
  {
    message: "Variable commission can't be greater than 100%",
    path: ["variable_commission"],
  }
);

export type CreateCooperationInput = z.infer<typeof CreateCooperationSchema>;

const onCreateCooperation = async (
  input: CreateCooperationInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    CreateCooperationSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/cooperations/create`,
      {
        name: input.name,
        influencer_id: input.influencer_id,
        placement_type: input.placement_type,
        source: input.source,
        scheduled_for: input.scheduled_for,
        commission: input.commission ?? 0,
        currency: input.currency,
        variable_commission: input.variable_commission ?? 0,
        variable_commission_type: input.variable_commission_type,
        labels: input.labels?.map((el) => el.trim()).filter((el) => el !== ""),
        links: [],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useCreateCooperation = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: CreateCooperationInput) =>
      onCreateCooperation(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["cooperations"] });
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
      queryClient.invalidateQueries({ queryKey: ["influencer"] });
      queryClient.invalidateQueries({ queryKey: ["cooperation"] });
    },
  });
};
