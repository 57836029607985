import axios, { AxiosError } from "axios";
import {
  Store,
  StoreListFetchRequest,
  StoreListRequest,
} from "@api/types/backendTypes";
import { useQuery } from "@tanstack/react-query";
import { invalidateToken, useAuth } from "../useAuth";
import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { anonymizeString } from "@lib/util-functions/string";

async function fetchStores({
  scope,
  session,
}: StoreListFetchRequest): Promise<Array<Store>> {
  // const { data, isPending } = useAuth();
  if (!session) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/site/list`,
      {
        scope: scope,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": session,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    let stores: Array<Store> = response.data.result.sites;
    if (DEPLOY_ENVIRONMENT === "demo") {
      const storesToFilter = process.env.NEXT_PUBLIC_DEMO_STORES?.split(",");
      stores = stores
        .filter((store) => storesToFilter?.includes(store.csid))
        .map((store) => ({
          ...store,
          name: anonymizeString(store.name),
          description: anonymizeString(store.description),
          url: anonymizeString(store?.url),
        }));
    }
    // Inferred return type: Promise<MyData>
    return stores;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage.error ?? axiosError.message);
    }
    throw new Error("Unknown error occurred!");
  }
}

export type UseStoresOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Store>) => void) | undefined;
};

function useStores(props: StoreListRequest, options?: UseStoresOptions) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["stores"],
    queryFn: () => fetchStores({ ...props, session: data?.token! }),

    ...options,
    refetchOnWindowFocus: false,
    gcTime: 0,
    staleTime: 0,
    enabled: !!data?.token,
  });
}
export { useStores };
