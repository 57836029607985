/**
 * Checks whether the incoming value is empty or null.
 *
 * @param {String} value
 * @return {boolean} boolean if string is empty or not
 */
export const isEmpty = (value: any) => {
  if (typeof value === "string") {
    return !value.trim();
  }
  return !value;
};

/**
 * Formats given number into a string.
 *
 * @param {number} val Number to be formated
 * @param {number} numDigits Number of digits after decimal point
 * @param {string} loc Locale to use.
 * @param {number} defaultVal Default value to assume if provided number is not valid
 * @return {string} formatted number to tracify format
 */
export function formatTracifyNumber(
  val: number,
  numDigits: number = 0,
  loc: string = "de-DE",
  defaultVal: number = 0.0
) {
  if (!val) val = defaultVal;
  return val.toLocaleString(loc, {
    maximumFractionDigits: numDigits,
    minimumFractionDigits: numDigits,
  });
}

// code gotten from  observablehq.com/@mbostock/localized-number-parsing "NumberParser" class
// and modified. Might have to fix this for some Hindu/Chinese languages
/**
 * Formats given number into a string.
 *
 * @param {string} val Number to be formated
 * @param {string} loc Locale to use.
 * @return {number} formatted string to number
 */
export function parseStringToNumber(val: string, loc: string = "de-DE") {
  const parts = new Intl.NumberFormat(loc).formatToParts(12345.6);
  const group = new RegExp(
    `[${parts?.find((d) => d.type === "group")?.value}]`,
    "g"
  );
  const decimal = new RegExp(
    `[${parts.find((d) => d.type === "decimal")?.value}]`
  );
  return Number(val.trim().replace(group, "").replace(decimal, "."));
}

export function JSONreplacer(key: string, value: any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}
export function JSONreviver(key: string, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

export function anonymizeString(input: string) {
  return input?.replace(/[a-z\d]/gi, "x");
}
