import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { Influencer } from "../useInfluencers";

const UseAttributionDataPropsSchema = z.object({
  list: z.record(z.string(), z.array(z.string())),
  influencerId: z.string(),
  cooperationId: z.string().optional(),
});

export type UseAttributionDataProps = z.infer<
  typeof UseAttributionDataPropsSchema
>;

const FetchAttributionDataSchema = z.object({
  list: z.record(z.string(), z.array(z.string())),
  token: z.string({ required_error: "User Session not available" }),
  csids: z.array(z.string()),
});

export type FetchAttributionDataProps = z.infer<
  typeof FetchAttributionDataSchema
>;

async function fetchAttributionData({
  list,
  token,
  csids,
}: FetchAttributionDataProps): Promise<Record<string, boolean>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/influencer/hasdata`,
      {
        csids: csids,
        influencer_cooperations: list,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response?.data?.result
      ? (response.data.result as Record<string, boolean>)
      : {};
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage?.error ?? axiosError?.message);
    }

    throw new Error("Unknown error occurred! Please refresh the page.");
  }
}

export type UseAttributionDataOptions = {
  enabled?: boolean;
  disableRefetch?: boolean;
  onSuccess?: ((data: Record<string, boolean>) => void) | undefined;
};

export function useAttributionData(
  props: UseAttributionDataProps,
  options?: UseAttributionDataOptions
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  return useQuery({
    queryKey: [
      "attributionData",
      options?.disableRefetch // we want to disable a refetch for this query for the links list
        ? {
            selectedStoreIds,
            influencerId: props.influencerId,
            cooperationId: props.cooperationId,
          }
        : { ...props, selectedStoreIds },
    ],
    queryFn: () => {
      return fetchAttributionData({
        ...props,
        token: data?.token!,
        csids: selectedStoreIds,
      });
    },

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 30000, // 30 seconds needed to overwrite global settings
    gcTime: 30000, // 30 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
