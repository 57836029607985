import { useCallback } from "react";
import useLocale from "./use-locale";

const useFormat = () => {
  const { currentLocale } = useLocale();

  const formatNumber = useCallback(
    (val: number, numDigits: number = 0, minimumFractionDigits?: number) => {
      const defaultVal: number = 0.0;

      if (!val) val = defaultVal;
      return val.toLocaleString(currentLocale, {
        maximumFractionDigits: numDigits,
        minimumFractionDigits: minimumFractionDigits ?? numDigits,
      });
    },
    [currentLocale]
  );

  return {
    formatNumber,
  };
};

export default useFormat;
