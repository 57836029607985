export const getLocalStorage = () => {
  if (typeof window !== undefined) {
    try {
      const localStorage = window.localStorage;
      return localStorage;
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};
