import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";

interface Props {
  active?: boolean;
}

const CollapseIcon: React.FC<Props> = ({ active }) => {
  return (
    <div
      className={`collapse-icon transform transition-transform duration-200 ease-out ${
        active ? "rotate-180" : "rotate-0"
      } `}
    >
      <ChevronDownIcon className="h-4 w-4" />
    </div>
    // <svg
    //   viewBox="0 0 20 20"
    //   width="20"
    //   height="20"
    //   stroke="currentColor"
    //   strokeWidth="1.5"
    //   strokeLinecap="round"
    //   strokeLinejoin="round"
    //   fill="none"
    //   shapeRendering="geometricPrecision"
    //   style={{ color: "currentColor" }}
    // >
    //   <path d="M6 9l6 6 6-6" />
  );
};

const MemoCollapseIcon = React.memo(CollapseIcon);

export default MemoCollapseIcon;
