import React from "react";
import s from "./card.module.css";

interface Props {
  disableAutoMargin?: boolean;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type CardFooterProps = Props & NativeAttrs;

const CardFooter: React.FC<React.PropsWithChildren<CardFooterProps>> = ({
  children,
  className = "",
  disableAutoMargin = false,
  ...props
}) => {
  return (
    <footer
      className={`${s.footer} py-3 px-6 flex items-center overflow-hidden text-sm border-t border-gray-200 rounded-bl-md rounded-br-md min-h- ${className}`}
      {...props}
    >
      {children}
    </footer>
  );
};

export default CardFooter;
