import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToCooperation } from "./transformations";
import { Cooperation } from "./types";
import { ChiefAPIError } from "@api/types/backendTypes";
import useSelectedStores from "@lib/hooks/use-selected-stores";

const UseCooperationPropsSchema = z.object({
  cooperationId: z.string(),
  csids: z.array(z.string()).optional(),
});

const FetchCooperationSchema = z.object({
  cooperationId: z.string(),
  csids: z.array(z.string()).optional(),
  token: z.string({ required_error: "User Session not available" }),
});

export type UseCooperationProps = z.infer<typeof UseCooperationPropsSchema>;

export type FetchCooperationProps = z.infer<typeof FetchCooperationSchema>;

async function fetchCooperation({
  token,
  cooperationId,
  csids,
}: FetchCooperationProps): Promise<Cooperation | null> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/cooperations/${cooperationId}/get`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // TODO transform API data to our type
    return response.data ? transformToCooperation(response.data, csids) : null;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseCooperationOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Cooperation | null) => void) | undefined;
};

export function useCooperation(
  props: UseCooperationProps,
  options?: UseCooperationOptions
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  return useQuery({
    queryKey: [
      "cooperation",
      { ...props, csids: props.csids ?? selectedStoreIds },
    ],
    queryFn: () => {
      return fetchCooperation({
        ...props,
        token: data?.token!,
        csids: props.csids ?? selectedStoreIds,
      });
    },

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5000, // 5 seconds needed to overwrite global settings
    gcTime: 5000, // 5 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
