import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Spinner } from "@ui";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 focus-visible:ring-offset-background disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      size: {
        md: "h-12 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        xs: "h-6 rounded-md px-2 text-xs",
        "icon-md": "h-12 w-12",
        "icon-sm": "h-9 w-9",
        "icon-xs": "h-6 w-6",
      },
      variant: {
        primary:
          "bg-primary text-primary-foreground shadow hover:bg-primary-dark active:bg-primary-dark active:ring-primary-foreground focus-visible:ring-primary-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary-900 active:bg-secondary-900 active:ring-secondary-foreground focus-visible:ring-secondary-foreground",
        destructive:
          "bg-red-500 text-destructive-foreground shadow-sm hover:bg-red-700 active:bg-red-700 active:ring-destructive-foreground focus-visible:ring-destructive-foreground",
        ghost:
          "hover:text-gray-100 active:text-gray-100 hover:bg-gray-600 active:bg-gray-600 active:ring-foreground focus-visible:ring-foreground",
        link: "h-auto py-0 px-0 text-gray-100 underline-offset-4 hover:underline ring-0 focus-visible:ring-0 focus-visible:ring-transparent",
        // outline:
        //   "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
      },
    },
    defaultVariants: {
      size: "md",
      variant: "primary",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      disabled = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? <Spinner className="" /> : children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
