import { anonymizeString } from "@lib/util-functions/string";
import {
  Organisation,
  OrganisationAPIResponse,
  OrganisationsAPIResponse,
} from "./types";
import { DEPLOY_ENVIRONMENT } from "constants/constants";

export const transformToOrganisation = (
  organisation: OrganisationAPIResponse
) => {
  return {
    id: organisation.id,
    name:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(organisation.name)
        : organisation.name,
    accounts: organisation.accounts,
    customerSites: organisation.customer_sites,
    featureFlags: organisation.feature_flags,
  };
};

export const transformToOrganisations = (data: OrganisationsAPIResponse) => {
  let organisations: Array<Organisation> = data.map((organisation) =>
    transformToOrganisation(organisation)
  );
  if (DEPLOY_ENVIRONMENT === "demo") {
    const storesToFilter = process.env.NEXT_PUBLIC_DEMO_STORES?.split(",");
    organisations = organisations.filter((organisation) => {
      let hasStore = false;
      for (const site of organisation.customerSites) {
        if (hasStore) break;
        hasStore = storesToFilter?.includes(site.id) ?? false;
      }
      return hasStore;
    });
  }
  return organisations;
};
