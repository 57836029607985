import "./link.module.css";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { LinkTypes } from "../../lib/util-types";
import { useMemo } from "react";

/* eslint-disable-next-line */

type NativeAttrs = Omit<React.AnchorHTMLAttributes<any>, keyof Props>;
export interface Props extends NextLinkProps {
  className?: string;
  type?: LinkTypes;
  external?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}
export type LinkProps = Props & NativeAttrs;
export const getStyles = (type?: LinkTypes): string => {
  const colors: { [key in LinkTypes]: string } = {
    default: "text-link font-semibold hover:underline",
    medium: "text-sm font-medium text-gray-500 hover:text-gray-700",
    small: "text-xs font-medium text-gray-400 hover:text-gray-700",
  };

  if (!type) return colors.default;
  return colors[type];
};

export const Link: React.FC<LinkProps> = ({
  className = "",
  type = "default",
  children,
  external,
  onClick,
  ...props
}) => {
  const style = useMemo(() => getStyles(type), [type]);
  if (external) {
    const { href, referrerPolicy, rel, target } = props;
    return (
      <a
        className={`${style} transition-all duration-200 ${className}`}
        onClick={onClick}
        href={href as string}
        referrerPolicy={referrerPolicy}
        rel={rel}
        target={target}
      >
        {children}
      </a>
    );
  }
  return (
    <NextLink
      {...props}
      passHref
      className={`${style} transition-all duration-200 ${className}`}
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
};

export default Link;
