import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { transformToCooperations } from "../useCooperations";
import { CooperationLink } from "../useLinks";
import {
  Influencer,
  InfluencerAPIResponse,
  InfluencersAPIResponse,
} from "./types";
import { anonymizeString } from "@lib/util-functions/string";

export const transformToInfluencer = (
  influencer: InfluencerAPIResponse,
  csids?: string[]
) => {
  const inf: Influencer = {
    id: influencer.id,
    name:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(influencer.name)
        : influencer.name,
    contactEmail: influencer.contact_email,
    organisationId: influencer.organisation_id,
    cooperations: transformToCooperations(influencer.cooperations, csids),
    channels: {
      tiktok: influencer.tiktok_account_name,
      blog: influencer.blog_account_name,
      twitch: influencer.twitch_account_name,
      youtube: influencer.youtube_account_name,
      facebook: influencer.facebook_account_name,
      instagram: influencer.instagram_account_name,
      pinterest: influencer.pinterest_account_name,
      linkedin: influencer.linkedin_account_name,
      snapchat: influencer.snapchat_account_name,
      podcast: influencer.podcast_account_name,
      twx: influencer.twx_account_name,
      threads: influencer.threads_account_name,
      other: influencer.other_account_name,
    },
    links: [],
    discountCodes: [],
  };
  // do it afterwards, because we already filtered out the cooperations by csid
  // and transformed to necessary type
  const cooperationLinks = inf.cooperations.reduce((prev, curr) => {
    return [...prev, ...curr.links];
  }, [] as CooperationLink[]);

  inf.links = cooperationLinks;

  inf.discountCodes = cooperationLinks.filter((link) => !!link.discountCode);

  return inf;
};

export const transformToInfluencers = (
  data: InfluencersAPIResponse,
  csids?: string[]
) => {
  const influencers: Array<Influencer> = data.map((influencer) => {
    return transformToInfluencer(influencer, csids);
  });
  return influencers;
};
