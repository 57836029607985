import React from "react";

const ChartLineIcon = () => {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 400"
      height={18}
    >
      <polyline
        points="27.5 31 27.5 368.5 376.5 368.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        stroke-miterlimit="10"
        strokeWidth="15"
      />
      <polyline
        points="63.66 291.44 132.98 202.68 203.68 293.21 349.5 106.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="15"
      />
    </svg>
  );
};

export default ChartLineIcon;
