import { useEffect, useState } from "react";
import useSelectedStores from "./use-selected-stores";

type Props = {
  fetchingData: boolean;
  finished: boolean;
};

export const useLoadingState = ({ fetchingData, finished }: Props) => {
  const { selectedStoreIds } = useSelectedStores();

  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [storesChanged, setStoresChanged] = useState(false);

  useEffect(() => {
    if (selectedStoreIds.length > 0) {
      setStoresChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreIds.join(",")]);

  useEffect(() => {
    if (storesChanged && fetchingData) {
      setStoresChanged(false);
      setIsLoadingInitialData(true);
    }
  }, [fetchingData, storesChanged]);

  useEffect(() => {
    if (isLoadingInitialData && finished && !fetchingData) {
      setIsLoadingInitialData(false);
    }
  }, [fetchingData, finished, isLoadingInitialData]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (finished && !fetchingData) {
      setIsLoading(true);
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [fetchingData, finished]);

  return {
    isLoadingInitialData,
    isLoading,
    showLoadingState: isLoading || isLoadingInitialData,
  };
};
