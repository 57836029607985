import cn from "classnames";
import React, { FC } from "react";

interface ContainerProps {
  className?: string;
  children?: any;
  el?: HTMLElement;
  clean?: boolean;
  narrow?: boolean;
}

export const Container: FC<ContainerProps> = ({
  children,
  className,
  el = "div",
  narrow,
  clean,
}) => {
  const rootClassName = cn(className, {
    "mx-auto max-w-8xl px-4 sm:px-6 md:px-8 lg:px-12": !clean && !narrow,
    "mx-auto max-w-7xl px-4 sm:px-6 md:px-8 lg:px-12": !clean && narrow,
  });

  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any;

  return <Component className={rootClassName}>{children}</Component>;
};

export default Container;
