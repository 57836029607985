import React, { useMemo } from "react";

export interface InputIconProps {
  icon: React.ReactNode;
  className: string;
  clickable: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const InputIcon: React.FC<InputIconProps> = ({
  icon,
  className,
  clickable,
  onClick,
}) => {
  return (
    <span
      className={`input-icon box-content flex h-full items-center align-middle m-0 py-0 leading-none relative ${
        clickable ? "cursor-pointer auto" : "cursor-default none"
      } ${className}`}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};

const MemoInputIcon = React.memo(InputIcon);

export default MemoInputIcon;
