import React, { useEffect, useState } from "react";
import CollapseIcon from "./collapse-icon";
import { useCollapseContext } from "./collapse-context";
import Expand from "../shared/expand";

interface Props {
  title: string;
  subtitle?: React.ReactNode | string;
  initialVisible?: boolean;
  shadow?: boolean;
  className?: string;
  index?: number;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type CollapseProps = Props & NativeAttrs;

export const Collapse: React.FC<React.PropsWithChildren<CollapseProps>> = ({
  children,
  title,
  subtitle,
  initialVisible = false,
  shadow = false,
  className = "",
  index,
  ...props
}) => {
  const [visible, setVisible] = useState<boolean>(initialVisible);
  const { values, updateValues } = useCollapseContext();

  useEffect(() => {
    if (!values.length) return;
    const isActive = !!values.find((item) => item === index);
    setVisible(isActive);
  }, [index, values]);

  const clickHandler = () => {
    setVisible((current) => {
      updateValues && updateValues(index, !current);
      return !current;
    });
  };

  return (
    <div
      className={`collapse my-2 ${shadow ? "shadow-sm " : ""} ${className}`}
      {...props}
    >
      <div
        className={`border-b border-gray-200 ${
          subtitle ? "py-2" : "py-4"
        } flex flex-col outline-none`}
        role="button"
        onClick={clickHandler}
      >
        <div className="title text-sm font-medium flex justify-between items-center text-foreground">
          <h3>{title}</h3> <CollapseIcon active={visible} />
        </div>
        {subtitle && (
          <div className="text-sm text-foreground-soft">{subtitle}</div>
        )}
      </div>
      <Expand isExpanded={visible}>
        <div className="content text-base leading-relaxed pt-4 pl-8">
          {children}
        </div>
      </Expand>
    </div>
  );
};

export default Collapse;
