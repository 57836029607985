import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToCooperationLinks } from "./transformations";
import { CooperationLink } from "./types";
import { ChiefAPIError } from "@api/types/backendTypes";
import useSelectedStores from "@lib/hooks/use-selected-stores";

export const UseCooperationLinksPropsSchema = z.object({
  organisationId: z.string({ required_error: "Organisation ID is required." }),
  csids: z.array(z.string()).optional(),
});

export const FetchCooperationLinksSchema =
  UseCooperationLinksPropsSchema.extend({
    token: z.string({ required_error: "User Session not available" }),
  });

export type UseCooperationLinksProps = z.infer<
  typeof UseCooperationLinksPropsSchema
>;

export type FetchCooperationLinksProps = z.infer<
  typeof FetchCooperationLinksSchema
>;

async function fetchCooperationLinks({
  token,
  organisationId,
  csids,
}: FetchCooperationLinksProps): Promise<Array<CooperationLink>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/links/list?organisation_id=${organisationId}`,
      {
        params: {
          limit: 100000,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return transformToCooperationLinks(response.data.items);
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseCooperationLinksOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<CooperationLink>) => void) | undefined;
};

export function useCooperationLinks(
  props: UseCooperationLinksProps,
  options?: UseCooperationLinksOptions
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  return useQuery({
    queryKey: [
      "cooperationLinks",
      { ...props, csids: props.csids ?? selectedStoreIds },
    ],
    queryFn: () =>
      fetchCooperationLinks({
        ...props,
        token: data?.token!,
        csids: props.csids ?? selectedStoreIds,
      }),

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5000, // 5 seconds needed to overwrite global settings
    gcTime: 5000, // 5 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
