import React from "react";
import withDefaults from "../../lib/util-functions/with-defaults";
import TableCell from "./table-cell";
import { useTableContext } from "./table-context";

interface Props {
  hover: boolean;
  emptyText: string;
  onRow: (row: any, index: number) => void;
  onCell: (cell: any, index: number, colunm: number) => void;
  data: Array<any>;
  className?: string;
}

const defaultProps = {
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type TableBodyProps = Props & typeof defaultProps & NativeAttrs;

export type cellActions = {
  remove: Function;
};

export type cellData = {
  row: number;
  column: number;
  value: any;
};

const TableBody: React.FC<TableBodyProps> = ({
  data,
  hover,
  emptyText,
  onRow,
  onCell,
  className,
}) => {
  const { columns } = useTableContext();
  const rowClickHandler = (row: any, index: number) => {
    onRow(row, index);
  };

  return (
    <tbody className="border border-black">
      {data.map((row, index) => {
        return (
          <tr
            key={`tbody-row-${index}`}
            className={`group/row transition-colors duration-200 ease-out bg-gray-100 dark:bg-gray-800 rounded-xl  ${
              hover ? "hover:bg-gray-200 dark:hover:bg-gray-700" : ""
            } ${className}`}
            onClick={() => rowClickHandler(row, index)}
          >
            <TableCell
              columns={columns}
              row={row}
              rowIndex={index}
              emptyText={emptyText}
              onCellClick={onCell}
            />
          </tr>
        );
      })}
    </tbody>
  );
};

const MemoTableBody = React.memo(TableBody);

export default withDefaults(MemoTableBody, defaultProps);
