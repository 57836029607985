import { LoginFormData } from "@components/Login/LoginView";
import axios, { AxiosError } from "axios";
import { APIError, UserSession } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TUtils from "@lib/util-functions/TUtils";
import { del } from "idb-keyval";
import { isPrivateMode } from "@lib/util-functions/checkPrivateMode";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
export let globalToken: string = "";

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (loginData: LoginFormData) => {
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/account/login`,
          {
            email: loginData.email?.trim(),
            password: TUtils.hash(loginData.password?.trim()),
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status !== 200) {
          throw new Error(response.data.error);
        }
        // Inferred return type: Promise<MyData>
        const token = response.data?.result?.session;
        if (token) {
          getLocalStorage()?.setItem("token", JSON.stringify(token));
          if (!getLocalStorage()) {
            globalToken = token;
          }
        }

        // deleting query cache in IDB
        try {
          if (!(await isPrivateMode())) {
            await del("tracify_cache");
          }
        } catch (error) {
          // failed to delete indexedDB table, only the case for when browsers don't support indexedDB, which makes this obsolete anyway
        }
        await queryClient.invalidateQueries();

        return response.data.result as UserSession;
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          if (!err.response) {
            throw err;
          }
          const axiosError = err as AxiosError;
          const apiError = axiosError.response?.data as APIError;
          console.log(axiosError);
          throw new Error(apiError?.error ?? axiosError.message);
        }
        throw new Error("Unknown error occurred!");
      }
    },
  });
};
