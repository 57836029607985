import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";

const ResendWebhookSchema = z.object({
  id: z.string(),
  date: z.string(),
  url: z.string(),
});
export type ResendWebhookInput = z.infer<typeof ResendWebhookSchema>;

const onResendWebhook = async (input: ResendWebhookInput, token: string) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    ResendWebhookSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_TRACY_BACKEND_BASE_URL}/webhook/submissions/payload/resend`,
      {
        webhook_id: input.id,
        submission_date: input.date,
        webhook_url: input.url,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as APIError;
      const firstErr = apiError.error;
      throw new Error(firstErr ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useResendWebhook = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: ResendWebhookInput) =>
      onResendWebhook(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["webhookSubmissions"] });
    },
  });
};
