import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { DashboardDatePicker } from "./DashboardDatePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover";

type Props = {
  disabled?: boolean;
  isCompared: boolean;
  children: React.ReactNode;
  maxDate?: Date;
  minDate?: Date;
};
const DatePickerPopover: React.FC<Props> = ({
  disabled,
  children,
  isCompared,
  maxDate,
  minDate,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger
        id="date-select-popover"
        disabled={disabled}
        className={`h-full w-full flex items-center rounded-md overflow-hidden focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-primary focus:ring-offset-primary`}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent className="z-popover w-full">
        <DashboardDatePicker
          isCompared={isCompared}
          closeDatePicker={() => setOpen(false)}
          maxDate={maxDate}
          minDate={minDate}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePickerPopover;
