import React from "react";
type Props = { children: React.ReactNode; open: boolean };
const NvrTooltip = ({ children, open }: Props) => {
  return (
    <span className="h-full relative">
      {children}
      {open ? (
        <div
          className={`absolute mt-1 bg-gray-200 dark:bg-gray-700 shadow-lg border border-gray-600 rounded-lg px-4 py-3 w-96 left-1/2 transform -translate-x-1/2 ${
            !open ? "opacity-0" : "opacity-100"
          } transition-opacity duration-200 z-40`}
        >
          <span className="text-sm text-foreground-soft">
            Data loading currently takes longer due to User Segmentation being
            activated. For faster loading, choose “All Users” from the User
            Segment dropdown on the right.
          </span>
        </div>
      ) : null}
    </span>
  );
};

export default NvrTooltip;
