import { useProportions } from "../../lib/util-functions";
import { NormalTypes } from "../../lib/util-types";
import React from "react";
import s from "./progress.module.css";

export type ProgressColors = {
  [key: number]: string;
};

interface Props {
  value?: number;
  max?: number;
  fixedTop?: boolean;
  fixedBottom?: boolean;
  colors?: ProgressColors;
  type?: NormalTypes;
  className?: "";
}

const defaultProps = {};

type NativeAttrs = Omit<React.ProgressHTMLAttributes<any>, keyof Props>;
export type ProgressProps = Props & typeof defaultProps & NativeAttrs;

const getCurrentColor = (
  ratio: number,
  type: NormalTypes,
  colors: ProgressColors = {}
): string => {
  const defaultColors: { [key in NormalTypes]: string } = {
    default: "bg-foreground",
    primary: "bg-primary",
    secondary: "bg-accent",
    warning: "bg-yellow-500",
    error: "bg-red-500",
  };
  const colorKeys = Object.keys(colors);
  if (colorKeys.length === 0) return defaultColors[type];

  const customColorKey = colorKeys.find((key) => ratio <= +key);
  if (!customColorKey || Number.isNaN(+customColorKey))
    return defaultColors[type];
  return colors[+customColorKey];
};

export const Progress: React.FC<ProgressProps> = ({
  value = 0,
  max = 100,
  type = "default" as NormalTypes,
  fixedTop = false,
  fixedBottom = false,
  className = "",
  colors,
  ...props
}) => {
  const percentValue = useProportions(value, max);
  const currentColor = getCurrentColor(percentValue, type, colors);
  const fixed = fixedTop || fixedBottom;

  return (
    <div
      className={`w-full h-1.5 bg-gray-200 rounded-md ${className} ${
        fixed
          ? `${s["progress-fixed"]} fixed left-0 rounded-none ${
              fixedTop ? "top-0" : ""
            } ${fixedBottom ? "bottom-0" : ""}`
          : "relative"
      }`}
    >
      <div
        className={`${s.inner} inner absolute top-0 left-0 bottom-0 h-full transition-all duration-100 ease-in rounded-md ${currentColor}`}
        title={`${percentValue}%`}
        style={{
          width: `${percentValue}%`,
        }}
      />
      <progress
        className={`${s.progress} fixed opacity-0 invisible pointer-events-none  ${className}`}
        value={value}
        max={max}
        {...props}
      />
    </div>
  );
};

export default Progress;
