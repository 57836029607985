import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { jwtDecode } from "jwt-decode";
import { TracifyJWTPayload } from "../types/backendTypes";

/**
 * Decode JWT Token and provide payload
 *
 * @param {string} token JWT token from Tracify.
 * @return {TracifyJWTPayload} Payload information.
 */
export function decodeToken(token: string): TracifyJWTPayload {
  const payload = jwtDecode<TracifyJWTPayload>(token);
  payload.scp = new Map<string, Array<string>>(Object.entries(payload.scp));
  return payload;
}

/**
 * Checks whether given token payload has access to requested scope.
 *
 * @param {TracifyJWTPayload} payload Payload to check.
 * @param {string} requestedScope Requested access scope.
 * @return {boolean} True if access is allowed.
 */
export function hasPayloadAccess(
  payload: TracifyJWTPayload,
  requestedScope: string
): boolean {
  const scopes = payload.scp.get("any");
  if (DEPLOY_ENVIRONMENT === "development") {
    if (!payload.ema.endsWith("@tracify.ai")) {
      return false;
    }
  }
  if (!scopes) return false;
  return scopes.includes(requestedScope);
}

/**
 * Checks whether given token has access to requested scope.
 *
 * @param {string} token JWT-Token to check.
 * @param {string} requestedScope Requested access scope.
 * @return {boolean} True if access is allowed.
 */
export function hasTokenAccess(token: string, requestedScope: string): boolean {
  const payload = decodeToken(token);
  return hasPayloadAccess(payload, requestedScope);
}

/**
 * Checks whether given token payload has access to requested scope on given ressource.
 *
 * @param {TracifyJWTPayload} payload Payload to check.
 * @param {string} requestedScope Requested access scope.
 * @param {string} ressourceId ID of the ressource for which scope should be checked (e.g. store-id)
 * @return {boolean} True if access is allowed.
 */
export function hasPayloadRessourceAccess(
  payload: TracifyJWTPayload,
  requestedScope: string,
  ressourceId: string
): boolean {
  // general access?
  if (hasPayloadAccess(payload, requestedScope)) return true;

  // check specific ressource
  const scopes = payload.scp.get(ressourceId);
  if (!scopes) return false;
  return scopes.includes(requestedScope);
}

/**
 * Checks whether given token payload has access to requested scope on given ressource.
 *
 * @param {string} token JWT-Token to check.
 * @param {string} requestedScope Requested access scope.
 * @param {string} ressourceId ID of the ressource for which scope should be checked (e.g. store-id)
 * @return {boolean} True if access is allowed.
 */
export function hasTokenRessourceAccess(
  token: string,
  requestedScope: string,
  ressourceId: string
): boolean {
  const payload = decodeToken(token);
  return hasPayloadRessourceAccess(payload, requestedScope, ressourceId);
}
