import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";

export const CreateInfluencerSchema = z.object({
  name: z.string({ required_error: "Name is required" }),
  contact_email: z
    .string()
    .email({
      message: "Not a valid email address",
    })
    .optional(),
  blog_account_name: z.string().optional(),
  facebook_account_name: z.string().optional(),
  instagram_account_name: z.string().optional(),
  tiktok_account_name: z.string().optional(),
  twitch_account_name: z.string().optional(),
  youtube_account_name: z.string().optional(),
  pinterest_account_name: z.string().optional(),
  linkedin_account_name: z.string().optional(),
  snapchat_account_name: z.string().optional(),
  podcast_account_name: z.string().optional(),
  twx_account_name: z.string().optional(),
  threads_account_name: z.string().optional(),
  other_account_name: z.string().optional(),
  organisation_id: z.string({
    required_error: "Organisation ID is required.",
  }),
});
const RefinedCreateInfluencerSchema = CreateInfluencerSchema.refine(
  ({
    blog_account_name,
    facebook_account_name,
    instagram_account_name,
    linkedin_account_name,
    pinterest_account_name,
    podcast_account_name,
    snapchat_account_name,
    tiktok_account_name,
    twitch_account_name,
    youtube_account_name,
    threads_account_name,
    twx_account_name,
    other_account_name,
  }) =>
    blog_account_name ||
    facebook_account_name ||
    threads_account_name ||
    twx_account_name ||
    other_account_name ||
    instagram_account_name ||
    linkedin_account_name ||
    pinterest_account_name ||
    podcast_account_name ||
    snapchat_account_name ||
    twitch_account_name ||
    youtube_account_name ||
    tiktok_account_name,
  { message: "At least one of the available channels must be defined" }
);

export type CreateInfluencerInput = z.infer<typeof CreateInfluencerSchema>;

const onCreateInfluencer = async (
  input: CreateInfluencerInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    RefinedCreateInfluencerSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/create`,
      {
        name: input.name,
        contact_email: input.contact_email,
        blog_account_name: input.blog_account_name,
        facebook_account_name: input.facebook_account_name,
        instagram_account_name: input.instagram_account_name,
        tiktok_account_name: input.tiktok_account_name,
        twitch_account_name: input.twitch_account_name,
        youtube_account_name: input.youtube_account_name,
        pinterest_account_name: input.pinterest_account_name,
        linkedin_account_name: input.linkedin_account_name,
        snapchat_account_name: input.snapchat_account_name,
        podcast_account_name: input.podcast_account_name,
        twx_account_name: input.twx_account_name,
        threads_account_name: input.threads_account_name,
        other_account_name: input.other_account_name,
        organisation_id: input.organisation_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    if (err.issues && err.issues[0]) {
      throw new Error(err.issues[0].message);
    }

    throw new Error("Unkown error occurred!");
  }
};

export const useCreateInfluencer = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: CreateInfluencerInput) =>
      onCreateInfluencer(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
    },
  });
};
