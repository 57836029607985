import NvrTooltip from "@components/NvrDropdown/NvrTooltip";
import { Button } from "@components/ui/button";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@ui";
import React from "react";

type Props = {
  showNvrTooltip: boolean;
  onFetchData: () => void;
  isPending: boolean;
  fetchingData: boolean;
  progress?: number;
};

const PageLoadingButton = ({
  fetchingData,
  isPending,
  onFetchData,
  progress,
  showNvrTooltip,
}: Props) => {
  return (
    <NvrTooltip open={showNvrTooltip}>
      <Button
        onClick={() => {
          onFetchData();
        }}
        className=""
        id="page-loading-button"
        disabled={isPending || fetchingData}
      >
        {fetchingData ? (
          <>
            <Spinner className="mr-2" />
            <span>
              Fetching Data{" "}
              {progress ? `(${fetchingData ? progress?.toFixed(0) : 99}%)` : ""}
            </span>
          </>
        ) : (
          <ArrowPathIcon className="w-5 h-5" />
        )}
      </Button>
    </NvrTooltip>
  );
};

export default PageLoadingButton;
