import { NormalSizes } from "@lib/util-types";

export interface TabSize {
  fontSize: string;
}

export const getSizes = (size?: NormalSizes) => {
  const sizes: { [key in NormalSizes]: TabSize } = {
    medium: {
      fontSize: "text-base",
    },
    small: {
      fontSize: "text-sm",
    },
    large: {
      fontSize: "text-lg",
    },
  };

  return size ? sizes[size] : sizes.medium;
};
