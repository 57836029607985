import { NormalSizes } from "../../lib/util-types";
import React, { useMemo } from "react";
import s from "./spinner.module.css";
interface Props {
  size?: NormalSizes;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type SpinnerProps = Props & NativeAttrs;

const getSpans = (color?: string) => {
  return [...new Array(12)].map((_, index) => (
    <span
      className={`${s.slice} absolute ${
        color ? color : "bg-white dark:bg-foreground"
      } rounded-md`}
      key={`spinner-${index}`}
    ></span>
  ));
};

const getWidth = (size: NormalSizes) => {
  const widths: { [key in NormalSizes]: string } = {
    small: ".75rem",
    medium: "1rem",
    large: "1.25rem",
  };

  return widths[size];
};

export const Spinner: React.FC<SpinnerProps> = ({
  size = "medium",
  className = "",
  color,
  ...props
}) => {
  const width = useMemo(() => getWidth(size), [size]);

  return (
    <div
      className={`${s.spinner} ${className}`}
      style={{
        width: width,
        height: width,
      }}
      {...props}
    >
      <div className={s.container}>{getSpans(color)}</div>
    </div>
  );
};

export default Spinner;
