import * as React from "react";

import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export const inputVariants = cva(
  "text-sm relative truncate flex h-12 w-full rounded-md border border-input px-3 py-1 shadow-sm transition-colors shadow-transparent file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground  placeholder:text-gray-950 dark:placeholder:text-gray-50 placeholder:opacity-40 hover:shadow-hover focus-within:outline-none focus:outline-none focus:border-transparent focus-within:ring-1 focus-within:ring-foreground data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-[56%] data-[disabled=true]:shadow-none",
  {
    variants: {
      variant: {
        default: "bg-gray-200 dark:bg-gray-700",
        alternate: "bg-gray-100 dark:bg-gray-800",
        ghost: "bg-transparent border-transparent",
      },
      size: {
        default: "h-12",
        sm: "h-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    VariantProps<typeof inputVariants> {
  loading?: boolean;
  icon?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      size,
      variant,
      icon,
      loading,
      endAdornment,
      startAdornment,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          inputVariants({
            variant: variant,
            size: size,
          }),
          icon ? "pl-9" : "",
          loading ? "pr-9" : "",
          className
        )}
        data-disabled={props.disabled}
      >
        {icon && (
          <div className="absolute left-3 top-1/2 -translate-y-1/2">{icon}</div>
        )}
        {startAdornment && (
          <span className="flex items-center text-gray-950/40 dark:text-gray-50/40">
            {startAdornment}
          </span>
        )}
        <input
          type={type}
          className={cn(
            "text-sm w-full p-0 bg-transparent border-none ring-0 outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:border-none disabled:cursor-not-allowed",
            {
              "pl-1.5": !!startAdornment,
              "pr-1.5": !!endAdornment,
            }
          )}
          ref={ref}
          {...props}
        />
        {loading && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <ArrowPathIcon className="w-4 h-4 animate-spin" />
          </div>
        )}
        {endAdornment ? (
          <span className="relative flex items-center text-gray-950/40 dark:text-gray-50/40">
            {endAdornment}
          </span>
        ) : null}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
