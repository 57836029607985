import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToCooperations } from "./transformations";
import { Cooperation } from "./types";
import { ChiefAPIError } from "@api/types/backendTypes";

export const UseCooperationsPropsSchema = z.object({
  organisationId: z.string({ required_error: "Organisation ID is required" }),
  csids: z.array(z.string()).optional(),
});

export const FetchCooperationsSchema = UseCooperationsPropsSchema.extend({
  token: z.string({ required_error: "User Session not available" }),
});

export type UseCooperationsProps = z.infer<typeof UseCooperationsPropsSchema>;

export type FetchCooperationsProps = z.infer<typeof FetchCooperationsSchema>;

export async function fetchCooperations({
  token,
  organisationId,
  csids,
}: FetchCooperationsProps): Promise<Array<Cooperation>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  if (!organisationId) {
    throw new Error("Organisation ID is required");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/cooperations/list?organisation_id=${organisationId}`,
      {
        params: {
          limit: 100000,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // Inferred return type: Promise<MyData>
    return transformToCooperations(response.data.items, csids);
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseCooperationsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Cooperation>) => void) | undefined;
};

export function useCooperations(
  props: UseCooperationsProps,
  options?: UseCooperationsOptions
) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["cooperations", { ...props }],
    queryFn: () => fetchCooperations({ ...props, token: data?.token! }),

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5000, // 5 seconds needed to overwrite global settings
    gcTime: 5000, // 5 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
