import { attributionModelAtom, selectedMarketingChannelsAtom } from "@/atoms";
import { CONSTANT_LABELS, PAID_CHANNELS } from "@/constants/constants";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { STORAGE_KEYS } from "./constants";

export const useInitializeAttributionSettings = ({
  requiredChannel,
  enabled = true,
}: {
  requiredChannel?: string;
  enabled?: boolean;
}) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedMarketingChannels, setSelectedMarketingChannels] = useAtom(
    selectedMarketingChannelsAtom
  );
  const [attributionModel, setAttributionModel] = useAtom(attributionModelAtom);
  const channelToAdd =
    requiredChannel === "influencer_module" ? "influencer" : requiredChannel;
  useEffect(() => {
    if (requiredChannel) {
      setInitialized(false);
    }
  }, [requiredChannel]);

  useEffect(() => {
    if (initialized) return;
    if (attributionModel === "isolated" && channelToAdd) {
      setSelectedMarketingChannels([channelToAdd]);
    } else if (attributionModel === "isolated" && !channelToAdd) {
      // TODO load data that was set before isolated attribution was selected
      const settings = getLocalStorage()?.getItem(
        STORAGE_KEYS.SETTINGS_BEFORE_ISOLATED
      );
      if (settings) {
        const parsedSettings = JSON.parse(settings);
        setSelectedMarketingChannels(parsedSettings.touchpoints);
        setAttributionModel(parsedSettings.attributionModel);
      } else {
        setSelectedMarketingChannels(PAID_CHANNELS.map((el) => el.value));
        setAttributionModel("dda_stcb_purchaseatd_t3");
      }
    } else if (
      enabled &&
      channelToAdd &&
      !selectedMarketingChannels.includes(channelToAdd)
    ) {
      setSelectedMarketingChannels((current) => {
        const newState = [...current];
        if (!newState.includes(channelToAdd)) {
          newState.push(channelToAdd);
        }
        return newState;
      });

      toast.info(
        `${
          CONSTANT_LABELS[requiredChannel!] ?? requiredChannel
        } was added to your touchpoint selection as it is required for this dashboard to display data.`,
        {
          id: "required-channel-added",
          position: "top-center",
        }
      );
    }
    setInitialized(true);
  }, [
    requiredChannel,
    attributionModel,
    selectedMarketingChannels,
    enabled,
    setSelectedMarketingChannels,
    setAttributionModel,
    initialized,
    channelToAdd,
  ]);
};
