import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { CreateCooperationLinkSchema } from "./useCreateLink";

const UpdateCooperationLinkSchema = CreateCooperationLinkSchema.extend({
  linkId: z.string(),
});
export type UpdateCooperationLinkInput = z.infer<
  typeof UpdateCooperationLinkSchema
>;

const onUpdateCooperationLink = async (
  input: UpdateCooperationLinkInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    UpdateCooperationLinkSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/links/${input.linkId}/update`,
      {
        title: input.title,
        cooperation_id: input.cooperation_id,
        target_url: input.target_url,
        existing_utm: input.existing_utm,
        discount_code: input.discount_code,
        url_shortener: input.url_shortener,
        active: input.active,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useUpdateCooperationLink = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: UpdateCooperationLinkInput) =>
      onUpdateCooperationLink(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["cooperationLinks"] });
      queryClient.invalidateQueries({ queryKey: ["cooperationLink"] });
      queryClient.invalidateQueries({ queryKey: ["cooperations"] });
      queryClient.invalidateQueries({ queryKey: ["cooperation"] });
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
      queryClient.invalidateQueries({ queryKey: ["influencer"] });
    },
  });
};
