import { ButtonTypes, NormalSizes } from "../../lib/util-types";
import React, { useMemo } from "react";
import { ButtonGroupContext, ButtonGroupConfig } from "./button-group-context";
import s from "./button-group.module.css";
interface Props {
  disabled?: boolean;
  vertical?: boolean;
  ghost?: boolean;
  size?: NormalSizes;
  type?: ButtonTypes;
  className?: string;
}

const defaultProps = {
  disabled: false,
  vertical: false,
  ghost: false,
  size: "medium" as NormalSizes,
  type: "default" as ButtonTypes,
  className: "",
};

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type ButtonGroupProps = Props & typeof defaultProps & NativeAttrs;

const getGroupBorderColors = (props: ButtonGroupProps): string => {
  const { ghost, type } = props;
  if (!ghost && type !== "default") return "border-background";
  const colors: { [key in ButtonTypes]?: string } = {
    default: "border-gray-100",
    primary: "border-primary",
    secondary: "border-secondary",
    error: "border-red-500",
    warning: "border-yellow-500",
  };
  return colors[type] || (colors.default as string);
};

const ButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = (
  groupProps
) => {
  const {
    disabled,
    size,
    type,
    ghost,
    vertical,
    children,
    className,
    ...props
  } = groupProps;
  const initialValue = useMemo<ButtonGroupConfig>(
    () => ({
      disabled,
      size,
      type,
      ghost,
      isButtonGroup: true,
    }),
    [disabled, size, type]
  );

  const borderColor = useMemo(() => {
    return getGroupBorderColors(groupProps);
  }, [type, disabled, ghost]);

  return (
    <ButtonGroupContext.Provider value={initialValue}>
      <div
        className={`${
          s["btn-group"]
        } inline-flex rounded-md m-2 border bg-transparent overflow-hidden ${borderColor} ${
          vertical ? s.vertical + " flex-col" : s.horizontal + " flex-row"
        } ${className}`}
        {...props}
      >
        {children}
        <style jsx>{``}</style>
      </div>
    </ButtonGroupContext.Provider>
  );
};

export default ButtonGroup;
