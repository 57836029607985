/* eslint-disable guard-for-in */
import { get, set, del } from "idb-keyval";
import {
  PersistedClient,
  Persister,
} from "@tanstack/react-query-persist-client";
import { JSONreplacer, JSONreviver } from "./util-functions/string";
import { isPrivateMode } from "./util-functions/checkPrivateMode";

const MAX_QUERY_COUNT = 15;

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 * @param {IDBValidKey} idbValidKey key for storing data
 * @param {number} throttleTime time to wait before writing to IDB
 * @return {Persister} our new IDB persister
 */
export function createIDBPersister(
  idbValidKey: IDBValidKey = "tracify_cache",
  throttleTime = 5000
) {
  return {
    persistClient: throttle(async (client: PersistedClient) => {
      let clientWithMaxQueryCount = { ...client };
      const queryCount = client.clientState.queries.length;
      if (queryCount > MAX_QUERY_COUNT) {
        clientWithMaxQueryCount = {
          ...client,
          clientState: {
            ...client.clientState,
            queries: client.clientState.queries.slice(
              queryCount - MAX_QUERY_COUNT
            ),
          },
        };
      }
      const stringifiedClient = JSON.stringify(
        clientWithMaxQueryCount,
        JSONreplacer
      );
      try {
        if (!(await isPrivateMode())) {
          await set(idbValidKey, stringifiedClient);
        }
      } catch (error: any) {
        if (
          error.message
            ?.toLowerCase()
            .includes("the serialized value is too large")
        ) {
          // purge all analytics data from cache, because it is too big
          clientWithMaxQueryCount = {
            ...client,
            clientState: {
              ...client.clientState,
              queries: client.clientState.queries.filter(
                (query) => !query.queryKey.includes("analytics")
              ),
            },
          };
          const stringifiedClient = JSON.stringify(
            clientWithMaxQueryCount,
            JSONreplacer
          );
          try {
            if (!(await isPrivateMode())) {
              await set(idbValidKey, stringifiedClient);
            }
          } catch (err) {
            console.log("ERROR", err);
          }
        }
      }
    }, throttleTime),
    restoreClient: async () => {
      const client = await get<string>(idbValidKey);
      const jsonClient = client ? JSON.parse(client, JSONreviver) : null;

      return jsonClient;
    },
    removeClient: async () => {
      if (!(await isPrivateMode())) {
        try {
          await del(idbValidKey);
        } catch (error) {
          console.log("Failed to delete db values");
        }
      }
    },
  } as Persister;
}

function throttle(func: Function, wait = 100) {
  let timer: NodeJS.Timeout | null = null;
  let params: any;
  return function (...args: any) {
    params = args;

    if (timer === null) {
      timer = setTimeout(() => {
        func(...params);
        timer = null;
      }, wait);
    }
  };
}
