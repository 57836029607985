import { BaseScore, BaseScoreWithCreative } from "@api/types/backendTypes";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";

type ScoreType =
  | BaseScoreWithCreative
  | MarketingChannelOverviewInterface
  | BaseScoreWithCreative[]
  | MarketingChannelOverviewInterface[];

export const calculatePurchaseAmount = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let purchaseAmount = 0;
    for (const score of value) {
      purchaseAmount += score.purchaseAmount;
    }
    return purchaseAmount;
  } else {
    return value.purchaseAmount ?? 0;
  }
};

export const calculatePurchaseCount = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let purchaseCount = 0;
    for (const score of value) {
      purchaseCount += score.purchaseCount;
    }
    return purchaseCount;
  } else {
    return value.purchaseCount ?? 0;
  }
};
export const calculateSpend = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let spend = 0;
    for (const score of value) {
      spend += score.spend ?? 0;
    }
    return spend;
  } else {
    return value.spend ?? 0;
  }
};

export const calculateAOV = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let purchaseCount = 0;
    let purchaseAmount = 0;
    for (const score of value) {
      purchaseCount += score.purchaseCount;
      purchaseAmount += score.purchaseAmount;
    }
    if (purchaseAmount > 0 && purchaseCount > 0) {
      return purchaseAmount / purchaseCount;
    }
  } else {
    if (value.aov) {
      return value.aov;
    }
    if (value.purchaseCount && value.purchaseAmount) {
      return value.purchaseAmount / value.purchaseCount;
    }
  }
  return 0;
};

export const calculateAverageTimeWatched = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let impressions = 0;
    let weightedAvarage = 0;
    for (const score of value) {
      impressions += score.impressions ?? 0;
    }
    if (impressions > 0) {
      for (const score of value) {
        weightedAvarage +=
          ((score.videoAvgTimeWatchedActions ?? 0) * (score.impressions ?? 0)) /
          impressions;
      }
    }
    return Math.round(weightedAvarage);
  } else {
    if (value.videoAvgTimeWatchedActions) {
      return value.videoAvgTimeWatchedActions;
    }
  }
  return 0;
};

export const calculateCPO = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let purchaseCount = 0;
    let spend = 0;
    for (const score of value) {
      purchaseCount += score.purchaseCount;
      spend += score.spend ?? 0;
    }
    if (spend > 0 && purchaseCount > 0) {
      return spend / purchaseCount;
    }
  } else {
    if (value.cpo) {
      return value.cpo;
    }
    if (value.purchaseCount && value.spend) {
      return value.spend / value.purchaseCount;
    }
  }
  return 0;
};

export const calculateMER = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let spend = 0;
    let purchaseAmount = 0;
    for (const score of value) {
      spend += score.spend ?? 0;
      purchaseAmount += score.purchaseAmount;
    }
    if (purchaseAmount > 0 && spend > 0) {
      return purchaseAmount / spend;
    }
  } else {
    if (value.spend && value.purchaseAmount) {
      return value.purchaseAmount / value.spend;
    }
  }
  return 0;
};

export const calculateCR = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let pageview = 0;
    let purchaseCount = 0;
    for (const score of value) {
      pageview += score.pageview ?? 0;
      purchaseCount += score.purchaseCount ?? 0;
    }
    if (purchaseCount > 0 && pageview > 0) {
      return purchaseCount / pageview;
    }
  } else {
    if (value.cr) return value.cr;
    if (value.pageview && value.purchaseCount) {
      return value.purchaseCount / value.pageview;
    }
  }
  return 0;
};

// if (adScore.spend && adScore.purchaseAmount > 0) {
//   adScore.roas = adScore.purchaseAmount / adScore.spend;
// }

export const calculateCPC = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let clicks = 0;
    let spend = 0;
    for (const score of value) {
      clicks +=
        score.provider === "facebook"
          ? score.linkClick ?? 0
          : score.clicks ?? 0;
      spend += score.spend ?? 0;
    }
    if (spend > 0 && clicks > 0) {
      return spend / clicks;
    }
  } else {
    if (value.cpc) {
      return value.cpc;
    }
    if (value.provider === "facebook") {
      if (value.linkClick && value.spend) {
        return value.spend / value.linkClick;
      }
    } else {
      if (value.clicks && value.spend) {
        return value.spend / value.clicks;
      }
    }
  }
  return 0;
};

export const calculateCTR = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let clicks = 0;
    let impressions = 0;
    for (const score of value) {
      clicks +=
        score.provider === "facebook"
          ? score.linkClick ?? 0
          : score.clicks ?? 0;
      impressions += score.impressions ?? 0;
    }
    if (impressions > 0 && clicks > 0) {
      return clicks / impressions;
    }
  } else {
    if (value.ctr) {
      return value.ctr;
    }
    if (value.provider === "facebook") {
      if (value.linkClick && value.impressions) {
        return value.linkClick / value.impressions;
      }
    } else {
      if (value.clicks && value.impressions) {
        return value.clicks / value.impressions;
      }
    }
  }
  return 0;
};

export const calculateFrequency = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let reach = 0;
    let impressions = 0;
    for (const score of value) {
      reach += score.reach ?? 0;
      impressions += score.impressions ?? 0;
    }
    if (impressions > 0 && reach > 0) {
      return impressions / reach;
    }
  } else {
    if (value.frequency) {
      return value.frequency;
    }
    if (value.reach && value.impressions) {
      return value.impressions / value.reach;
    }
  }
  return 0;
};

export const calculateThumbstopRatio = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let videoView = 0;
    let impressions = 0;
    for (const score of value) {
      videoView += score.videoView ?? 0;
      impressions += score.impressions ?? 0;
    }
    if (impressions > 0 && videoView > 0) {
      return videoView / impressions;
    }
  } else {
    if (value.thumbstopRatio) {
      return value.thumbstopRatio;
    }
    if (value.videoView && value.impressions) {
      return value.videoView / value.impressions;
    }
  }
  return 0;
};

export const calculateROAS = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let spend = 0;
    let purchaseAmount = 0;
    for (const score of value) {
      spend += score.spend ?? 0;
      purchaseAmount += score.purchaseAmount;
    }
    if (purchaseAmount > 0 && spend > 0) {
      return purchaseAmount / spend;
    }
  } else {
    if (value.roas) {
      return value.roas;
    }
    if (value.spend && value.purchaseAmount) {
      return value.purchaseAmount / value.spend;
    }
  }
  return 0;
};
export const calculateCAC = (value: ScoreType, isNewUserData?: boolean) => {
  if (Array.isArray(value)) {
    let spend = 0;
    let purchaseCount = 0;
    let nvr_purchaseCount = 0;
    for (const score of value) {
      spend += score.spend ?? 0;
      purchaseCount += score.purchaseCount;
      nvr_purchaseCount += score.nvr_purchaseCount ?? 0;
    }
    // added in here to calculate the cac on the nvr all map to which we added
    // the new customers purchaseCount in a separate field called nvr_purchaseCount

    if (
      nvr_purchaseCount > 0 &&
      // we check if it's smaller than the purchaseCount because in nvr = new view
      // the orders added here are the total orders not only the orders from new customers
      !isNewUserData &&
      spend > 0
    ) {
      return spend / nvr_purchaseCount;
    } else if (purchaseCount > 0 && spend > 0 && isNewUserData) {
      return spend / purchaseCount;
    }
  } else {
    if (value.cac) {
      return value.cac;
    }

    if (
      value.nvr_purchaseCount &&
      value.nvr_purchaseCount > 0 &&
      // we check if it's smaller than the purchaseCount because in nvr = new view
      // the orders added here are the total orders not only the orders from new customers
      !isNewUserData &&
      value.spend
    ) {
      return value.spend / value.nvr_purchaseCount;
    } else if (value.spend && value.purchaseCount > 0 && isNewUserData) {
      return value.spend / value.purchaseCount;
    }
  }
  return 0;
};
export const calculateNewCustomerRate = (
  value: ScoreType,
  isNewUserData?: boolean
) => {
  if (Array.isArray(value)) {
    let purchaseCount = 0;
    let nvr_purchaseCount = 0;
    //  if we get the data for the "new" user segment, the standard "purchaseCount" contains
    // only the data for new users, while nvr_purchaseCount contains the data for all,
    // that why we want to reverse the calculation in that case
    // we have to do it this way to stay consistent for any other calculations based on pageviews for new users
    for (const score of value) {
      purchaseCount += !isNewUserData
        ? score.purchaseCount
        : score.nvr_purchaseCount ?? 0;
      nvr_purchaseCount += !isNewUserData
        ? score.nvr_purchaseCount ?? 0
        : score.purchaseCount;
    }
    if (nvr_purchaseCount > 0 && purchaseCount > 0) {
      return nvr_purchaseCount / purchaseCount;
    }
  } else {
    if (value.newCustomerRate) {
      return value.newCustomerRate;
    }

    if (value.purchaseCount && value.nvr_purchaseCount) {
      return !isNewUserData
        ? value.nvr_purchaseCount / value.purchaseCount
        : value.purchaseCount / value.nvr_purchaseCount;
    }
  }
  return 0;
};
export const calculateNewVisitorRate = (
  value: ScoreType,
  isNewUserData?: boolean
) => {
  if (Array.isArray(value)) {
    let pageview = 0;
    let nvr_upv = 0;
    //  if we get the data for the "new" user segment, the standard "pageview" contains
    // only the data for new users, while nvr_upv contains the data for all,
    // that why we want to reverse the calculation in that case
    // we have to do it this way to stay consistent for any other calculations based on pageviews for new users
    for (const score of value) {
      pageview += !isNewUserData ? score.pageview : score.nvr_upv ?? 0;
      nvr_upv += !isNewUserData ? score.nvr_upv ?? 0 : score.pageview;
    }
    if (nvr_upv > 0 && pageview > 0) {
      return nvr_upv / pageview;
    }
  } else {
    if (value.newVisitorRate) {
      return value.newVisitorRate;
    }

    if (value.pageview && value.nvr_upv) {
      return !isNewUserData
        ? value.nvr_upv / value.pageview
        : value.pageview / value.nvr_upv;
    }
  }
  return 0;
};

export const calculateCPM = (value: ScoreType) => {
  if (Array.isArray(value)) {
    let impressions = 0;
    let spend = 0;
    for (const score of value) {
      impressions += score.impressions ?? 0;
      spend += score.spend ?? 0;
    }
    if (spend > 0 && impressions > 0) {
      return spend / (impressions / 1000);
    }
  } else {
    if (value.cpm) {
      return value.cpm;
    }
    if (value.impressions && value.spend) {
      return value.spend / (value.impressions / 1000);
    }
  }
  return 0;
};

export const kpiCalculations = {
  aov: calculateAOV,
  cr: calculateCR,
  cpm: calculateCPM,
  mer: calculateMER,
  cpo: calculateCPO,
  roas: calculateROAS,
  cpc: calculateCPC,
  cac: calculateCAC,
  frequency: calculateFrequency,
  ctr: calculateCTR,
  spend: calculateSpend,
  thumbstopRatio: calculateThumbstopRatio,
  newVisitorRate: calculateNewVisitorRate,
  newCustomerRate: calculateNewCustomerRate,
  purchaseAmount: calculatePurchaseAmount,
  purchaseCount: calculatePurchaseCount,
  videoAvgTimeWatchedActions: calculateAverageTimeWatched,
};
