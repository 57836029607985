import { SnippetTypes } from "../../lib/util-types";
import React, { useMemo } from "react";

interface Props {
  type?: SnippetTypes;
  invert?: boolean;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type TagProps = Props & NativeAttrs;

export type TagColors = {
  color: string;
  bgColor: string;
  borderColor: string;
};

const getColors = (type: SnippetTypes, invert: boolean) => {
  const colors: {
    [key in SnippetTypes]: Pick<TagColors, "color"> & Partial<TagColors>;
  } = {
    default: {
      color: !invert ? "text-foreground" : "text-background",
      bgColor: !invert ? "bg-background" : "bg-foreground",
      borderColor: "border-foreground",
    },
    primary: {
      color: !invert ? "text-primary" : "text-on-primary",
      bgColor: !invert ? "bg-on-primary" : "bg-primary",
      borderColor: "border-primary",
    },
    warning: {
      color: !invert ? "text-yellow-500" : "text-background",
      bgColor: invert ? "bg-yellow-500" : "bg-background",
      borderColor: "border-yellow-500",
    },
    error: {
      color: !invert ? "text-red-500" : "text-background",
      bgColor: invert ? "bg-red-500" : "bg-background",
      borderColor: "border-red-500",
    },
    secondary: {
      color: !invert ? "text-secondary" : "text-on-secondary",
      bgColor: invert ? "bg-secondary" : "bg-on-secondary",
      borderColor: "border-secondary",
    },
    dark: {
      color: invert ? "text-foreground" : "text-background",
      bgColor: invert ? "bg-background" : "bg-foreground",
      borderColor: "border-foreground",
    },
    lite: {
      color: !invert ? "text-foreground" : "text-background",
      bgColor: invert ? "bg-foreground-soft" : "bg-background",
      borderColor: "border-foreground",
    },
  };
  const hideBorder = invert || type === "lite";

  const cardStyle = {
    ...colors[type],
    bgColor: colors[type].bgColor || "bg-background",
    borderColor: hideBorder ? "border-transparent" : colors[type].borderColor,
  };

  return !invert
    ? cardStyle
    : {
        ...cardStyle,
        color: cardStyle.bgColor,
        bgColor: cardStyle.color,
      };
};

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({
  children,
  type = "default" as SnippetTypes,
  invert = false,
  className = "",
  ...props
}) => {
  const { color, bgColor, borderColor } = useMemo(
    () => getColors(type, invert),
    [type, invert]
  );
  return (
    <span
      className={` inline-block text-xs rounded-md py-0.5 px-1 box-border border ${color} ${bgColor} ${borderColor} ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

export default Tag;
