import { NormalSizes } from "@lib/util-types";
import React, { useEffect, useMemo, useState } from "react";
import { getSizes } from "./styles";
import { TabsLabelItem, TabsConfig, TabsContext } from "./tabs-context";
import s from "./tabs.module.css";

interface Props {
  initialValue?: string;
  center?: boolean;
  value?: string;
  hideDivider?: boolean;
  tabSpacing?: "px-2" | "px-4" | "px-6" | "px-8" | "px-12" | "px-16" | "px-20";
  size?: NormalSizes;
  onChange?: (val: string) => void;
  className?: string;
  headerClassname?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type TabsProps = Props & NativeAttrs;

export const Tabs: React.FC<React.PropsWithChildren<TabsProps>> = ({
  initialValue: userCustomInitialValue,
  value,
  hideDivider = false,
  center = false,
  children,
  tabSpacing,
  onChange,
  size = "medium",
  className = "",
  headerClassname = "",
  ...props
}) => {
  const [selfValue, setSelfValue] = useState<string | undefined>(
    userCustomInitialValue
  );
  const [tabs, setTabs] = useState<Array<TabsLabelItem>>([]);
  const { fontSize } = useMemo(() => getSizes(size), [size]);
  const register = (next: TabsLabelItem) => {
    setTabs((last) => {
      const hasItem = last.find((item) => item.value === next.value);
      if (!hasItem) return [...last, next];
      return last.map((item) => {
        if (item.value !== next.value) return item;
        return {
          ...item,
          label: next.label,
          disabled: next.disabled,
        };
      });
    });
  };

  const initialValue = useMemo<TabsConfig>(
    () => ({
      register,
      currentValue: selfValue,
      inGroup: true,
    }),
    [selfValue]
  );

  useEffect(() => {
    if (value === undefined) return;
    setSelfValue(value);
  }, [value]);

  useEffect(() => {
    if (selfValue) {
      const tabEl = document.querySelector(`#${selfValue}`);
      const tabsContainer = document.querySelector(`#tabs-container`);
      if (tabEl && tabsContainer && tabEl instanceof HTMLElement) {
        tabsContainer.scrollTo({
          left: tabEl.offsetLeft - 16,
          behavior: "smooth",
        });
      }
    }
  }, [selfValue]);

  const clickHandler = (item: TabsLabelItem) => {
    if (item.disabled) return;
    setSelfValue(item.value);
    onChange && onChange(item.value);
  };

  return (
    <TabsContext.Provider value={initialValue}>
      <div className={`${s.tabs} ${className}`} {...props}>
        <header
          className={`${headerClassname} flex w-auto max-w-full overflow-y-hidden overflow-x-auto items-center border-gray-600 ${
            hideDivider ? s["hide-divider"] : "border-b"
          } ${center ? "mx-auto justify-center" : ""}
          `}
          id="tabs-container"
        >
          {tabs.map((item) => (
            <div
              className={` ${
                s.tab
              } cursor-pointer outline-none transition-all duration-200 ease-out tracking-tight font-semibold ${fontSize}  my-0 select-none flex items-center leading-tight relative  ${
                item.disabled
                  ? s.disabled + " text-gray-400 cursor-not-allowed"
                  : ""
              } ${
                selfValue === item.value ? s.active : "text-foreground-soft"
              } `}
              role="button"
              key={item.value}
              id={item.value}
              onClick={() => clickHandler(item)}
            >
              <span
                className={`transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-md py-2 mb-1 ${
                  tabSpacing ? tabSpacing : "px-8"
                } `}
              >
                {item.label}
              </span>
            </div>
          ))}
        </header>
        <div className={`pt-4`}>{children}</div>
      </div>
    </TabsContext.Provider>
  );
};

export default Tabs;
