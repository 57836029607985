import React, { useEffect } from "react";
import { PageHeaderInterface } from "../../../interface/PageHeaderInterface";
import DatePickerPopover from "@components/Datepicker/DatePickerPopover";
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useTimeRangeLabel } from "@lib/hooks/useTimeRangeLabel";
import { comparedAtom } from "atoms";
import { useAtom } from "jotai";
import AttributionMenu from "@components/AttributionMenu/AttributionMenu";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { CONSTANT_LABELS } from "@/constants/constants";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Button } from "@components/ui/button";

export function DatePageHeader({
  title,
  maxDate,
  minDate,
  children,
  hideAttributionMenu = false,
  enableCompare = false,
  requiredChannel,
}: PageHeaderInterface) {
  const { label, comparedLabel } = useTimeRangeLabel();
  const { notSelfOnboardedAccounts } = useSelectedStores();
  const [isCompared, setIsCompared] = useAtom(comparedAtom);
  useEffect(() => {
    if (!enableCompare && isCompared) {
      setIsCompared(false);
    }
  }, [isCompared, enableCompare, setIsCompared]);
  const notSelfOnboardedChannels = [
    ...new Set(notSelfOnboardedAccounts.map((el) => el.source)),
  ];
  return (
    <>
      <div className="grid grid-cols-3 items-center gap-2 relative z-navbar">
        {notSelfOnboardedAccounts.length > 0 ? (
          <div className="bg-yellow-400 dark:bg-yellow-600 px-2 py-1 w-[calc(100%+32px)] flex items-center col-span-3 -mt-2 -mx-4">
            <InformationCircleIcon className="h-5 w-5"></InformationCircleIcon>
            <p className="text-sm ml-1 mr-3">
              To be able to show you data like the ad spend from your{" "}
              {notSelfOnboardedChannels
                .map((el) => CONSTANT_LABELS[el] ?? el)
                .join(", ")}{" "}
              ad account(s), please reconnect them by clicking the button to the
              right.
            </p>
            <LinkWithPersistentQuery href="/settings/integrations">
              <Button variant="primary" size="xs">
                Reconnect
              </Button>
            </LinkWithPersistentQuery>
          </div>
        ) : null}
        <div className="w-full col-span-3 sm:col-span-2 flex items-center">
          {typeof title === "string" ? <h2 className="h2">{title}</h2> : title}
        </div>
        <div className="w-full flex justify-end items-start self-start lg:min-w-[320p] col-span-3 sm:col-span-1 ">
          {children}

          <div className="w-fit flex-none">
            <DatePickerPopover
              isCompared={enableCompare}
              maxDate={maxDate}
              minDate={minDate}
            >
              <div className="px-3 h-12 w-fit 2xl:px-8 bg-gray-200 dark:bg-gray-700 rounded-md  cursor-pointer flex flex-none items-center justify-center">
                <div className="">
                  <div className="text-xs text-foreground text-center">
                    {label}
                  </div>
                  {comparedLabel && enableCompare ? (
                    <div className="w-full text-xs text-foreground-soft text-center">
                      {comparedLabel}
                    </div>
                  ) : null}
                </div>
                <div className="ml-2">
                  <ChevronDownIcon
                    color={"var(--color-foreground)"}
                    className="h-4 w-4"
                  />
                </div>
              </div>
            </DatePickerPopover>
          </div>
        </div>
        {hideAttributionMenu ? null : (
          <div className="w-full flex justify-end col-span-3">
            <AttributionMenu requiredChannel={requiredChannel} />
          </div>
        )}
      </div>
    </>
  );
}
