import axios, { AxiosError } from "axios";
import { TracifyJWTPayload, TracifySettings } from "@api/types/backendTypes";
import { DASHBOARD_APPROVALS } from "constants/constants";
import dayjs from "dayjs";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { getLocalStorage } from "@lib/util-functions/getLocalStorage";
import { globalToken } from "../useLogin";

export type AuthPayloadType = {
  payload: TracifyJWTPayload;
  token: string;
  settings: TracifySettings;
};

/**
 * Checks whether token exists in localStorage.
 *
 * @return {Promise<AuthPayloadType | null>} AuthPayloadType if token exists or null
 */
async function fetchAuth(): Promise<AuthPayloadType | null> {
  const token = getLocalStorage()?.getItem("token")
    ? JSON.parse(getLocalStorage()?.getItem("token") || "")
    : globalToken;
  if (token) {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/account/info`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": token,
          },
        }
      );
      const localSettings = window.localStorage.getItem("settings") ?? "";
      const settings = localSettings ? JSON.parse(localSettings ?? "{}") : {};
      if (response.status !== 200) {
        throw new Error(response.data.error);
      }
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      if (
        !response.data.result.valid ||
        (response.data.result?.expires &&
          dayjs(response.data.result?.expires).isBefore(new Date()))
      ) {
        throw new Error("Token is invalid. Please sign in again.");
      }
      const payload = response.data.result.payload;
      payload.scp = new Map<string, Array<string>>(Object.entries(payload.scp));
      return { payload, token, settings };
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const errorMessage = axiosError.response?.data as {
          error: string;
          success: boolean;
        };
        throw new Error(errorMessage?.error ?? axiosError?.message);
      }
      if (err.message === "Token is invalid. Please sign in again.") {
        throw err;
      }
      throw new Error("Unknown error occurred! Please refresh the page.");
    }
  }
  throw new Error("No token available. Please sign in again.");
}

function invalidateToken() {
  const queryClient = new QueryClient();
  getLocalStorage()?.removeItem("token");
  queryClient.clear();
  queryClient.invalidateQueries({ queryKey: ["auth"] });
}

function useAuth(
  // options?: UseQueryOptions<
  //   AuthPayloadType | null,
  //   AxiosError,
  //   AuthPayloadType | null
  // > &
  options?: {
    redirect?: string;
    approvals?: Array<(typeof DASHBOARD_APPROVALS)[number]>;
  }
) {
  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: fetchAuth,
    ...options,
    retry: false,
    gcTime: 0,
    staleTime: 0,
    meta: {
      redirect: options?.redirect,
      approvals: options?.approvals,
    },
  });

  return authQuery;
}
export { useAuth, invalidateToken };
