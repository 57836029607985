import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { transformToWebhookSubmissions } from "./transformations";
import { Webhook, WebhookSubmission } from "./types";
import { APIError, ChiefAPIError } from "@api/types/backendTypes";

export const UseWebhookSubmissionsPropsSchema = z.object({
  webhook_id: z.string(),
});

export const FetchWebhookSubmissionsSchema =
  UseWebhookSubmissionsPropsSchema.extend({
    token: z.string({ required_error: "User Session not available" }),
  });

export type UseWebhookSubmissionsProps = z.infer<
  typeof UseWebhookSubmissionsPropsSchema
>;

export type FetchWebhookSubmissionsProps = z.infer<
  typeof FetchWebhookSubmissionsSchema
>;

export async function fetchWebhookSubmissions({
  token,
  webhook_id,
}: FetchWebhookSubmissionsProps): Promise<Array<WebhookSubmission>> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_TRACY_BACKEND_BASE_URL}/webhook/submissions/list`,
      { webhook_id: webhook_id, submission_index: 0, submission_num: 10 },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }

    return transformToWebhookSubmissions(
      response.data?.result?.submissions ?? []
    );
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as APIError;
      const firstErr = apiError.error;
      throw new Error(firstErr ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseWebhookSubmissionsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Array<Webhook>) => void) | undefined;
};

export function useWebhookSubmissions(
  props: UseWebhookSubmissionsProps,
  options?: UseWebhookSubmissionsOptions
) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["webhookSubmissions", { ...props }],
    queryFn: () => fetchWebhookSubmissions({ ...props, token: data?.token! }),
    ...options,
    refetchOnWindowFocus: true,
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
