import { CooperationLink, CooperationLinksAPIResponse } from "../useLinks";

export enum CooperationChannels {
  "tiktok" = "tiktok",
  "facebook" = "facebook",
  "instagram" = "instagram",
  "youtube" = "youtube",
  "blog" = "blog",
  "twitch" = "twitch",
  "pinterest" = "pinterest",
  "linkedin" = "linkedin",
  "snapchat" = "snapchat",
  "podcast" = "podcast",
  "twx" = "twx",
  "threads" = "threads",
  "other" = "other",
}

export type CooperationAPIResponse = {
  id: string;
  name: string;
  influencer_id: string;
  labels?: string[];
  links: CooperationLinksAPIResponse; // one cooperation can have n codes, needs to be the instances
  placement_type: CooperationPlacementTypes;
  source: CooperationChannels;
  scheduled_for: string; // date for which this cooperation is schedule saved as utc string or Date obj
  commission: string;
  currency: string;
  variable_commission: string;
  variable_commission_type: "total" | "percentage"; // either i.e. 5.00€ or 5%
};
export type CooperationsAPIResponse = Array<CooperationAPIResponse>;

export type CooperationPlacementTypes =
  | "post"
  | "story"
  | "reel"
  | "live"
  | "pin"
  | "short"
  | "video"
  | "podcast";

export type Cooperation = {
  id: string;
  name: string;
  influencerId: string;
  labels: string[];
  csids: string[] | null;
  links: CooperationLink[]; // one cooperation can have n codes, needs to be the instances
  discountCodes: CooperationLink[]; // one cooperation can have n codes, needs to be the instances
  placementType: CooperationPlacementTypes;
  scheduledFor: string; // date for which this cooperation is schedule saved as utc string or Date obj
  commission: string;
  currency: string;
  source: CooperationChannels; // tiktok, google and so on
  variableCommission: string;
  variableCommissionType: "total" | "percentage"; // either i.e. 5.00€ or 5%
};
