import { COLUMN_OPTIONS, GENERAL_COLUMN_OPTIONS } from "constants/constants";
import React from "react";
import {
  generalMetricsInformation,
  metricsInformation,
} from "./metricsInformation";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { dashboardModeAtom, newVsReturningAtom } from "atoms";
import { useAtom } from "jotai";
import { Mutable } from "@lib/util-types";

type Props = {
  metric: (typeof COLUMN_OPTIONS)[number]["value"] | "ihc";
  label?: string;
};

const MetricNameWithTooltip = ({ metric, label: labelString }: Props) => {
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const columnOptions =
    dashboardMode === "general" ? GENERAL_COLUMN_OPTIONS : COLUMN_OPTIONS;
  const label =
    columnOptions.find((el: any) => el.value === metric)?.label ??
    labelString ??
    metric;
  const info =
    dashboardMode === "general"
      ? generalMetricsInformation[metric]
      : metricsInformation[metric];

  const transformLabel = (label: string) => {
    if (label === "ROAS") {
      return newVsReturning === "new" ? "Acquisition ROAS" : "ROAS";
    }
    return label;
  };
  if (!info) {
    return <span>{label}</span>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <p>{transformLabel(label)}</p>
      </TooltipTrigger>
      <TooltipContent>
        <p className="max-w-xs">{info}</p>
      </TooltipContent>
    </Tooltip>
  );
};

export default MetricNameWithTooltip;
