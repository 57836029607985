import React from "react";

export interface InputLabel {
  isRight?: boolean;
  fontSize: string;
  colors: string;
}

const InputLabel: React.FC<React.PropsWithChildren<InputLabel>> = ({
  children,
  isRight,
  fontSize,
  colors,
}) => {
  return (
    <span
      className={`inline-flex h-full box-border items-center ${
        typeof children === "string" ? "pointer-events-none border" : ""
      } m-0 py-0 px-3 leading-none rounded-md  ${colors} ${fontSize} ${
        isRight
          ? "right border-l-0 rounded-tl-none rounded-bl-none"
          : "border-r-0 rounded-tr-none rounded-br-none"
      }`}
    >
      {children}
    </span>
  );
};

const MemoInputLabel = React.memo(InputLabel);

export default MemoInputLabel;
