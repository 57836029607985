import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToInfluencer } from "./transformations";
import { Influencer } from "./types";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { ChiefAPIError } from "@api/types/backendTypes";

const UseInfluencerPropsSchema = z.object({
  influencerId: z.string(),
  csids: z.array(z.string()).optional(),
});

const FetchInfluencerSchema = z.object({
  influencerId: z.string(),
  csids: z.array(z.string()).optional(),
  token: z.string({ required_error: "User Session not available" }),
});

export type UseInfluencerProps = z.infer<typeof UseInfluencerPropsSchema>;

export type FetchInfluencerProps = z.infer<typeof FetchInfluencerSchema>;

async function fetchInfluencer({
  token,
  influencerId,
  csids,
}: FetchInfluencerProps): Promise<Influencer | null> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/${influencerId}/get`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }

    const influencer = response.data
      ? transformToInfluencer(response.data, csids)
      : null;
    return influencer;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as ChiefAPIError;
      const firstErr = apiError.detail[0];
      throw new Error(firstErr?.msg ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseInfluencerOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Influencer | null) => void) | undefined;
};

export function useInfluencer(
  props: UseInfluencerProps,
  options?: UseInfluencerOptions
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  return useQuery({
    queryKey: [
      "influencer",
      { ...props, csids: props.csids ?? selectedStoreIds },
    ],
    queryFn: () => {
      return fetchInfluencer({
        ...props,
        token: data?.token!,
        csids: props.csids ?? selectedStoreIds,
      });
    },

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5000, // 5 seconds needed to overwrite global settings
    gcTime: 5000, // 5 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
