import { SidebarTypes } from "../../lib/util-types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import { Fragment, useEffect, useMemo, useRef } from "react";

export interface SidebarProps {
  title?: string | React.ReactNode;
  onClose: () => void;
  open?: boolean;
  unmount?: boolean;
  className?: string;
  position?: SidebarTypes;
  children: React.ReactNode;
}

const getPosition = (position: SidebarTypes) => {
  const positions: { [key in SidebarTypes]: string } = {
    left: "align-left text-left",
    right: "align-right text-right",
  };

  if (!position) return positions.right;
  return positions[position];
};
const getTransitions = (position: SidebarTypes) => {
  const transitions: { [key in SidebarTypes]: { [key: string]: string } } = {
    left: {
      enter: "transition-all ease-out duration-300",
      enterFrom: "-left-full opacity-0",
      enterTo: "left-0 opacity-100",
      leave: "ease-in duration-200",
      leaveFrom: "left-0 opacity-100",
      leaveTo: "-left-full opacity-0",
    },
    right: {
      enter: "transition-all ease-out duration-300",
      enterFrom: "-right-full opacity-0",
      enterTo: "right-0 opacity-100",
      leave: "ease-in duration-200",
      leaveFrom: "right-0 opacity-100",
      leaveTo: "-right-full opacity-0",
    },
  };

  if (!position) return transitions.right;
  return transitions[position];
};

export const Sidebar: React.FC<SidebarProps> = ({
  title,
  onClose,
  open = false,
  unmount = true,
  children,
  className = "",
  position = "right",
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const styles = useMemo(() => getPosition(position), [position]);
  const transitions = useMemo(() => getTransitions(position), [position]);

  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current, { reserveScrollBarGap: true });
    }
    return () => {
      if (ref && ref.current) {
        enableBodyScroll(ref.current);
      }
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <>
      <Transition appear show={open} as={Fragment} unmount={unmount}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hidden"
          onClose={onClose}
        >
          <div className={`min-h-screen text-left ${styles}`}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div>
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
              </div>
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className={`inline-block h-screen ${styles}`}
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              unmount={unmount}
              enter={transitions.enter}
              enterFrom={transitions.enterFrom}
              enterTo={transitions.enterTo}
              leave={transitions.leave}
              leaveFrom={transitions.leaveFrom}
              leaveTo={transitions.leaveTo}
            >
              <div
                className={`inline-block h-screen relative w-full max-w-md overflow-y-auto overflow-x-hidden text-left align-middle transition-all transform bg-white shadow-xl ${className}`}
              >
                <div className="absolute right-0 top-0 h-full my-6 mx-4 lg:mx-6">
                  <button
                    onClick={() => onClose()}
                    aria-label="Close panel"
                    className={
                      "hover:text-gray-600 text-foreground transition ease-in-out duration-150 z-30 focus:outline-none sticky top-2 "
                    }
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 sticky left-0 right-0 top-0"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="z-20">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Sidebar;
