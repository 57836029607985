import s from "./breadcrumbs.module.css";
import React from "react";

interface Props {
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type BreadcrumbsProps = Props & NativeAttrs;

const BreadcrumbsSeparator: React.FC<
  React.PropsWithChildren<BreadcrumbsProps>
> = ({ children, className = "" }) => {
  return (
    <div
      className={`${s.separator} inline-flex mx-2 select-none pointer-events-none items-center ${className}`}
    >
      {children}
    </div>
  );
};

export default BreadcrumbsSeparator;
