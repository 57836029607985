export const getTotalValueOfAllRows = ({
  values,
  roundBeforeAdding,
  decimals,
}: {
  values: { value: number; compared?: number }[];
  roundBeforeAdding?: boolean;
  decimals?: number;
}) => {
  if (values.length > 0) {
    const value = values.reduce(
      (prev, curr) => {
        if (roundBeforeAdding) {
          let newValue = 0;
          let newComparedValue = 0;
          if (curr.value && !Number.isNaN(curr.value)) {
            newValue =
              Math.round(curr.value * Math.pow(10, decimals ?? 0)) /
              Math.pow(10, decimals ?? 0);
          }
          if (curr.compared && !Number.isNaN(curr.compared)) {
            newComparedValue = curr.compared
              ? Math.round(curr.compared * Math.pow(10, decimals ?? 0)) /
                Math.pow(10, decimals ?? 0)
              : 0;
          }
          return {
            value: prev.value + newValue,
            compared: (prev.compared ?? 0) + newComparedValue,
          };
        } else {
          let newValue = 0;
          let newComparedValue = 0;
          if (curr.value && !Number.isNaN(curr.value)) {
            newValue = curr.value;
          }
          if (curr.compared && !Number.isNaN(curr.compared)) {
            newComparedValue = curr.compared;
          }
          return {
            value: prev.value + newValue,
            compared: (prev.compared ?? 0) + newComparedValue,
          };
        }
      },
      { value: 0, compared: 0 }
    );
    return value;
  }
  return { value: 0, compared: 0 };
};

export const getCorrelation = (
  value: number,
  comparedValue?: number,
  decimals?: number
) => {
  const roundedValue = roundNumber(value, decimals);
  const roundedComparedValue = roundNumber(comparedValue ?? 0, decimals);
  if (!roundedComparedValue && roundedValue) return 100;
  if (!roundedValue && roundedComparedValue) return -100;
  if (roundedComparedValue) {
    return ((roundedValue - roundedComparedValue) / roundedComparedValue) * 100;
  }
  return 0;
};

export const roundNumber = (value: number, decimals?: number) => {
  return (
    Math.round(value * Math.pow(10, decimals ?? 0)) /
    Math.pow(10, decimals ?? 0)
  );
};
