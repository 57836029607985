import useSelectedStores from "@lib/hooks/use-selected-stores";
import {
  comparedAtom,
  endTimeAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  selectedTimezoneAtom,
  startTimeAtom,
} from "atoms";
import { EventSumResult } from "@api/types/backendTypes";
import { atom, useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { invalidateToken, useAuth } from "../useAuth";
import { requestEventList, toEventsRequestPayload } from "@api/services/events";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAttributionSettingsOpen } from "@lib/hooks";

export type EventsResult =
  | (EventSumResult & { compared?: EventsResult })
  | null;
export const eventsDataAtom = atom<EventsResult>({} as EventsResult);
export const fetchingEventsAtom = atom<boolean>(false);
export const initializedEventsAtom = atom<boolean>(false);

let globalRefetchTimeout: NodeJS.Timeout;

type FetchEventsProps = {
  token: string;
  csids: string[];
  startTime: string;
  endTime: string;
  selectedTimezone: string;
  dailyBreakdown?: boolean;
};

/**
 * Requests analytics from Tracify backend.
 *
 * @prop {FetchEventsProps} props
 * @return {EventsResult} the event list during the given timeframe
 */
const fetchEvents = async ({
  token,
  csids,
  endTime,
  selectedTimezone,
  startTime,
  dailyBreakdown,
}: FetchEventsProps) => {
  if (!token) return null;
  if (!csids?.length) {
    toast.warning("You have to select a store before fetching data.");
    return null;
  }
  if (globalRefetchTimeout) clearTimeout(globalRefetchTimeout);

  // get payload for request
  const payload = toEventsRequestPayload({
    csids: csids,
    startTime,
    endTime,
    selectedTimezone: selectedTimezone.toString(),
    interactions: ["purchase"],
    dailyBreakdown,
  });
  const result = await requestEventList({
    requestBody: {
      csids: payload.csids,
      collect_end: payload.collect_end,
      collect_start: payload.collect_start,
      interactions: ["purchase"],
      daily_breakdown: payload.daily_breakdown,
      utc_offset: payload.utc_offset,
    },
    backendUrl: process.env.NEXT_PUBLIC_BACKEND_BASE_URL!,
    token: token,
  }).catch((err) => {
    return null;
  });
  return result;
};

type UseEventProps = {
  dailyBreakdown?: boolean;
};
let timeoutHandler: NodeJS.Timeout | null = null;

function useEvents<TData = EventsResult>(
  props?: UseEventProps,
  options?: { enabled?: boolean }
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const [selectedTimezone] = useAtom(selectedTimezoneAtom);
  const settingsOpenState = useAttributionSettingsOpen();
  const [eventsData, setEventsData] = useAtom(eventsDataAtom);
  const [compared] = useAtom(comparedAtom);
  const [previousStartTime] = useAtom(previousStartTimeAtom);
  const [previousEndTime] = useAtom(previousEndTimeAtom);

  const [selectedStoresAfterClose, setSelectedStoresAfterClose] =
    useState(selectedStoreIds);

  useEffect(() => {
    if (settingsOpenState === false) {
      setSelectedStoresAfterClose(selectedStoreIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreIds.join(","), settingsOpenState]);

  const queryProps = useMemo(
    () => ({
      selectedTimezone,
      token: data?.token as string,
      dailyBreakdown: props?.dailyBreakdown,
    }),
    [selectedTimezone, data?.token, props?.dailyBreakdown]
  );
  // for some values we want to wait a few secs before fetching new data
  // to give the user the chance to adjust them in bulk and prevent
  // spamming unwanted requests to hive
  const [debouncedProps, setDebouncedProps] = useState<{
    selectedTimezone: FetchEventsProps["selectedTimezone"];
    token: string;
    dailyBreakdown?: boolean;
  }>(queryProps);

  useEffect(
    () => {
      // Update debounced value after delay
      if (settingsOpenState === false) {
        timeoutHandler = setTimeout(() => {
          setDebouncedProps(queryProps);
        }, 2000);
      }
      // Cancel the timeout if value changes
      // This is how we prevent debounced value from updating if value is changed
      // within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (timeoutHandler) {
          clearTimeout(timeoutHandler);
          timeoutHandler = null;
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryProps, settingsOpenState] // Only re-call effect if value changes
  );

  const instantProps = useMemo(
    () => ({
      startTime,
      endTime,
      csids: selectedStoresAfterClose,
      compared,
      previousStartTime,
      previousEndTime,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      endTime,
      previousEndTime,
      previousStartTime,
      compared,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedStoresAfterClose.join(","),
      startTime,
    ]
  );

  const completeProps = useMemo(
    () => ({ ...instantProps, ...queryProps }),
    // we disable linting here because we want to rerun this memo when either the instant
    // or the debounced part changed. But in the object we return above, we use the "partialObj"
    // that also instantly changes, to avoid having the situation where the "instantPartialObj" changes
    // we calculate a new obj and then after the debounce we again get new values and have to cancel
    // the old query because the props have changed
    // with this method we always have the current settings as our query props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedProps, instantProps]
  );

  const dataAvailable = useMemo(
    () =>
      completeProps.csids.length > 0 &&
      !!completeProps.startTime &&
      !!completeProps.endTime &&
      !!completeProps.selectedTimezone &&
      !!completeProps?.token,
    [completeProps]
  );

  const query = useQuery({
    queryKey: ["events", completeProps],
    // @ts-ignore
    queryFn: async () => {
      try {
        if (dataAvailable) {
          const promises = [fetchEvents({ ...completeProps })];
          if (
            completeProps.compared &&
            completeProps.previousStartTime &&
            completeProps.previousEndTime
          ) {
            promises.push(
              fetchEvents({
                ...completeProps,
                startTime: completeProps.previousStartTime,
                endTime: completeProps.previousEndTime,
              })
            );
          }
          const [eventsData, eventsDataCompared] = await Promise.all(promises);

          return {
            ...eventsData,
            compared: eventsDataCompared,
          } as EventsResult;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    enabled:
      options?.enabled !== undefined
        ? options?.enabled && dataAvailable
        : dataAvailable,
  });
  useEffect(() => {
    if (query.data) {
      setEventsData(query.data);
    }
  }, [query.data, setEventsData]);

  return { ...query, data: query.data ? query.data : eventsData };
}

export { useEvents };
