import React from "react";
import s from "./radio.module.css";

interface Props {
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type RadioDescriptionProps = Props & NativeAttrs;

export const RadioDescription: React.FC<
  React.PropsWithChildren<RadioDescriptionProps>
> = ({ className = "", children, ...props }) => {
  return (
    <span className={`${s.description} text-gray-500  ${className}`} {...props}>
      {children}
    </span>
  );
};

export default RadioDescription;
