import { NormalSizes } from "../../lib/util-types";
import React, { useEffect, useMemo, useState } from "react";
import { RadioContext } from "./radio-context";

interface Props {
  value?: string | number;
  initialValue?: string | number;
  disabled?: boolean;
  size?: NormalSizes;
  onChange?: (value: string | number) => void;
  className?: string;
  useRow?: boolean;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type RadioGroupProps = Props & NativeAttrs;

export const getRadioSize = (size: NormalSizes): string => {
  // eslint-disable-next-line no-unused-vars
  const sizes: { [key in NormalSizes]: string } = {
    small: "12px",
    medium: "14px",
    large: "16px",
  };
  return sizes[size];
};

export const RadioGroup: React.FC<React.PropsWithChildren<RadioGroupProps>> = ({
  onChange,
  value,
  children,
  initialValue,
  disabled = false,
  size = "medium" as NormalSizes,
  className = "",
  useRow = false,
  ...props
}) => {
  const [selfVal, setSelfVal] = useState<string | number | undefined>(
    initialValue
  );
  const updateState = (nextValue: string | number) => {
    setSelfVal(nextValue);
    onChange && onChange(nextValue);
  };

  const providerValue = useMemo(() => {
    return {
      updateState,
      disabledAll: disabled,
      inGroup: true,
      value: selfVal,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, selfVal]);

  // const fontSize = useMemo(() => getRadioSize(size), [size]);
  // const groupGap = `calc(${fontSize} * 1)`;

  useEffect(() => {
    if (value === undefined) return;
    setSelfVal(value);
  }, [value]);

  return (
    <RadioContext.Provider value={providerValue}>
      <div
        className={`radio-group flex ${
          useRow ? "flex-row flex-wrap" : "flex-col space-y-2"
        } ${className}`}
        {...props}
      >
        {children}
      </div>
    </RadioContext.Provider>
  );
};

export default RadioGroup;
