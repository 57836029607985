import dayjs from "dayjs";
import {
  CooperationLink,
  CooperationLinkAPIResponse,
  CooperationLinksAPIResponse,
} from "./types";
import { DEPLOY_ENVIRONMENT } from "constants/constants";
import { anonymizeString } from "@lib/util-functions/string";

export const transformToCooperationLink = (
  link: CooperationLinkAPIResponse
) => {
  const cooperationLink: CooperationLink = {
    id: link.id,
    title:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.title ?? "")
        : link.title,
    cooperationId: link.cooperation_id,
    active: link.active,
    csid: link.customer_site_id,
    targetUrl:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.target_url ?? "")
        : link.target_url,
    fullUrl:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.full_url ?? "")
        : link.full_url,
    discountCode:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.discount_code ?? "")
        : link.discount_code,
    existingUTM:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.existing_utm ?? "")
        : link.existing_utm,
    shortenedUrl:
      DEPLOY_ENVIRONMENT === "demo"
        ? anonymizeString(link.shortened_url ?? "")
        : link.shortened_url,
    createdAt: link.created_at ? dayjs(link.created_at).toDate() : new Date(),
  };
  return cooperationLink;
};

export const transformToCooperationLinks = (
  data: CooperationLinksAPIResponse
) => {
  const links: Array<CooperationLink> = data.map((link) =>
    transformToCooperationLink(link)
  );
  return links;
};
