// atomWithHash is implemented with atomWithStorage

import { TRACIFY_APP_STATE_VAR } from "constants/constants";
import { PrimitiveAtom, WritableAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import Router, { NextRouter } from "next/router";
import qs from "qs";
import { getLocalStorage } from "./util-functions/getLocalStorage";

export const NO_STORAGE_VALUE: any = "";

type Unsubscribe = () => void;

const pathnameRegex = /[^?#]+/u;

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

export const getRouterLocation = (router: NextRouter) => {
  if (typeof window !== "undefined") {
    // For SSG, no query parameters are available on the server side,
    // since they can't be known at build time. Therefore to avoid
    // markup mismatches, we need a two-part render in this case that
    // patches the client with the updated query parameters lazily.
    // Note that for SSR `router.isReady` will be `true` immediately
    // and therefore there's no two-part render in this case.
    if (router.isReady) {
      return window.location;
    } else {
      return { search: "" } as Location;
    }
  } else {
    // On the server side we only need a subset of the available
    // properties of `Location`. The other ones are only necessary
    // for interactive features on the client.
    return {
      search: router.asPath.replace(pathnameRegex, ""),
    } as Location;
  }
};

export interface SyncStorage<Value> {
  getItem: (key: string) => Value | typeof NO_STORAGE_VALUE;
  setItem: (key: string, newValue: Value) => void;
  removeItem: (key: string) => void;
  delayInit?: boolean;
  subscribe?: (key: string, callback: (value: Value) => void) => Unsubscribe;
}

let queryToUpdate: { [key: string]: any } = {};
let queryToRemove: string[] = [];

type WithInitialValue<Value> = {
  init: Value;
};
let timeout: NodeJS.Timeout | null = null;
export function atomWithQuery<Value>(
  key: string,
  initialValue: Value,
  options?: {
    shallow?: boolean;
    serialize?: (val: Value) => string;
    deserialize?: (str: string | null) => Value | typeof NO_STORAGE_VALUE;
    delayInit?: boolean;
    replaceState?: boolean;
    saveToStorage?: boolean;
    subscribe?: (callback: () => void) => () => void;
  }
): PrimitiveAtom<Value> & WithInitialValue<Value> {
  const serialize = options?.serialize || ((str) => str);
  const deserialize =
    options?.deserialize ||
    ((str) => {
      try {
        return JSON.parse(str || "");
      } catch {
        return NO_STORAGE_VALUE;
      }
    });

  const subscribe =
    options?.subscribe ||
    ((callback) => {
      Router.events.on("routeChangeComplete", callback);
      Router.events.on("hashChangeComplete", callback);
      return () => {
        Router.events.off("routeChangeComplete", callback);
        Router.events.off("hashChangeComplete", callback);
      };
    });

  const setItemCallback = (key: string, newValue: any) => {
    // when updating many states consecutively inside a function the NextJS can't
    // finish the routing process in time. We therefore add the new query param to a batch
    // routing request and then try to change the search. If the router has fulfilled
    // the routing request, we empty our queryToUpdate obj, and if not, we'll keep it for
    // the next request. Also note: the router will only fail to fulfill the request
    // because there is another request going on at the same time
    queryToUpdate = { ...queryToUpdate, [key]: serialize(newValue) };
    const match = Router.asPath.match(pathnameRegex);
    const pathname = match ? match[0] : Router.asPath;
    const location = getRouterLocation(Router);
    const query = qs.parse(location.search.substring(1));
    const applicationState = { ...query, ...queryToUpdate };
    const search = qs.stringify({ ...applicationState });
    const hash = location.hash;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (options?.replaceState) {
        Router.replace(
          { pathname: Router.pathname, search, hash },
          { pathname, search, hash },
          { shallow: options?.shallow, scroll: false }
        )
          .then((fulfilled) => {
            queryToUpdate = {};
            return fulfilled;
          })
          .catch((reason) => console.log("reason", reason));
      } else {
        Router.push(
          { pathname: Router.pathname, search, hash },
          { pathname, search, hash },
          { shallow: options?.shallow, scroll: false }
        )
          .then((fulfilled) => {
            queryToUpdate = {};
            return fulfilled;
          })
          .catch((reason) => console.log("reason", reason));
      }
    }, 25);
  };
  const getItemCallback = (key: string) => {
    const location = getRouterLocation(Router);
    const query = qs.parse(location.search.substring(1));
    const storedValue = query[key];
    if (storedValue === undefined) {
      return initialValue;
    }
    return deserialize(storedValue as string);
  };

  const routerStorage: SyncStorage<Value> = {
    getItem: getItemCallback,
    setItem: setItemCallback,
    removeItem: (key) => {
      queryToRemove.push(key);

      const match = Router.asPath.match(pathnameRegex);
      const pathname = match ? match[0] : Router.asPath;
      const location = getRouterLocation(Router);
      const search = location.search.substring(1);
      const hash = location.hash;
      const searchParams = new URLSearchParams(search);
      queryToRemove.forEach((queryKey) => {
        searchParams.delete(queryKey);
      });
      if (options?.replaceState) {
        Router.replace(
          {
            pathname: Router.pathname,
            search: `?${searchParams.toString()}`,
            hash,
          },
          { pathname, search: `?${searchParams.toString()}`, hash },
          { shallow: options?.shallow, scroll: false }
        ).then((fulfilled) =>
          fulfilled === true ? (queryToRemove = []) : fulfilled
        );
      } else {
        Router.push(
          {
            pathname: Router.pathname,
            search: `?${searchParams.toString()}`,
            hash,
          },
          { pathname, search: `?${searchParams.toString()}`, hash },
          { shallow: options?.shallow, scroll: false }
        ).then((fulfilled) =>
          fulfilled === true ? (queryToRemove = []) : fulfilled
        );
      }
    },
    delayInit: true,
    subscribe: (key, setValue) => {
      const callback = () => {
        const routerLocation = getRouterLocation(Router);
        const query = qs.parse(routerLocation.search.substring(1));
        const storedValue = query[key];
        if (storedValue !== null && storedValue !== undefined) {
          // delete queryToUpdate[key];
          setValue(deserialize(storedValue as string));
        } else {
          // delete queryToUpdate[key];
          setValue(initialValue);
        }
      };
      return subscribe(callback);
    },
  };

  // @ts-ignore
  return atomWithStorage(key, initialValue, routerStorage);
}
