import { NormalSizes } from "../../lib/util-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Switch } from "@headlessui/react";

interface Props {
  checked?: boolean;
  initialChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  size?: NormalSizes;
  className?: string;
}

type NativeAttrs = Omit<React.LabelHTMLAttributes<any>, keyof Props>;
export type ToggleProps = Props & NativeAttrs;

export type ToggleSize = {
  width: string;
  height: string;
  toggleWidth: string;
  translateX: string;
};

const getSizes = (size: NormalSizes) => {
  const sizes: { [key in NormalSizes]: ToggleSize } = {
    small: {
      width: "w-4",
      height: "h-4",
      toggleWidth: "w-8",
      translateX: "translate-x-4",
    },
    medium: {
      width: "w-5",
      height: "h-5",
      toggleWidth: "w-10",
      translateX: "translate-x-5",
    },
    large: {
      width: "w-6",
      height: "h-6",
      toggleWidth: "w-11",
      translateX: "translate-x-5",
    },
  };
  return sizes[size];
};

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  size = "medium" as NormalSizes,
  disabled = false,
  initialChecked = false,
  className = "",
  ...props
}) => {
  const [selfChecked, setSelfChecked] = useState<boolean>(initialChecked);
  const { width, height, toggleWidth, translateX } = useMemo(
    () => getSizes(size),
    [size]
  );

  const changeHandle = useCallback(
    (checked: boolean) => {
      if (disabled) return;
      setSelfChecked(checked);
      onChange && onChange(checked);
    },
    [disabled, selfChecked, onChange]
  );

  useEffect(() => {
    if (checked === undefined) return;
    setSelfChecked(checked);
  }, [checked]);

  return (
    <Switch
      checked={selfChecked}
      disabled={disabled}
      onChange={changeHandle}
      className={`${
        selfChecked
          ? disabled
            ? "bg-gray-300"
            : "bg-primary"
          : disabled
            ? "bg-gray-200"
            : "bg-gray-300"
      } ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } relative flex items-center ${height} ${toggleWidth} rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary`}
    >
      <span
        className={`${
          selfChecked ? translateX : "translate-x-0"
        } inline-block ${height} ${width} scale-[80%] transform bg-white rounded-full transition-transform`}
      />
    </Switch>
  );
};

export default Toggle;
