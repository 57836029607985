import { NormalSizes } from "../../lib/util-types";
import React, { ReactNode, useMemo } from "react";
import BreadcrumbsItem from "./breadcrumbs-item";
import BreadcrumbsSeparator from "./breadcrumbs-separator";
import s from "./breadcrumbs.module.css";

interface Props {
  size: NormalSizes;
  separator?: string | ReactNode;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type BreadcrumbsProps = Props & NativeAttrs;

const getSize = (size: NormalSizes) => {
  const sizes: { [key in NormalSizes]: string } = {
    small: "text-xs",
    medium: "text-sm",
    large: "text-base",
  };
  return sizes[size];
};

const Breadcrumbs: React.FC<React.PropsWithChildren<BreadcrumbsProps>> = ({
  size = "medium" as NormalSizes,
  separator = "/",
  className = "",
  children,
}) => {
  const fontSize = useMemo(() => getSize(size), [size]);

  const childrenArray = React.Children.toArray(children);
  const withSeparatorChildren = childrenArray.map((item, index) => {
    if (!React.isValidElement(item)) return item;
    const last = childrenArray[index - 1];
    const lastIsSeparator =
      React.isValidElement(last) && last.type === BreadcrumbsSeparator;
    const currentIsSeparator = item.type === BreadcrumbsSeparator;
    if (!lastIsSeparator && !currentIsSeparator && index > 0) {
      return (
        <React.Fragment key={index}>
          <BreadcrumbsSeparator>{separator}</BreadcrumbsSeparator>
          {item}
        </React.Fragment>
      );
    }
    return item;
  });

  return (
    <nav className={`${s.nav} ${fontSize} ${className}`}>
      {withSeparatorChildren}
    </nav>
  );
};

export default Breadcrumbs;
