import React from "react";
import { BookOpenIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { newVsReturningAtom } from "atoms";
import { NVR_OPTIONS } from "constants/constants";
import { useAtom } from "jotai";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from "@components/ui/select";
import { cn } from "@/lib/utils";

const NvrDropdown: React.FC = () => {
  const [newVsReturning, setNewVsReturning] = useAtom(newVsReturningAtom);

  return (
    <Select
      value={newVsReturning}
      onValueChange={(val: typeof newVsReturning) => setNewVsReturning(val)}
    >
      <SelectTrigger
        className="bg-gray-200 dark:bg-gray-700"
        id="nvr-select-trigger"
      >
        <span className="truncate font-semibold mr-2 inline-flex items-center">
          <UserGroupIcon className="h-4 w-4 inline-block mr-2" />{" "}
          {(
            NVR_OPTIONS.find((el) => el.value === newVsReturning)?.label ??
            "All Users"
          ).replace("Users", "")}
        </span>
      </SelectTrigger>
      <SelectContent
        className="relative !max-h-140 !min-w-[240px]"
        viewportClassName="!p-0"
      >
        <SelectGroup>
          <SelectLabel className="px-1 pt-2 !pb-0 ms-2 h-[30px]">
            Customer Segment
          </SelectLabel>
          {NVR_OPTIONS.map((option, index) => (
            <SelectItem
              key={option.value}
              value={option.value}
              className={cn(
                "!p-0 w-[270px] h-[68px] flex flex-wrap !bg-transparent mb-1"
              )}
              id={"nvr-item-" + option.value}
            >
              <div
                className={cn(
                  "px-2.5 py-2 m-1 rounded-md w-inherit",
                  "focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 hover:bg-accent/50",
                  newVsReturning === option.value &&
                    "!bg-accent text-accent-foreground"
                )}
              >
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="grow shrink basis-0 text-sm font-medium leading-tight">
                    {option.title}
                  </div>
                  <div className="grow shrink basis-0 text-xs font-normal leading-none">
                    {option.description}
                  </div>
                </div>
              </div>
              <div
                className={cn(
                  "h-[1px] mx-2 mt-1 w-[calc(100%-12px)]",
                  index === 0 && "bg-[#A7A6AD]"
                )}
              />
            </SelectItem>
          ))}
        </SelectGroup>
        <div className="w-[270px] bg-accent/50 rounded-bl-md border-t border-input justify-start items-center gap-2 flex relative h-[42px] mt-3">
          <div className="px-2.5 flex gap-2">
            <BookOpenIcon className="h-4 w-4" />
            <div className="grow shrink basis-0 text-center text-white text-xs font-normal underline">
              <a
                href="https://knowledge.tracify.ai/en/articles/32101-what-is-user-segmentation-new-vs-returning-users-customers"
                target="_blank"
              >
                Learn more about user segmentation
              </a>
            </div>
          </div>
        </div>
      </SelectContent>
    </Select>
  );
};

export default NvrDropdown;
