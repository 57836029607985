import React, { useCallback } from "react";
import { format } from "date-fns";
import {
  PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
  START_DATE_RANGE_SELECT_OPTIONS_FULL,
} from "../../constants/constants";
import { Input } from "@components/ui/input";

interface DateRangeDisplayerInterface {
  startDate?: string;
  endDate?: string;
  preset?: string;
  isEditable?: boolean;
  isActive?: boolean;
  onOptionSelect?: CallableFunction;
}

export function DateRangeDisplayer({
  preset = "this_month",
  startDate = format(new Date(), "dd-mm-yyyy"),
  endDate = format(new Date(), "dd-mm-yyyy"),
  isEditable = true,
  isActive = false,
  onOptionSelect = () => {},
}: DateRangeDisplayerInterface) {
  const getLabelfromPreset = useCallback((presetValue: string) => {
    const dateRange = [
      ...START_DATE_RANGE_SELECT_OPTIONS_FULL,
      ...PREVIOUS_DATE_RANGE_SELECT_OPTIONS,
    ].filter((option) => option.value === presetValue);
    if (dateRange && dateRange.length !== 0) {
      return dateRange[0]?.label;
    }
    return "Today";
  }, []);

  return (
    <div className="grid grid-cols-2">
      <div className="col-span-2 flex items-center">
        <div className="flex-1 flex items-center border border-transparent px-4 h-9 bg-gray-200 dark:bg-gray-700 rounded-md text-sm text-foreground-soft">
          {startDate}
        </div>

        <div className="w-4 h-px bg-gray-200 dark:bg-gray-600 mx-2" />
        <div className="flex-1 flex items-center border border-transparent px-4 h-9 bg-gray-200 dark:bg-gray-700 rounded-md text-sm text-foreground-soft">
          {endDate}
        </div>
      </div>
    </div>
  );
}
