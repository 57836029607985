interface Props {
  count?: number;
  className?: string;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type AvatarGroupProps = Props & NativeAttrs;

export function AvatarGroup({
  count,
  className = "",
  children,
}: AvatarGroupProps) {
  return (
    <div className={`avatar-group flex items-center h-auto w-max ${className}`}>
      {children}
      {count && (
        <span className="text-sm inline-flex items-center text-gray-700 pl-1">
          +{count}
        </span>
      )}
    </div>
  );
}

export default AvatarGroup;
