import React, { useMemo } from "react";
import { getStyles } from "./styles";
import CardFooter from "./card-footer";
import CardContent from "./card-content";
import { CardTypes } from "../../lib/util-types";
import { hasChild, pickChild } from "../../lib/util-functions";
import s from "./card.module.css";

interface Props {
  hoverable?: boolean;
  shadow?: boolean;
  className?: string;
  width?: string;
  type?: CardTypes;
  tight?: boolean;
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>;
export type CardProps = Props & NativeAttrs;

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  type = "default" as CardTypes,
  hoverable = false,
  shadow = false,
  width = "100%",
  className = "",
  tight = false,
  ...props
}) => {
  const colors = useMemo(() => getStyles(type, shadow), [type, shadow]);

  const [withoutFooterChildren, footerChildren] = pickChild(
    children,
    CardFooter
  );

  const hasContent = hasChild(withoutFooterChildren, CardContent);

  return (
    <div
      className={`${
        s.card
      } m-0 p-0 duration-200  transition-all ease-out box-border rounded-md ${colors} ${
        shadow ? "shadow-xs" : ""
      } ${
        hoverable ? (shadow ? "hover:shadow-sm" : "hover:shadow-xs") : ""
      } ${className}`}
      {...props}
    >
      {hasContent ? (
        withoutFooterChildren
      ) : (
        <CardContent tight={tight}>{withoutFooterChildren}</CardContent>
      )}
      {footerChildren}
    </div>
  );
};

export default Card;
