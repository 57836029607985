import React from "react";
import { BookOpenIcon } from "@heroicons/react/24/outline";

type Props = {
  name: string;
  url: string | undefined;
};

const LearnMoreInfo = ({ name, url }: Props) => {
  if (!url) {
    return null;
  }

  return (
    <div className="flex gap-1 w-full justify-center items-center mt-2 mb-4">
      <BookOpenIcon className="h-4 w-4" />
      <a href={url} className="underline ml-1" target="_blank">
        Learn more about {name}
      </a>
    </div>
  );
};

export default LearnMoreInfo;
