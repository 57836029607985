import {
  AnalyticsRequest,
  AnalyticsResultRequest,
  BaseAnalyticsResult,
  TimeSettingsType,
} from "@api/types/backendTypes";
import axios, { AxiosError } from "axios";
import { transformToBaseAnalyticsResult } from "./analytics";
import { Influencer } from "@lib/api-hooks/useInfluencers";
import { Cooperation } from "@lib/api-hooks/useCooperations";
import dayjs from "dayjs";
import { Remote, wrap } from "comlink";

export type AnalyticsFilterType = {
  influencerId?: string[];
  onlyInfluencerData?: boolean;
};

export type RequestAnalyticsProps = {
  requestBody: AnalyticsRequest;
  backendUrl: string;
  token: string;
  signal?: AbortSignal;
  cooperationData?: Cooperation[];
  influencerData?: Influencer[];
  filter?: AnalyticsFilterType;
  cooperationsAsAdsets?: boolean;
};

let api: Remote<any> | null = null;
let worker: Worker | null = null;
if (typeof window !== undefined) {
  try {
    worker = window?.Worker
      ? new Worker(new URL("./analyticsWorker.js", import.meta?.url))
      : null;
    api = worker ? wrap(worker) : null;
  } catch (error) {}
}

/**
 * Submits an analytics job for processing and retrieves jobid.
 *
 * @param {RequestAnalyticsProps} props
 * @param {AnalyticsRequest} props.requestBody Analytics job to be processed.
 * @param {Influencer[]} props.influencerData Influencer data to pass to the transform result function
 * @param {Cooperation[]} props.cooperationData Cooperation data to pass to the transform result function
 * @param {AnalyticsFilterType} props.filter filters to apply when transforming the analytics result
 * @param {string} props.backendUrl Backend URL to use for submitting job.
 * @param {string} props.token (JWT-) Session token
 * @return {Promise<string | BaseAnalyticsResult>} Promise which resolves to job-token, the cached BaseAnalyticsResult or rejects with error message
 */
export function requestAnalytics({
  requestBody,
  backendUrl,
  token,
  signal,
  cooperationData,
  influencerData,
  filter,
  cooperationsAsAdsets,
}: RequestAnalyticsProps): Promise<string | BaseAnalyticsResult> {
  const payload = structuredClone(requestBody);
  // @ts-ignore
  delete payload.timezone; // we don't need this in the request
  return new Promise((resolve, reject) => {
    axios
      .post(`${backendUrl}/metrics/request`, payload, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
        signal,
      })
      .then(async function (response) {
        // handle success
        if (response.status !== 200) {
          reject(
            Error(`Could not request analytics job: ${response.data?.error}`)
          );
        } else {
          // check if result is provided
          if (response.data.result?.attributions) {
            const analyticsProps = {
              data: response.data.result,
              timeSettings: {
                timezone: requestBody.timezone,
                utcOffset: requestBody.utc_offset,
                startTime: dayjs(requestBody.attributions[0].attribution_start)
                  .tz("UTC", true)
                  .toISOString(),
                endTime: dayjs(requestBody.attributions[0].attribution_end)
                  .tz("UTC", true)
                  .toISOString(),
              },
              options: {
                influencerData,
                cooperationData,
                filter,
                views: requestBody.views,
                cooperationsAsAdsets,
              },
            };
            if (api && process.env.NODE_ENV !== "development") {
              const workerResult = await api.transformAnalytics(analyticsProps);
              resolve(workerResult);
            } else {
              const result = transformToBaseAnalyticsResult(analyticsProps);
              resolve(result);
            }
          } else {
            const jobid = response.data?.result?.jobid;
            // console.log(`Jobid unpacked: ${jobid}`);
            resolve(jobid);
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage =
            (axiosError.response?.data as Record<string, any> | undefined)
              ?.error ?? axiosError.message;
          reject(new Error(`Analytics result request failed: ${errorMessage}`));
        } else {
          reject(Error(`Analytics result request failed for unknown reason!`));
        }
      });
  });
}

export type RequestAnalyticsResultProps = {
  requestBody: AnalyticsResultRequest;
  backendUrl: string;
  token: string;
  timeSettings: TimeSettingsType;
  signal?: AbortSignal;
  cooperationData?: Cooperation[];
  influencerData?: Influencer[];
  filter?: AnalyticsFilterType;
  views: AnalyticsRequest["views"];
  cooperationsAsAdsets?: boolean;
};

/**
 * Requests an analytics result for given job information.
 *
 * @param {RequestAnalyticsResultProps} props
 * @param {AnalyticsResultRequest} props.requestBody Request body to use.
 * @param {string} props.backendUrl Backend URL to use for submitting job.
 * @param {string} props.token (JWT-) Session token
 * @param {Influencer[]} props.influencerData Influencer data to pass to the transform result function
 * @param {Cooperation[]} props.cooperationData Cooperation data to pass to the transform result function
 * @param {AnalyticsFilterType} props.filter filters to apply when transforming the analytics result
 * @param {TimeSettingsType} props.timeSettings start date, end date and utc offset values
 * @return {Promise} Promise which resolves to BaseAnalyticsResult result object or rejects with error message
 */
export function requestAnalyticsResult({
  requestBody,
  backendUrl,
  token,
  timeSettings,
  signal,
  influencerData,
  cooperationData,
  filter,
  views,
  cooperationsAsAdsets,
}: RequestAnalyticsResultProps): Promise<BaseAnalyticsResult> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${backendUrl}/metrics/results`, requestBody, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
        signal,
      })
      .then(async function (response) {
        // handle success
        // console.log(response);
        if (response.status !== 200) {
          reject(
            new Error(
              `Could not retrieve analytics job results: ${response.data?.error}`
            )
          );
        } else {
          // check if result is provided
          if (response.data.result?.attributions) {
            const analyticsProps = {
              data: response.data.result,
              timeSettings,
              options: {
                influencerData,
                cooperationData,
                filter,
                views,
                cooperationsAsAdsets,
              },
            };
            if (api && process.env.NODE_ENV !== "development") {
              const workerResult = await api.transformAnalytics(analyticsProps);
              resolve(workerResult);
            } else {
              const result = transformToBaseAnalyticsResult(analyticsProps);
              resolve(result);
            }
          } else {
            const result = {
              progress: response.data.result?.progress,
              finished: false,
            } as BaseAnalyticsResult;
            resolve(result);
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          const errorMessage =
            (axiosError.response?.data as Record<string, any> | undefined)
              ?.error ?? axiosError.message;
          reject(new Error(`Analytics result request failed: ${errorMessage}`));
        } else {
          reject(
            new Error(`Analytics result request failed for unknown reason!`)
          );
        }
      });
  });
}
