import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatTracifyNumber } from "./string";
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Transforms dayjs DateTime range and request mode and turn this info into tracify request data.
 *
 * This function will also make that the dates are in UTC format, which is required by Tracify API.
 *
 * @param {Dayjs} startDate Start date as Dayjs
 * @param {Dayjs} endDate  End date as Dayjs
 * @param {string} reportTime Type of interval filtering (event or ad)
 * @param {int} attributionWindow Attribution window
 * @return {[Dayjs,Dayjs,Dayjs,Dayjs]} [attributeStart, attributeEnd, collectStart, collectEnd]
 */
export function toTracifyDateRange(
  startDate: Dayjs,
  endDate: Dayjs,
  reportTime: string,
  attributionWindow: number
) {
  const attributeStart = startDate;
  const attributeEnd = endDate;

  let collectStart = startDate;
  let collectEnd = endDate;

  if (reportTime === "event") {
    collectStart = startDate.subtract(attributionWindow, "days");
  } else {
    collectEnd = endDate.add(attributionWindow, "days");
  }

  return [
    attributeStart.utc(),
    attributeEnd.utc(),
    collectStart.utc(),
    collectEnd.utc(),
  ];
}

export function hoursToText(hours: number) {
  if (hours < 1) {
    return `${formatTracifyNumber(hours * 60, 0)}m`;
  } else if (hours >= 1 && hours < 5) {
    return (Math.round(hours * 10) / 10) % 1 === 0
      ? `${formatTracifyNumber(hours, 0)}h`
      : `${formatTracifyNumber(hours, 1)}h`;
  } else if (hours >= 24 && hours < 168) {
    const sub24hours = Math.round(hours) % 24;
    const days = (Math.round(hours) - sub24hours) / 24;
    return `${formatTracifyNumber(days, 0)}d${
      sub24hours > 0 ? " " + formatTracifyNumber(sub24hours, 0) + "h" : ""
    }`;
  } else if (hours >= 168) {
    return `${formatTracifyNumber(hours / 24, 0)}d`;
  } else {
    return `${formatTracifyNumber(hours, 0)}h`;
  }
}
