import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import { Button } from "@components/ui/button";
import { useAuth } from "@lib/api-hooks";
import { Modal } from "@ui";
import { currencyModalStatusAtom } from "atoms";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import React from "react";

function CurrencyModalProvider() {
  const { data: auth } = useAuth();

  const router = useRouter();

  const [currencyModalStatus, setCurrencyModalStatus] = useAtom(
    currencyModalStatusAtom
  );

  const handleCloseModal = () => {
    setCurrencyModalStatus(false);
  };

  if (!auth?.payload?.ema) {
    return <></>;
  }

  return (
    <>
      <Modal
        open={currencyModalStatus}
        onClose={handleCloseModal}
        title="You are requesting a report in multiple currencies"
        width="small"
      >
        We have detected that you are combining data from stores that use
        different currencies. When we detect multiple currencies we will
        automatically convert to Euros. You can change this for your
        organizations in your settings.
        <div className="flex w-full justify-end mt-4">
          {router.pathname === "/settings" ? (
            <Button onClick={handleCloseModal}>Close</Button>
          ) : (
            <LinkWithPersistentQuery href="/settings" passHref>
              <a>
                <Button onClick={handleCloseModal}>Go to settings</Button>
              </a>
            </LinkWithPersistentQuery>
          )}
        </div>
      </Modal>
    </>
  );
}

export default CurrencyModalProvider;
