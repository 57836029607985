import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { CreateInfluencerSchema } from "./useCreateInfluencer";

const UpdateInfluencerSchema = CreateInfluencerSchema.extend({
  influencerId: z.string(),
});
export type UpdateInfluencerInput = z.infer<typeof UpdateInfluencerSchema>;

const onUpdateInfluencer = async (
  input: UpdateInfluencerInput,
  token: string
) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    UpdateInfluencerSchema.parse(input);
    // console.log(`Sending request to tracify: ${loginData.backendUrl}`);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_CHIEF_V4_BASE_URL}/influencers/${input.influencerId}/update`,
      {
        name: input.name,
        contact_email: input.contact_email,
        blog_account_name: input.blog_account_name,
        facebook_account_name: input.facebook_account_name,
        instagram_account_name: input.instagram_account_name,
        tiktok_account_name: input.tiktok_account_name,
        twitch_account_name: input.twitch_account_name,
        youtube_account_name: input.youtube_account_name,
        pinterest_account_name: input.pinterest_account_name,
        linkedin_account_name: input.linkedin_account_name,
        snapchat_account_name: input.snapchat_account_name,
        podcast_account_name: input.podcast_account_name,
        twx_account_name: input.twx_account_name,
        threads_account_name: input.threads_account_name,
        other_account_name: input.other_account_name,
        organisation_id: input.organisation_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const apiError = axiosError.response?.data as ChiefAPIError;
        const firstErr = apiError.detail[0];
        throw new Error(firstErr?.msg ?? axiosError.message);
      }
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useUpdateInfluencer = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: UpdateInfluencerInput) =>
      onUpdateInfluencer(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      queryClient.invalidateQueries({ queryKey: ["influencer"] });
      queryClient.invalidateQueries({ queryKey: ["influencers"] });
    },
  });
};
