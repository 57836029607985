import { useInitializeAtoms } from "@lib/hooks";
import { endTimeAtom, startTimeAtom, timeRangeKeyAtom } from "atoms";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  getStartDateRange,
  getTimerangeFromDates,
} from "services/DateRangeServices";

const StateInitializer = ({ children }: { children: React.ReactNode }) => {
  useInitializeAtoms();

  // whenever the dashboard stays open between different days, we want to adjust the timerange key
  // to update in regards to the current day - e.g. if the user has "Today" selected and keeps the dashboard
  // open for 1 day, we want to automatically update this key to say "Yesterday"
  // to avoid having to run a function every minute, we use the window focus event
  const [timerange, setTimerange] = useAtom(timeRangeKeyAtom);
  const [startDate, setStartTime] = useAtom(startTimeAtom);
  const [endDate, setEndTime] = useAtom(endTimeAtom);
  const [currentDate, setCurrentDate] = useState(dayjs().format("YYYY-MM-DD"));
  useEffect(() => {
    const focusFunction = () => {
      const newDate = dayjs().format("YYYY-MM-DD");
      if (newDate !== currentDate) {
        const newRange = getTimerangeFromDates({ startDate, endDate });
        setTimerange(newRange);
        setCurrentDate(newDate);
      }
    };
    // Set up event handler to produce text for the window focus event
    window.addEventListener("focus", focusFunction);
    return () => {
      window.removeEventListener("focus", focusFunction);
    };
  }, [
    currentDate,
    endDate,
    setEndTime,
    setStartTime,
    setTimerange,
    startDate,
    timerange,
  ]);

  return <>{children}</>;
};

export default StateInitializer;
