/**
 * Check if array is empty or not.
 *
 * @param {Array} array
 * @return {boolean} whether array is empty or not
 */
export const isEmpty = (array: any) => {
  return (
    !Array.isArray(array) ||
    !array.length ||
    array.every((x) => x === null || x === "" || x === "undefined")
  );
};

export function removeDuplicates(arr: any[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function arraysHaveSameValues(arr1: any[], arr2: any[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
