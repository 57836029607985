import {
  BaseAnalytics,
  DailyBaseAnalytics,
  EventSumInfo,
  EventSumResult,
  InfluencerCustomerJourneyBaseAnalytics,
} from "@api/types/backendTypes";
import dayjs from "dayjs";

const googleCampaign = (randomizer: number) => ({
  refId: "16890672640",
  fullyQualifiedId: "16890672640",
  name: "Max - DACH - 2024",
  status: "ENABLED",
  addtocart: 516 * randomizer,
  pageview: 3146 * randomizer,
  productview: 2897 * randomizer,
  purchaseCount: 222 * randomizer * Math.max(Math.random(), 0.85),
  purchaseAmount: 14785.519999999999 * randomizer,
  purchaseCartItems: 230 * randomizer,
  checkout: 0 * randomizer,
  cac: 0 * randomizer,
  cpo: 30.278063063063062 * randomizer,
  frequency: 0 * randomizer,
  roas: 2.199659908981765 * randomizer,
  ctr: 0.009278168787001965 * randomizer,
  thumbstopRatio: 0 * randomizer,
  cpc: 1.5573980537534753 * randomizer,
  cr: 0.07056579783852511 * randomizer,
  aov: 66.60144144144144 * randomizer,
  clicks: 4316 * randomizer,
  cpm: 14.449802011273103 * randomizer,
  impressions: 465178 * randomizer,
  landingPageView: 0 * randomizer,
  linkClick: 0 * randomizer,
  reach: 0 * randomizer,
  spend: 3721.73 * randomizer * Math.max(Math.random(), 0.75),
  fixedCommissionAmount: 0 * randomizer,
  variableCommissionAmount: 0 * randomizer,
  type: "campaign",
  provider: "google",
  subRows: [],
  advertisingChannelType: "PERFORMANCE_MAX",
});

const facebookCampaign = (randomizer: number) => ({
  refId: "23854375638450042",
  fullyQualifiedId: "23854375638450042",
  name: "TOFU_DACH_2024",
  status: "ACTIVE",
  addtocart: 270 * randomizer,
  pageview: 1399 * randomizer,
  productview: 1401 * randomizer,
  purchaseCount: 51 * randomizer * Math.max(Math.random(), 0.85),
  purchaseAmount: 4158.67 * randomizer,
  purchaseCartItems: 52 * randomizer,
  checkout: 0 * randomizer,
  cac: 0 * randomizer,
  cpo: 57.19823529411764 * randomizer,
  frequency: 1.075287951339459 * randomizer,
  roas: 1.4256130211065063 * randomizer,
  ctr: 0.006524962926347009 * randomizer,
  thumbstopRatio: 0 * randomizer,
  cpc: 1.921679841897233 * randomizer,
  cr: 0.036454610436025735 * randomizer,
  aov: 81.54254901960785 * randomizer,
  clicks: 2006 * randomizer,
  cpm: 12.538889724687827 * randomizer,
  impressions: 232645 * randomizer,
  landingPageView: 1663 * randomizer,
  linkClick: 1518 * randomizer,
  reach: 216356 * randomizer,
  spend: 2917.1099999999997 * randomizer * Math.max(Math.random(), 0.65),
  fixedCommissionAmount: 0 * randomizer,
  variableCommissionAmount: 0 * randomizer,
  type: "campaign",
  provider: "facebook",
  subRows: [],
});

const tiktokCampaign = (randomizer: number) => ({
  refId: "23854375638450042",
  fullyQualifiedId: "23854375638450042",
  name: "TOFU_DACH_2024",
  status: "ACTIVE",
  addtocart: 120 * randomizer,
  pageview: 899 * randomizer,
  productview: 601 * randomizer,
  purchaseCount: 31 * randomizer * Math.max(Math.random(), 0.85),
  purchaseAmount: 2158.67 * randomizer,
  purchaseCartItems: 42 * randomizer,
  checkout: 0 * randomizer,
  cac: 0 * randomizer,
  cpo: 57.19823529411764 * randomizer,
  frequency: 1.075287951339459 * randomizer,
  roas: 1.4256130211065063 * randomizer,
  ctr: 0.006524962926347009 * randomizer,
  thumbstopRatio: 0 * randomizer,
  cpc: 1.921679841897233 * randomizer,
  cr: 0.036454610436025735 * randomizer,
  aov: 81.54254901960785 * randomizer,
  clicks: 2006 * randomizer,
  cpm: 12.538889724687827 * randomizer,
  impressions: 132645 * randomizer,
  landingPageView: 1663 * randomizer,
  linkClick: 1018 * randomizer,
  reach: 116356 * randomizer,
  spend: 917.1099999999997 * randomizer * Math.max(Math.random(), 0.75),
  fixedCommissionAmount: 0 * randomizer,
  variableCommissionAmount: 0 * randomizer,
  type: "campaign",
  provider: "tiktok",
  subRows: [],
});
const pinterestCampaign = (randomizer: number) => ({
  refId: "23854375638450042",
  fullyQualifiedId: "23854375638450042",
  name: "TOFU_DACH_2024",
  status: "ACTIVE",
  addtocart: 120 * randomizer,
  pageview: 899 * randomizer,
  productview: 601 * randomizer,
  purchaseCount: 31 * randomizer * Math.max(Math.random(), 0.85),
  purchaseAmount: 2158.67 * randomizer,
  purchaseCartItems: 42 * randomizer,
  checkout: 0 * randomizer,
  cac: 0 * randomizer,
  cpo: 57.19823529411764 * randomizer,
  frequency: 1.075287951339459 * randomizer,
  roas: 1.4256130211065063 * randomizer,
  ctr: 0.006524962926347009 * randomizer,
  thumbstopRatio: 0 * randomizer,
  cpc: 1.921679841897233 * randomizer,
  cr: 0.036454610436025735 * randomizer,
  aov: 81.54254901960785 * randomizer,
  clicks: 2006 * randomizer,
  cpm: 12.538889724687827 * randomizer,
  impressions: 132645 * randomizer,
  landingPageView: 1663 * randomizer,
  linkClick: 1018 * randomizer,
  reach: 116356 * randomizer,
  spend: 917.1099999999997 * randomizer * Math.max(Math.random(), 0.75),
  fixedCommissionAmount: 0 * randomizer,
  variableCommissionAmount: 0 * randomizer,
  type: "campaign",
  provider: "pinterest",
  subRows: [],
});

const influencerCooperation = (randomizer: number) => ({
  refId: "influencer::12233572-5c1b-44ce-9253-6f5c256fecca",
  fullyQualifiedId: "influencer::12233572-5c1b-44ce-9253-6f5c256fecca",
  name: "test_influencer_" + randomizer.toFixed(2),
  addtocart: 120 * randomizer,
  pageview: 899 * randomizer,
  productview: 601 * randomizer,
  purchaseCount: 31 * randomizer * Math.max(Math.random(), 0.85),
  purchaseAmount: 2158.67 * randomizer,
  purchaseCartItems: 42 * randomizer,
  checkout: 0 * randomizer,
  cac: 0 * randomizer,
  cpo: 57.19823529411764 * randomizer,
  frequency: 1.075287951339459 * randomizer,
  roas: 1.4256130211065063 * randomizer,
  ctr: 0.006524962926347009 * randomizer,
  thumbstopRatio: 0 * randomizer,
  cpc: 1.921679841897233 * randomizer,
  cr: 0.036454610436025735 * randomizer,
  aov: 81.54254901960785 * randomizer,
  clicks: 2006 * randomizer,
  cpm: 12.538889724687827 * randomizer,
  impressions: 132645 * randomizer,
  landingPageView: 1663 * randomizer,
  linkClick: 1018 * randomizer,
  reach: 116356 * randomizer,
  spend: 917.1099999999997 * randomizer * Math.max(Math.random(), 0.75),
  fixedCommissionAmount: 0 * randomizer,
  variableCommissionAmount: 0 * randomizer,
  type: "cooperationLink",
  provider: "influencer_module",
  placementType: "story",
  source:
    randomizer < 1.2 ? "instagram" : randomizer < 1.6 ? "tiktok" : "youtube",
  subRows: [
    {
      refId: "bdff2b14-4bde-4f36-8403-8445ce908ede::melanie55",
      fullyQualifiedId: "bdff2b14-4bde-4f36-8403-8445ce908ede::melanie55",
      name: "melanie55",
      addtocart: 120 * randomizer,
      pageview: 899 * randomizer,
      productview: 601 * randomizer,
      purchaseCount: 31 * randomizer * Math.max(Math.random(), 0.85),
      purchaseAmount: 2158.67 * randomizer,
      purchaseCartItems: 42 * randomizer,
      checkout: 0 * randomizer,
      cac: 0 * randomizer,
      cpo: 57.19823529411764 * randomizer,
      frequency: 1.075287951339459 * randomizer,
      roas: 1.4256130211065063 * randomizer,
      ctr: 0.006524962926347009 * randomizer,
      thumbstopRatio: 0 * randomizer,
      cpc: 1.921679841897233 * randomizer,
      cr: 0.036454610436025735 * randomizer,
      aov: 81.54254901960785 * randomizer,
      clicks: 2006 * randomizer,
      cpm: 12.538889724687827 * randomizer,
      impressions: 132645 * randomizer,
      landingPageView: 1663 * randomizer,
      linkClick: 1018 * randomizer,
      reach: 116356 * randomizer,
      spend: 917.1099999999997 * randomizer * Math.max(Math.random(), 0.75),
      fixedCommissionAmount: 0 * randomizer,
      variableCommissionAmount: 0 * randomizer,
      type: "discountCode",
      provider: "influencer_module",
      subRows: [],
      id: "bdff2b14-4bde-4f36-8403-8445ce908ede",
      title: "d77wr_melanie_and_darlings_instagram",
      cooperationId: "ce723213-471c-4c1f-b3d6-94ae871d9440",
      active: true,
      csid: null,
      targetUrl: "https://www.ava-may.de/discount/melanie55",
      fullUrl:
        "https://www.ava-may.de/discount/melanie55?redirect=/collections/alle-produkte%253Ftrc_inf_un=melanie_and_darlings&utm_source=influencers&utm_medium=instagram%2Bstories&utm_campaign=melanie_and_darlings&utm_campaign_id=d77wr&trc_inf_un=melanie_and_darlings&trc_inf_cn=ig&trc_inf_clid=bdff2b14-4bde-4f36-8403-8445ce908ede&trc_inf_dc=melanie55",
      discountCode: "melanie55",
      existingUTM:
        "redirect=/collections/alle-produkte%3Ftrc_inf_un=melanie_and_darlings&utm_source=influencers&utm_medium=instagram+stories&utm_campaign=melanie_and_darlings&utm_campaign_id=d77wr",
      shortenedUrl: "https://trfy.eu/vns8t",
      createdAt: "2024-04-02T16:30:19.148Z",
    },
  ],
  discountMatchingRate: 0,
  videoAvgTimeWatchedActions: 0,
  id: "12233572-5c1b-44ce-9253-6f5c256fecca",
  contactEmail: "",
  organisationId: "d9765504-5482-4d03-8942-1ed71198f3bb",
  cooperations: [],
  channels: {
    tiktok: "",
    blog: "",
    twitch: "",
    youtube: "",
    facebook: "",
    instagram: "melanie_and_darlings",
    pinterest: "",
    linkedin: "",
    snapchat: "",
    podcast: "",
    twx: "",
    threads: "",
    other: "",
  },
  links: [],
  discountCodes: [],
});

const mapData = () => {
  return new Map([
    [
      "google",
      [
        googleCampaign(Math.random() * 2),
        googleCampaign(Math.random() * 2),
        googleCampaign(Math.random() * 2),
        googleCampaign(Math.random() * 2),
        googleCampaign(Math.random() * 2),
      ],
    ],
    [
      "facebook",
      [
        facebookCampaign(Math.random() * 2),
        facebookCampaign(Math.random() * 2),
        facebookCampaign(Math.random() * 2),
        facebookCampaign(Math.random() * 2),
        facebookCampaign(Math.random() * 2),
        facebookCampaign(Math.random() * 2),
      ],
    ],
    [
      "tiktok",
      [
        tiktokCampaign(Math.random() * 2),
        tiktokCampaign(Math.random() * 2),
        tiktokCampaign(Math.random() * 2),
        tiktokCampaign(Math.random() * 2),
      ],
    ],
    [
      "pinterest",
      [
        pinterestCampaign(Math.random() * 2),
        pinterestCampaign(Math.random() * 2),
        pinterestCampaign(Math.random() * 2),
        pinterestCampaign(Math.random() * 2),
      ],
    ],
    [
      "influencer_module",
      [
        influencerCooperation(Math.random() * 2),
        influencerCooperation(Math.random() * 2),
        influencerCooperation(Math.random() * 2),
        influencerCooperation(Math.random() * 2),
      ],
    ],
  ]);
};

export const empty_aggregated_data = () =>
  ({
    totals: new Map(),
    ads: mapData(),
    adsets: mapData(),
    campaigns: mapData(),
  }) as BaseAnalytics;

export const empty_daily_data = () => {
  const data: DailyBaseAnalytics = {};
  for (let i = 0; i < 7; i++) {
    data[
      dayjs()
        .subtract(7 - i, "days")
        .format("YYYY-MM-DD")
    ] = empty_aggregated_data();
  }
  return data;
};

export const empty_events_data = () => {
  const randomizer = Math.max(0.8, Math.random() * 3);

  const dailyData: Record<string, EventSumInfo> = {};

  for (let i = 0; i < 7; i++) {
    const dailyRandomizer = Math.max(0.5, Math.random() * 2);
    dailyData[
      dayjs()
        .subtract(7 - i, "days")
        .format("YYYY-MM-DD")
    ] = {
      total_amount: 72012 * dailyRandomizer,
      total_count: 1102 * dailyRandomizer,
      total_nitems: 4032 * dailyRandomizer,
    };
  }
  const result: EventSumResult = {
    totals: {
      total_amount: 72012 * randomizer,
      total_count: 1102 * randomizer,
      total_nitems: 4032 * randomizer,
    },
    daily: dailyData,
  };
  return result;
};

export const empty_cj_data = () => {
  // @ts-ignore
  return {
    touchpoints: {
      avg: 1.7228780438921737,
      sum: 39603,
    },
    ad_ids: {
      "": {
        "": {
          "direct::::::direct": 7207,
          "facebook::120200696506980043::120200696506990043::120200697303810043": 1,
          "facebook::120200911150660043::120204727654820043::120204727654830043": 3,
          "facebook::120200911150660043::120206599631620043::120206599691200043": 2,
          "facebook::120200911150660043::120206600244830043::120206600362060043": 2,
          "facebook::120200911150660043::120206828938660043::120206829262870043": 2,
          "facebook::120200911150660043::120206988903780043::120206989070830043": 7,
          "facebook::120200911150660043::120206988903780043::120206989088860043": 1,
          "facebook::120200911150660043::120207004642150043::120207004642140043": 3,
          "facebook::120200911150660043::120207004642150043::120207004663470043": 2,
          "facebook::120200911150660043::120207022855920043::120207022873430043": 2,
          "facebook::23854361968470042::23854361968450042::120205112788900043": 2,
          "facebook::23854361968470042::23854361968450042::120205448312270043": 4,
          "facebook::23854361968470042::23854361968450042::23854361968460042": 1,
          "facebook::23854361968470042::23854361968450042::23854541868120042": 1,
          "google::15205826778::129222869413::559930039164": 1209,
          "google::16890672640::146068874638::644833429823": 9,
          "google::18788099511::143050420996::632363382874": 372,
          "google::18788099511::143050420996::655644124992": 237,
          "google::18788099511::143050420996::655681587100": 285,
          "google::18788099511::151013388449::655721441675": 291,
          "google::18788099511::157752182879::689850450698": 8,
        },
      },
    },
    daysToConvert: {
      "15-30D": {
        new: 18,
        returning: 16,
      },
      "1D": {
        new: 234,
        returning: 109,
      },
      "2D": {
        new: 47,
        returning: 7,
      },
      "3-7D": {
        new: 94,
        returning: 29,
      },
      "31-60D": {
        new: 5,
        returning: 19,
      },
      "8-14D": {
        new: 42,
        returning: 9,
      },
    },
    daysToPurchase: {
      avg: 2.9688338535366627,
      new: {
        avg: 1.7176449688704941,
      },
      returning: {
        avg: 6.197682231132807,
      },
    },
    ihc: {
      new: {
        closer: {
          direct: 287,
          email: 46,
          facebook: 212,
          google: 437,
          referral: 1,
          referred: 64,
          influencer_module: 0,
        },
        holder: {
          direct: 45,
          email: 19,
          facebook: 82,
          google: 105,
          referred: 30,
          influencer_module: 0,
        },
        initiator: {
          direct: 242,
          email: 27,
          facebook: 235,
          google: 491,
          referral: 1,
          referred: 51,
          influencer_module: 0,
        },
      },
      returning: {
        closer: {
          direct: 88,
          email: 59,
          facebook: 9,
          google: 68,
          referred: 15,
          influencer_module: 0,
        },
        holder: {
          direct: 14,
          email: 19,
          facebook: 15,
          google: 24,
          referred: 13,
          influencer_module: 0,
        },
        initiator: {
          direct: 59,
          email: 45,
          facebook: 24,
          google: 94,
          referred: 17,
          influencer_module: 0,
        },
      },
      closer: {
        direct: 375,
        email: 105,
        facebook: 221,
        google: 505,
        referral: 1,
        referred: 79,
        influencer_module: 0,
      },
      holder: {
        direct: 59,
        email: 38,
        facebook: 97,
        google: 129,
        referred: 43,
        influencer_module: 0,
      },
      initiator: {
        direct: 301,
        email: 72,
        facebook: 259,
        google: 585,
        referral: 1,
        referred: 68,
        influencer_module: 0,
      },
    },
    channelSpend: {
      facebook: 17068.339999999997,
      google: 16407.39,
      tiktok: 1433.69,
      influencer_module: 0,
    },
    maxSteps: 10,
    conversionPaths: {
      "facebook->tiktok": {
        spend: 0,
        newCustomers: 159,
        cac: 0,
        new: {
          purchaseCount: 159,
          purchaseAmount: 12421.09,
          aov: 69.39156424581006,
          journeys: 3514,
          cr: 0.050939100739897554,
          customers: 179,
          customerPercentage: 0.13919129082426127,
        },
        returning: {
          purchaseCount: 40,
          purchaseAmount: 1743.35,
          aov: 43.583749999999995,
          journeys: 346,
          cr: 0.11560693641618497,
          customers: 40,
          customerPercentage: 0.03110419906687403,
        },
        purchaseCount: 219,
        purchaseAmount: 14164.44,
        aov: 64.67780821917809,
        journeys: 3860,
        customers: 219,
        cr: 0.05673575129533679,
        avgConversionTime: 0,
        customerPercentage: 0.1702954898911353,
        ncr: 0.817351598173516,
        firstTouchpoint: "facebook",
        lastTouchpoint: "tiktok",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 3860,
            },
          },
        },
        steps: 1,
        path: "facebook->tiktok",
      },
      "facebook->direct": {
        spend: 0,
        newCustomers: 27,
        cac: 0,
        new: {
          purchaseCount: 27,
          purchaseAmount: 1965.94,
          aov: 72.8125925925926,
          journeys: 289,
          cr: 0.09342560553633218,
          customers: 27,
          customerPercentage: 0.020995334370139968,
        },
        returning: {
          purchaseCount: 10,
          purchaseAmount: 304.53,
          aov: 30.452999999999996,
          journeys: 78,
          cr: 0.1282051282051282,
          customers: 10,
          customerPercentage: 0.007776049766718507,
        },
        purchaseCount: 37,
        purchaseAmount: 2270.4700000000003,
        aov: 61.36405405405406,
        journeys: 367,
        customers: 37,
        cr: 0.1008174386920981,
        avgConversionTime: null,
        customerPercentage: 0.028771384136858476,
        ncr: 0.7297297297297297,
        firstTouchpoint: "direct",
        lastTouchpoint: "direct",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 734,
            },
          },
        },
        deltas: {
          "0": {
            avg: 59.434856191341204,
          },
          new: {
            "0": {
              avg: 49.029669357939255,
            },
          },
          returning: {
            "0": {
              avg: 97.98740740740742,
            },
          },
        },
        steps: 2,
        path: "facebook->direct",
      },
      "google->email": {
        spend: 0,
        newCustomers: 3,
        cac: 0,
        new: {
          purchaseCount: 3,
          purchaseAmount: 199.11,
          aov: 66.37,
          journeys: 25,
          cr: 0.12,
          customers: 3,
          customerPercentage: 0.0023328149300155523,
        },
        returning: {
          purchaseCount: 3,
          purchaseAmount: 130.71,
          aov: 43.57,
          journeys: 17,
          cr: 0.17647058823529413,
          customers: 3,
          customerPercentage: 0.0023328149300155523,
        },
        purchaseCount: 6,
        purchaseAmount: 329.82000000000005,
        aov: 54.970000000000006,
        journeys: 42,
        customers: 6,
        cr: 0.14285714285714285,
        avgConversionTime: null,
        customerPercentage: 0.004665629860031105,
        ncr: 0.5,
        firstTouchpoint: "direct",
        lastTouchpoint: "email",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 42,
              "email::AC_3 DE (Rg7rvh)::::19E55F0B4FF2979B8573ED274F84660B88D8163B7E06C3618B8D5E0B0B4CD31D": 1,
              "email::Copy of Zustellung erfolgt innerhalb der nächsten Stunden (SRjCmr)::::B527A8C7500D4EBD443089D2722B97A9B9A25B7B4B0744B2154BE4DB3F887689": 1,
              "email::DE_Vatertag_2_Sign_Up_Mail_29_04_2024 (01HSE28F2P1GQGR83P044JNEYR)::::BC8FEE6A029B79230B2A2DD79D364C0EB87628C14B3B5C73B8017D2D778211D5": 1,
              "email::DE_Vatertag_Hype_Mail_01_05_2024_Sign_Ups (01HSG1PDKA8RJKQMAN9MV5CDR7)::::F8C5502BB62652BBCA8B167B2D5B6AE9B282D0FE5F0C91B924D0894F80DE9209": 3,
              "email::DE_Vatertag_Hype_Mail_02_05_2024_Sale Start_All_Customers (01HSTBWVJCXSXPC7JABF9YVQF6)::::DDB08A863F2A09F44869737A12CDA971C1266EF6A01757725C98F4E2A921C6F1": 6,
              "email::DE_Vatertag_Hype_Mail_02_05_2024_Sale Start_Sign_Ups (01HSTA6CMXMM06J7F54X32CSDG)::::3DE0D4F72BBED40A0DEC0EE3850451B07CF3C882FF0504A0400E38670550C4B2": 11,
              "email::Email #1 (VVRhXs)::::33712B09EFD9399BC666E7A17D257F75327556CC115F2CDB07CD454382B5F08A": 2,
              "email::Email #1 (WHTP6W)::::0BBF372F22B1DF0539CB481043A0A285FFB19BF6895ADB4885C73EF69DD8AFE3": 4,
              "email::Email #2.1 (XJ8Y4Z)::::40C9940AA95162EBFD79D6937388A74FDDE634A772EFD0BDBF4AA3A74215FF57": 1,
              "email::Reviews je Produkt AC1 Trustpilot (UwS3AG)::::C766AC6D66EA7ED0EB1BE62EE34258CE059DD2578AB9C10B15CFFB809E5E2A25": 1,
              "email::Zustellung erfolgt innerhalb der nächsten Stunden (WFGSiu)::::2FDC70C705858145772FBE9397A01EA8E3D1C963BD40B773FB897FB04977F09F": 11,
            },
          },
        },
        deltas: {
          "0": {
            avg: 105.88181216931218,
          },
          new: {
            "0": {
              avg: 98.30624444444443,
            },
          },
          returning: {
            "0": {
              avg: 117.02235294117646,
            },
          },
        },
        steps: 2,
        path: "google->email",
      },
      "direct->referred->google": {
        spend: 1.2795470085470084,
        newCustomers: 0,
        cac: 0,
        new: {
          purchaseCount: 0,
          purchaseAmount: 0,
          aov: null,
          journeys: 0,
          cr: null,
          customers: 0,
          customerPercentage: 0,
        },
        returning: {
          purchaseCount: 1,
          purchaseAmount: 16.31,
          aov: 16.31,
          journeys: 1,
          cr: 1,
          customers: 1,
          customerPercentage: 0.0007776049766718507,
        },
        purchaseCount: 1,
        purchaseAmount: 16.31,
        aov: 16.31,
        journeys: 1,
        customers: 1,
        cr: 1,
        avgConversionTime: null,
        customerPercentage: 0.0007776049766718507,
        ncr: 0,
        firstTouchpoint: "direct",
        lastTouchpoint: "google",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 1,
              "google::max_16890672640::::5C50CAB87504545445CCE4F14E62CB7EF61D069651A61772FBF01FD2369CDD71": 1,
              "referred::https://www.google.com::::AC6BB669E40E44A8D9F8F0C94DFC63734049DCF6219AAC77F02EDF94B9162C09": 1,
            },
          },
        },
        deltas: {
          "0": {
            avg: 0.07611111111111112,
          },
          "1": {
            avg: 0.024444444444444446,
          },
          returning: {
            "0": {
              avg: 0.07611111111111112,
            },
            "1": {
              avg: 0.024444444444444446,
            },
          },
        },
        steps: 3,
        path: "direct->referred->google",
      },
      "pinterest->referred->google": {
        spend: 1.2795470085470084,
        newCustomers: 0,
        cac: 0,
        new: {
          purchaseCount: 0,
          purchaseAmount: 0,
          aov: null,
          journeys: 0,
          cr: null,
          customers: 0,
          customerPercentage: 0,
        },
        returning: {
          purchaseCount: 1,
          purchaseAmount: 16.31,
          aov: 16.31,
          journeys: 1,
          cr: 1,
          customers: 1,
          customerPercentage: 0.0007776049766718507,
        },
        purchaseCount: 1,
        purchaseAmount: 16.31,
        aov: 16.31,
        journeys: 1,
        customers: 1,
        cr: 1,
        avgConversionTime: null,
        customerPercentage: 0.0007776049766718507,
        ncr: 0,
        firstTouchpoint: "direct",
        lastTouchpoint: "google",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 1,
              "google::max_16890672640::::5C50CAB87504545445CCE4F14E62CB7EF61D069651A61772FBF01FD2369CDD71": 1,
              "referred::https://www.google.com::::AC6BB669E40E44A8D9F8F0C94DFC63734049DCF6219AAC77F02EDF94B9162C09": 1,
            },
          },
        },
        deltas: {
          "0": {
            avg: 0.07611111111111112,
          },
          "1": {
            avg: 0.024444444444444446,
          },
          returning: {
            "0": {
              avg: 0.07611111111111112,
            },
            "1": {
              avg: 0.024444444444444446,
            },
          },
        },
        steps: 3,
        path: "pinterest->referred->google",
      },
      "tiktok->referred->google": {
        spend: 1.2795470085470084,
        newCustomers: 0,
        cac: 0,
        new: {
          purchaseCount: 0,
          purchaseAmount: 0,
          aov: null,
          journeys: 0,
          cr: null,
          customers: 0,
          customerPercentage: 0,
        },
        returning: {
          purchaseCount: 1,
          purchaseAmount: 16.31,
          aov: 16.31,
          journeys: 1,
          cr: 1,
          customers: 1,
          customerPercentage: 0.0007776049766718507,
        },
        purchaseCount: 1,
        purchaseAmount: 16.31,
        aov: 16.31,
        journeys: 1,
        customers: 1,
        cr: 1,
        avgConversionTime: null,
        customerPercentage: 0.0007776049766718507,
        ncr: 0,
        firstTouchpoint: "direct",
        lastTouchpoint: "google",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 1,
              "google::max_16890672640::::5C50CAB87504545445CCE4F14E62CB7EF61D069651A61772FBF01FD2369CDD71": 1,
              "referred::https://www.google.com::::AC6BB669E40E44A8D9F8F0C94DFC63734049DCF6219AAC77F02EDF94B9162C09": 1,
            },
          },
        },
        deltas: {
          "0": {
            avg: 0.07611111111111112,
          },
          "1": {
            avg: 0.024444444444444446,
          },
          returning: {
            "0": {
              avg: 0.07611111111111112,
            },
            "1": {
              avg: 0.024444444444444446,
            },
          },
        },
        steps: 3,
        path: "tiktok->referred->google",
      },
      "google->facebook->google": {
        spend: 1.2795470085470084,
        newCustomers: 0,
        cac: 0,
        new: {
          purchaseCount: 0,
          purchaseAmount: 0,
          aov: null,
          journeys: 0,
          cr: null,
          customers: 0,
          customerPercentage: 0,
        },
        returning: {
          purchaseCount: 1,
          purchaseAmount: 16.31,
          aov: 16.31,
          journeys: 1,
          cr: 1,
          customers: 1,
          customerPercentage: 0.0007776049766718507,
        },
        purchaseCount: 1,
        purchaseAmount: 16.31,
        aov: 16.31,
        journeys: 1,
        customers: 1,
        cr: 1,
        avgConversionTime: null,
        customerPercentage: 0.0007776049766718507,
        ncr: 0,
        firstTouchpoint: "direct",
        lastTouchpoint: "google",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 1,
              "google::max_16890672640::::5C50CAB87504545445CCE4F14E62CB7EF61D069651A61772FBF01FD2369CDD71": 1,
              "referred::https://www.google.com::::AC6BB669E40E44A8D9F8F0C94DFC63734049DCF6219AAC77F02EDF94B9162C09": 1,
            },
          },
        },
        deltas: {
          "0": {
            avg: 0.07611111111111112,
          },
          "1": {
            avg: 0.024444444444444446,
          },
          returning: {
            "0": {
              avg: 0.07611111111111112,
            },
            "1": {
              avg: 0.024444444444444446,
            },
          },
        },
        steps: 3,
        path: "google->facebook->google",
      },
      "facebook->referred->google": {
        spend: 1.2795470085470084,
        newCustomers: 0,
        cac: 0,
        new: {
          purchaseCount: 0,
          purchaseAmount: 0,
          aov: null,
          journeys: 0,
          cr: null,
          customers: 0,
          customerPercentage: 0,
        },
        returning: {
          purchaseCount: 1,
          purchaseAmount: 16.31,
          aov: 16.31,
          journeys: 1,
          cr: 1,
          customers: 1,
          customerPercentage: 0.0007776049766718507,
        },
        purchaseCount: 1,
        purchaseAmount: 16.31,
        aov: 16.31,
        journeys: 1,
        customers: 1,
        cr: 1,
        avgConversionTime: null,
        customerPercentage: 0.0007776049766718507,
        ncr: 0,
        firstTouchpoint: "direct",
        lastTouchpoint: "google",
        ad_ids: {
          "": {
            "": {
              "direct::::::direct": 1,
              "google::max_16890672640::::5C50CAB87504545445CCE4F14E62CB7EF61D069651A61772FBF01FD2369CDD71": 1,
              "referred::https://www.google.com::::AC6BB669E40E44A8D9F8F0C94DFC63734049DCF6219AAC77F02EDF94B9162C09": 1,
            },
          },
        },
        deltas: {
          "0": {
            avg: 0.07611111111111112,
          },
          "1": {
            avg: 0.024444444444444446,
          },
          returning: {
            "0": {
              avg: 0.07611111111111112,
            },
            "1": {
              avg: 0.024444444444444446,
            },
          },
        },
        steps: 3,
        path: "facebook->referred->google",
      },
    },
  } as InfluencerCustomerJourneyBaseAnalytics;
};
