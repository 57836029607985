import React, { PropsWithChildren, ReactElement, useCallback } from "react";
import { Table } from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export function TablePaginationNew<T extends Record<string, unknown>>({
  table,
  tableRef,
}: PropsWithChildren<{
  table: Table<T>;
  tableRef: React.MutableRefObject<HTMLDivElement | null>;
}>): ReactElement | null {
  const handleChangePage = useCallback(
    (newPage: number) => {
      table.setPageIndex(Math.ceil(newPage - 1));
      tableRef?.current?.scrollTo({ top: 0, left: 0 });
    },
    [table, tableRef]
  );

  const buttonClassNames =
    "bg-gray-100 dark:bg-gray-800 hover:bg-gray-900 disabled:bg-transparent disabled:text-gray-400 disabled:cursor-not-allowed transition-colors duration-200 h-8 w-8 flex justify-center items-center rounded-md";
  return (
    <>
      {table.getPageCount() > 1 ? (
        <div className="flex items-center text-sm space-x-1">
          <button
            className={`${buttonClassNames}`}
            disabled={table.getState().pagination.pageIndex === 0}
            onClick={() =>
              handleChangePage(table.getState().pagination.pageIndex)
            }
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>
          <div className="h-8 min-w-[96px] flex items-center justify-center font-bold rounded-md bg-gray-100 dark:bg-gray-800">
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <button
            className={buttonClassNames}
            disabled={
              table.getState().pagination.pageIndex + 1 === table.getPageCount()
            }
            onClick={() =>
              handleChangePage(table.getState().pagination.pageIndex + 2)
            }
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      ) : null}
    </>
  );
}
