import { getLocalStorage } from "./getLocalStorage";

/**
 * Lightweight script to detect whether the browser is running in Private mode.
 * @return {Promise<boolean>}
 */
export function isPrivateMode() {
  return new Promise(function detect(resolve) {
    const yes = function () {
      resolve(true);
    }; // is in private mode
    const not = function () {
      resolve(false);
    }; // not in private mode

    function detectChromeOpera() {
      // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
      const isChromeOpera =
        /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) &&
        navigator.storage &&
        navigator.storage.estimate;
      if (isChromeOpera) {
        navigator.storage.estimate().then(function (data) {
          return (data.quota ?? 0) < 120000000 ? yes() : not();
        });
      }
      return !!isChromeOpera;
    }

    function detectFirefox() {
      const isMozillaFirefox =
        "MozAppearance" in document.documentElement.style;
      if (isMozillaFirefox) {
        if (indexedDB == null) yes();
        else {
          const db = indexedDB.open("inPrivate");
          db.onsuccess = not;
          db.onerror = yes;
        }
      }
      return isMozillaFirefox;
    }

    function detectSafari() {
      const isSafari = navigator.userAgent.match(
        /Version\/([0-9\._]+).*Safari/
      );
      if (isSafari) {
        const testLocalStorage = function () {
          try {
            if (getLocalStorage()?.length) not();
            else {
              getLocalStorage()?.setItem("inPrivate", "0");
              getLocalStorage()?.removeItem("inPrivate");
              not();
            }
          } catch (_) {
            // Safari only enables cookie in private mode
            // if cookie is disabled, then all client side storage is disabled
            // if all client side storage is disabled, then there is no point
            // in using private mode
            navigator.cookieEnabled ? yes() : not();
          }
          return true;
        };

        const version = parseInt(isSafari[1], 10);
        if (version < 11) return testLocalStorage();
        try {
          // @ts-ignore
          window.openDatabase(null, null, null, null);
          not();
        } catch (_) {
          yes();
        }
      }
      return !!isSafari;
    }

    function detectEdgeIE10() {
      const isEdgeIE10 =
        !window.indexedDB && (window.PointerEvent || window.PointerEvent);
      if (isEdgeIE10) yes();
      return !!isEdgeIE10;
    }

    // when a browser is detected, it runs tests for that browser
    // and skips pointless testing for other browsers.
    if (detectChromeOpera()) return;
    if (detectFirefox()) return;
    if (detectSafari()) return;
    if (detectEdgeIE10()) return;

    // default navigation mode
    return not();
  });
}
