import { APIError, ChiefAPIError } from "@api/types/backendTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { useAuth } from "../useAuth";
import { CreateWebhookSchema } from "./useCreateWebhook";
import { WebhookAPIResponse } from "./types";

const UpdateWebhookSchema = CreateWebhookSchema.extend({
  webhook_id: z.string(),
});
export type UpdateWebhookInput = z.infer<typeof UpdateWebhookSchema>;

const onUpdateWebhook = async (input: UpdateWebhookInput, token: string) => {
  if (!token) {
    throw new Error(
      "Invalid token provided! Please provide a valid kytron token."
    );
  }
  try {
    UpdateWebhookSchema.parse(input);
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_TRACY_BACKEND_BASE_URL}/webhook/update`,
      {
        ...input,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    return response.data as WebhookAPIResponse;
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as APIError;
      const firstErr = apiError.error;
      throw new Error(firstErr ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
};

export const useUpdateWebhook = () => {
  const queryClient = useQueryClient();
  const { data } = useAuth();
  return useMutation({
    mutationFn: (input: UpdateWebhookInput) =>
      onUpdateWebhook(input, data?.token ?? ""),
    onSuccess: async (data, values) => {
      // update all queries that might have the updated webhook in it
      queryClient.invalidateQueries({ queryKey: ["webhooks"] });
      queryClient.invalidateQueries({ queryKey: ["webhook"] });
    },
  });
};
