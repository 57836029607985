import React from "react";

interface Props {
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

type NativeAttrs = Omit<React.ButtonHTMLAttributes<any>, keyof Props>;
export type PaginationItemProps = Props & NativeAttrs;

const PaginationItem: React.FC<
  React.PropsWithChildren<PaginationItemProps>
> = ({ active, children, disabled, onClick, ...props }) => {
  const clickHandler = (event: React.MouseEvent) => {
    if (disabled) return;
    onClick && onClick(event);
  };

  return (
    <li>
      <button
        className={`border-none inline-flex items-center border justify-center box-border select-none capitalize whitespace-nowrap text-center align-middle shadow-none outline-none cursor-pointer rounded-md transition-all duration-200 ease-linear ${
          active && !disabled
            ? "active font-bold bg-primary text-on-primary shadow-sm hover:shadow-md hover:bg-primary-dark"
            : "text-foreground bg-gray-200 dark:bg-gray-700 border-gray-100 hover:bg-primary hover:text-on-primary"
        } ${
          disabled
            ? "disabled text-gray-500 cursor-not-allowed hover:bg-gray-200"
            : ""
        }`}
        onClick={clickHandler}
        {...props}
      >
        {children}
      </button>
      <style jsx>{`
        li {
          margin-right: 6px;
          display: inline-block;
        }
        button {
          height: var(--pagination-size);
          min-width: var(--pagination-size);
          font-size: inherit;
        }

        button :global(svg) {
          width: 1.3em;
          height: 1.3em;
        }
      `}</style>
    </li>
  );
};

export default PaginationItem;
