import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import useSelectedStores from "@lib/hooks/use-selected-stores";

const FetchAttributionDataSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
});

export type FetchAttributionDataProps = z.infer<
  typeof FetchAttributionDataSchema
>;

type CurrencyExchangeRates = {
  base: string;
  rates: {
    [currencyCode: string]: number;
  };
};

async function fetchCurrencyRates({
  token,
}: FetchAttributionDataProps): Promise<CurrencyExchangeRates> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/currency/list`,
      {
        bcode: "EUR",
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response?.data?.result ? response.data.result : {};
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage?.error ?? axiosError?.message);
    }

    throw new Error("Unknown error occurred! Please refresh the page.");
  }
}

export type UseAttributionDataOptions = {
  enabled?: boolean;
  disableRefetch?: boolean;
  onSuccess?: ((data: CurrencyExchangeRates) => void) | undefined;
};

export function useCurrencyRates(options?: UseAttributionDataOptions) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["currencyRates"],
    queryFn: () => {
      return fetchCurrencyRates({
        token: data?.token!,
      });
    },
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 30000, // 30 seconds needed to overwrite global settings
    gcTime: 30000, // 30 seconds
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
