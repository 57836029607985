import React, { useState, useRef, useEffect } from "react";
import {
  XMarkIcon,
  PlayIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import { useLocalStorage } from "@lib/hooks/use-local-storage";
import { Modal } from "@ui";
import { usePostHog } from "posthog-js/react";

type Props = {
  id: string;
  name: string;
  videoUrl: string;
};

const CollapsibleBanner = ({ id, name, videoUrl }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [expandedHeight, setExpandedHeight] = useState<number | undefined>(
    undefined
  );
  const [collapsedHeight, setCollapsedHeight] = useState<number | undefined>(
    undefined
  );

  const PADDING_TO_ACCOUNT = 32;
  const [lastDismissed, setLastDismissed] = useLocalStorage<string>(
    `tracify:education-banner-${id}`,
    { usingJson: false }
  );
  const [isCollapsedState, setIsCollapsedState] = useLocalStorage<boolean>(
    `tracify:education-banner-collapsed-${id}`,
    { usingJson: true }
  );
  const posthog = usePostHog();

  useEffect(() => {
    const updateHeights = () => {
      if (contentRef.current && headerRef.current) {
        setExpandedHeight(contentRef.current.getBoundingClientRect().height);
        setCollapsedHeight(
          headerRef.current.getBoundingClientRect().height + PADDING_TO_ACCOUNT
        ); // 32px for padding
      }
    };

    const resizeObserver = new ResizeObserver(updateHeights);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    updateHeights();
    return () => resizeObserver.disconnect();
  }, [isCollapsedState]);

  const getEmbedUrl = (url: string) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}?autoplay=1&modestbranding=1`
      : url;
  };

  const isVisible = !lastDismissed;

  const handlePermanentDismiss = () => {
    setLastDismissed(new Date().toISOString());
    posthog.capture("Educational banner dismissed");
  };

  const handleCollapse = () => {
    setIsCollapsedState(true);
    posthog.capture("Educational banner collapsed");
  };

  const handleExpand = () => {
    setIsCollapsedState(false);
    posthog.capture("Educational banner expanded");
  };

  const handleVideoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
    posthog.capture("Educational banner video clicked");
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        className="w-full overflow-hidden relative rounded-md mt-2 transition-all duration-300"
        style={{
          background:
            "linear-gradient(90deg, #4D4799 0%, #8176FF 50%, #4D4799 100%)",
          height: isCollapsedState ? collapsedHeight : expandedHeight,
        }}
      >
        <div ref={contentRef} className="max-w-7xl mx-auto p-4">
          <div className="flex flex-col items-center text-center">
            <div ref={headerRef}>
              <h2 className="text-lg font-semibold text-white font-space-grotesk">
                Get to know the {name}
              </h2>
            </div>
            <div
              className={`mt-2 space-y-4 transition-all duration-300 ${
                isCollapsedState
                  ? "opacity-0 translate-y-[-20px]"
                  : "opacity-100 translate-y-0"
              }`}
            >
              <p className="text-white/90 text-sm font-space-grotesk">
                We put various guides together to help you make the most out of
                the {name}.
              </p>
              <button
                onClick={handleVideoClick}
                className="inline-flex bg-white text-primary font-bold px-4 py-2 rounded-md items-center gap-1 hover:bg-white/90 transition-colors"
              >
                <PlayIcon className="h-6 w-6" />
                Watch video
              </button>
            </div>
          </div>

          <div className="absolute right-4 top-3">
            {isCollapsedState ? (
              <button
                onClick={handleExpand}
                className="text-white hover:bg-white/10 p-2 rounded-full transition-all duration-500"
              >
                <ChevronDownIcon className="w-4 h-4 transition-transform duration-300" />
              </button>
            ) : (
              <DropdownMenu>
                <DropdownMenuTrigger className="text-white hover:bg-white/10 p-2 rounded-full transition-colors">
                  <XMarkIcon className="w-4 h-4" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuItem onClick={handleCollapse}>
                    Collapse for now
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handlePermanentDismiss}>
                    Don&apos;t show this again
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        width="large"
        className="max-h-full"
      >
        <div
          className="relative w-full max-w-full overflow-hidden max-h-full"
          style={{ paddingTop: "56.25%" }}
        >
          <iframe
            src={getEmbedUrl(videoUrl)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute inset-0 w-full h-full !important aspect-video"
            title={`${name} Video Guide`}
          />
        </div>
      </Modal>
    </>
  );
};

export default CollapsibleBanner;
