import axios, { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { invalidateToken, useAuth } from "../useAuth";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import { useMemo } from "react";
import {
  AnalyticsJob,
  AnalyticsJobListFetchRequest,
  AnalyticsJobListRequest,
} from "./types";
import { useAtom } from "jotai";
import { endTimeAtom, startTimeAtom } from "atoms";
import dayjs from "dayjs";

async function fetchAnalyticsJobs({
  session,
  maxDate,
  minDate,
}: AnalyticsJobListFetchRequest): Promise<Array<AnalyticsJob>> {
  if (!session) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/metrics/jobs`,
      {
        max_date: maxDate,
        min_date: minDate,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": session,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // TODO parse payloads to dashboard state

    // Inferred return type: Promise<MyData>
    return response.data.result.jobs as Array<AnalyticsJob>;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const errorMessage = axiosError.response?.data as {
        error: string;
        success: boolean;
      };
      throw new Error(errorMessage.error ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

function useAnalyticsJobs<TData = Array<AnalyticsJob>>(
  props?: AnalyticsJobListRequest,
  options?: UseQueryOptions<
    Array<AnalyticsJob>,
    AxiosError,
    TData,
    (
      | string
      | {
          minDate: string;
          maxDate: string;
        }
    )[]
  >
) {
  const { data } = useAuth();
  const { selectedStoreIds } = useSelectedStores();
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);

  const enabled = useMemo(() => {
    const defaultEnabled =
      !!data?.token && !!selectedStoreIds && selectedStoreIds?.length > 0;
    if (options?.enabled) {
      return options.enabled && defaultEnabled;
    }
    return defaultEnabled;
  }, [options, data, selectedStoreIds]);

  // at least 14 days in the past, but else use startTime
  const minDate = dayjs(
    Math.min(
      dayjs(startTime).unix(),
      dayjs().startOf("day").subtract(14, "days").unix()
    ) * 1000
  ).format("YYYY-MM-DD HH:mm:ss");
  const maxDate = dayjs(endTime).format("YYYY-MM-DD HH:mm:ss");

  return useQuery({
    queryKey: ["analyticsJobs", { minDate, maxDate }],
    queryFn: () =>
      fetchAnalyticsJobs({
        ...props,
        session: data?.token!,
        maxDate,
        minDate,
      }),

    ...options,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000, // 60 seconds
    enabled: enabled,
  });
}

export { useAnalyticsJobs };
