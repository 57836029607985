// eslint-disable-next-line max-classes-per-file
import forge from "node-forge";

class TUtils {
  static hash(text: string) {
    const md = forge.md.sha256.create();
    md.update(text);
    return md.digest().toHex().toUpperCase();
  }

  static filterArrayByString<T>(mainArr: T[], searchText: string) {
    if (searchText === "") {
      return mainArr;
    }

    const lowerSearchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) =>
      this.searchInObj(itemObj, lowerSearchText)
    );
  }

  static searchInObj(itemObj: any, searchText: string) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
    return false;
  }

  static searchInArray<T>(arr: T[], searchText: string) {
    arr.forEach((value: T) => {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  static searchInString(value: string, searchText: string) {
    return value.toLowerCase().includes(searchText);
  }

  static generateGUID() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4();
  }

  static toggleInArray(item: never, array: never[]) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text: { toString: () => string }) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/\W+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  // static randomMatColor(hue: string) {
  //   const newHue = hue || '400';
  //   const mainColors = [
  //     'red',
  //     'pink',
  //     'purple',
  //     'deepPurple',
  //     'indigo',
  //     'blue',
  //     'lightBlue',
  //     'cyan',
  //     'teal',
  //     'green',
  //     'lightGreen',
  //     'lime',
  //     'yellow',
  //     'amber',
  //     'orange',
  //     'deepOrange',
  //   ];
  //   const randomColor =
  //     mainColors[Math.floor(Math.random() * mainColors.length)];
  //   return colors[randomColor][newHue];
  // }
}

export default TUtils;
