import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";
import { invalidateToken, useAuth } from "../useAuth";
import { transformToWebhook } from "./transformations";
import { Webhook } from "./types";
import { APIError, ChiefAPIError } from "@api/types/backendTypes";

const UseWebhookPropsSchema = z.object({
  webhookId: z.string(),
});

const FetchWebhookSchema = z.object({
  webhookId: z.string(),
  token: z.string({ required_error: "User Session not available" }),
});

export type UseWebhookProps = z.infer<typeof UseWebhookPropsSchema>;

export type FetchWebhookProps = z.infer<typeof FetchWebhookSchema>;

async function fetchWebhook({
  token,
  webhookId,
}: FetchWebhookProps): Promise<Webhook | null> {
  if (!token) {
    throw new Error("User Session not available");
  }
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_TRACY_BACKEND_BASE_URL}/webhook/read`,
      { webhook_id: webhookId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    // TODO transform API data to our type
    return response.data?.result
      ? transformToWebhook({ webhook_id: webhookId, ...response.data?.result })
      : null;
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      const axiosError = err as AxiosError;
      const apiError = axiosError.response?.data as APIError;
      const firstErr = apiError.error;
      throw new Error(firstErr ?? axiosError.message);
    }
    throw new Error("Unkown error occurred!");
  }
}

export type UseWebhookOptions = {
  enabled?: boolean;
  onSuccess?: ((data: Webhook | null) => void) | undefined;
};

export function useWebhook(
  props: UseWebhookProps,
  options?: UseWebhookOptions
) {
  const { data } = useAuth();
  return useQuery({
    queryKey: ["webhook", { ...props }],
    queryFn: () => {
      return fetchWebhook({ ...props, token: data?.token! });
    },

    ...options,
    refetchOnWindowFocus: false,
    enabled:
      options?.enabled !== undefined
        ? options.enabled && !!data?.token
        : !!data?.token,
  });
}
