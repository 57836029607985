import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRadioContext } from "./radio-context";
import { getRadioSize } from "./radio-group";
import RadioDescription from "./radio-description";
import { NormalSizes } from "../../lib/util-types";
import { pickChild } from "../../lib/util-functions";
import s from "./radio.module.css";

interface RadioEventTarget {
  checked: boolean;
}

export interface RadioEvent {
  target: RadioEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: React.ChangeEvent;
}

interface Props {
  checked?: boolean;
  value?: string | number;
  size?: NormalSizes;
  className?: string;
  disabled?: boolean;
  onChange?: (e: RadioEvent) => void;
}

type NativeAttrs = Omit<React.InputHTMLAttributes<any>, keyof Props>;
export type RadioProps = Props & NativeAttrs;

export const Radio = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<RadioProps>
>(
  (
    {
      checked,
      onChange,
      value: radioValue,
      children,
      size = "medium" as NormalSizes,
      disabled = false,
      className = "",
      ...props
    },
    ref: React.Ref<HTMLInputElement | null>
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => checkboxRef.current);
    const [selfChecked, setSelfChecked] = useState<boolean>(!!checked);
    const {
      value: groupValue,
      disabledAll,
      inGroup,
      updateState,
    } = useRadioContext();
    const [withoutDescChildren, DescChildren] = pickChild(
      children,
      RadioDescription
    );

    useEffect(() => {
      if (inGroup) {
        setSelfChecked(groupValue === radioValue);
      }
    }, [groupValue, inGroup, radioValue]);

    const fontSize = useMemo(() => getRadioSize(size), [size]);
    const isDisabled = useMemo(
      () => disabled || disabledAll,
      [disabled, disabledAll]
    );
    const changeHandler = (event: React.ChangeEvent) => {
      if (isDisabled) return;
      const selfEvent: RadioEvent = {
        target: {
          checked: !selfChecked,
        },
        stopPropagation: event.stopPropagation,
        preventDefault: event.preventDefault,
        nativeEvent: event,
      };
      setSelfChecked(!selfChecked);
      if (inGroup) {
        updateState && updateState(radioValue as string | number);
      }
      onChange && onChange(selfEvent);
    };

    useEffect(() => {
      if (checked === undefined) return;
      setSelfChecked(Boolean(checked));
    }, [checked]);
    const style = { "--radio-size": fontSize } as React.CSSProperties;
    return (
      <div
        className={`${s.radio} flex items-start relative ${className}`}
        style={style}
      >
        <label
          className={`flex flex-col justify-start ${
            isDisabled
              ? "text-gray-500 cursor-not-allowed"
              : "text-foreground cursor-pointer"
          }`}
        >
          <input
            type="radio"
            ref={checkboxRef}
            value={radioValue}
            checked={selfChecked}
            onChange={changeHandler}
            className={`${s["radio-input"]} opacity-0 invisible overflow-hidden fixed h-px w-px`}
            {...props}
          />
          <span className="name text-xs select-none inline-flex items-center">
            <div
              className={`rounded-full w-4 h-4 border ${
                selfChecked ? "border-primary" : "border-primary"
              } relative`}
            >
              <div
                className={`${
                  s["inner-point"]
                } absolute w-3 h-3 transform transition-all duration-200 ease-out ${
                  selfChecked
                    ? "bg-primary opacity-1 scale-90"
                    : "bg-white opacity-0 scale-0"
                } rounded-full`}
              />
            </div>
            <div className="w-2" />
            {withoutDescChildren}
          </span>
          {DescChildren && DescChildren}
        </label>
      </div>
    );
  }
);

Radio.displayName = "Radio";
export default Radio;
