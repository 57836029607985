import React from "react";

type CardProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  isDarkMode?: boolean;
  padding?: string;
  margin?: string;
  className?: string;
  type?: "default" | "dark";
};

export function CardContainer(props: CardProps) {
  const {
    children,
    title,
    padding,
    margin,
    className,
    type = "default",
  } = props;

  return (
    <div
      className={`${
        type === "dark"
          ? "bg-gray-100 dark:bg-gray-800"
          : "bg-gray-200 dark:bg-gray-700"
      } rounded-lg h-full relative flex flex-col
      ${padding ?? "px-3 py-2 xl:px-4 xl:py-4"}  ${
        margin ?? "my-3 lg:my-4"
      } ${className}`}
    >
      {title && (
        <div className="text-s text-foreground font-medium  mb-8">{title}</div>
      )}
      {children}
    </div>
  );
}
