import { EventListRequest, EventSumResult } from "@api/types/backendTypes";
import axios, { AxiosError } from "axios";

export type RequestEventListProps = {
  requestBody: EventListRequest;
  backendUrl: string;
  token: string;
};

/**
 * Submits an events request
 *
 * @param {RequestEventListProps} props
 * @param {EventListRequest} props.requestBody Events to be fetched.
 * @param {string} props.backendUrl Backend URL for API request
 * @param {string} props.token (JWT-) Session token
 * @return {Promise<EventSumResult>} Promise which resolves to EventSumResult or rejects with error message
 */
export function requestEventList({
  requestBody,
  backendUrl,
  token,
}: RequestEventListProps): Promise<EventSumResult> {
  return new Promise((resolve, reject) => {
    axios
      .post(`${backendUrl}/event/sum`, requestBody, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": token,
        },
      })
      .then(function (response) {
        // handle success
        if (response.status !== 200) {
          reject(
            new Error(
              `Could not request event summary: ${response.data?.error}`
            )
          );
        } else {
          const result: EventSumResult = {
            totals: {
              total_amount: response.data?.result?.total_amount,
              total_count: response.data?.result?.total_count,
              total_nitems: response.data?.result?.total_nitems,
            },
            daily: response.data?.result?.daily,
          };
          resolve(result);
        }
      })
      .catch(function (error) {
        // handle error
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          reject(new Error(`Events request failed: ${axiosError.message}`));
        } else {
          reject(new Error(`Event summary request failed for unknown reason!`));
        }
      });
  });
}
