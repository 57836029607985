import RateDisplay from "@components/Cards/RateDisplay";
import useFormat from "@lib/hooks/use-format";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import React from "react";
import { getTotalValueOfAllRows, getCorrelation } from "./calculations";

type Props<T> = {
  compared?: boolean;
} & (
  | {
      info: CellContext<T, number | undefined>;
      calculateTotals?: false;
    }
  | {
      info: HeaderContext<T, number | undefined>;
      calculateTotals: true;
    }
);

export type CurrencyCalculatorType = (
  value: number,
  numDigits?: number
) => string;

const getDisplayString = (
  value: number,
  formatNumber: CurrencyCalculatorType
) => {
  return `${formatNumber(value * 100, 2)}%`;
};

function ConversionRateTableDisplay<T extends Record<string, any>>({
  info,
  compared,
  calculateTotals,
}: Props<T>) {
  const { formatNumber } = useFormat();
  let comparedValue = 0;
  let value = 0;
  if (calculateTotals) {
    const allOrderValues = info.column.getFacetedRowModel().rows.map((row) => {
      return {
        value: row.original?.["purchaseCount"],
        compared: row.original?.compared?.["purchaseCount"],
      };
    });
    const allPageviewValues = info.column
      .getFacetedRowModel()
      .rows.map((row) => {
        return {
          value: row.original?.["pageview"],
          compared: row.original?.compared?.["pageview"],
        };
      });
    const orderTotals = getTotalValueOfAllRows({
      values: allOrderValues,
      roundBeforeAdding: true,
    });
    const pageviewTotals = getTotalValueOfAllRows({
      values: allPageviewValues,
      roundBeforeAdding: true,
    });

    value =
      pageviewTotals.value && orderTotals.value
        ? orderTotals.value / pageviewTotals.value
        : 0;
    comparedValue =
      pageviewTotals.compared && orderTotals.compared
        ? orderTotals.compared / pageviewTotals.compared
        : 0;
  } else {
    value = info.getValue() ?? 0;
    if (compared && info.row.original?.compared) {
      comparedValue = info.row.original?.compared[info.column?.id];
    }
  }
  if (compared) {
    const rate = getCorrelation(value, comparedValue, 4);
    return (
      <div className="flex items-center w-full">
        <div className="flex-1">
          <p>{getDisplayString(value, formatNumber)}</p>
          <p className="text-[11px] leading-tight opacity-70">
            vs {getDisplayString(comparedValue, formatNumber)}
          </p>
        </div>
        <RateDisplay rate={rate} isIncreasing={rate > 0} size="small" />
      </div>
    );
  }
  return getDisplayString(value, formatNumber);
}

export default ConversionRateTableDisplay;
