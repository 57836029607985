import axios, { AxiosError } from "axios";

import { useMutation, useQuery } from "@tanstack/react-query";
import { invalidateToken, useAuth } from "../useAuth";
import { z } from "zod";

export type SettingsResponseType = {
  currency?: string;
  enable_nvr?: boolean;
  is_24_clock_type?: boolean;
  max_nvd_lookback?: number;
  number_format?: string;
  time_format?: string;
};

const FetchSettingsSchema = z.object({
  token: z.string({ required_error: "User Session not available" }),
});

export type FetchSettingsProps = z.infer<typeof FetchSettingsSchema>;

async function fetchSettings({
  token,
}: FetchSettingsProps): Promise<SettingsResponseType | null> {
  if (token) {
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/account/settings/get`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": token,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(response.data.error);
      }
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const result = response.data.result;
      return result;
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const errorMessage = axiosError.response?.data as {
          error: string;
          success: boolean;
        };
        throw new Error(errorMessage?.error ?? axiosError?.message);
      }
      if (err.message === "Token is invalid. Please sign in again.") {
        throw err;
      }
      throw new Error("Unknown error occurred! Please refresh the page.");
    }
  }
  throw new Error("No token available. Please sign in again.");
}

function useSettings() {
  const { data } = useAuth();
  const settingsQuery = useQuery({
    queryKey: ["settings"],
    queryFn: () => fetchSettings({ token: data?.token! }),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    gcTime: 5000,
    enabled: !!data?.token,
  });

  async function updateSettings(newSettings: SettingsResponseType) {
    try {
      if (!data?.token)
        throw new Error("No token available. Please sign in again.");

      return await axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/account/settings/update`,
        newSettings,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "tracify-token": data?.token,
          },
        }
      );
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (!err.response) {
          throw err;
        }
        const axiosError = err as AxiosError;
        const errorMessage = axiosError.response?.data as {
          error: string;
          success: boolean;
        };
        // throw new Error(errorMessage?.error ?? axiosError?.message);
      }
      //   throw new Error("Unknown error occurred while updating settings.");
    }
  }

  return {
    settingsQuery,
    updateSettings,
  };
}

export { useSettings };
