import {
  Webhook,
  WebhookAPIResponse,
  WebhookSubmission,
  WebhookSubmissionAPIResponse,
  WebhookSubmissionsAPIResponse,
  WebhooksAPIResponse,
  WebhooksFormType,
} from "./types";
import dayjs from "dayjs";
import "dayjs/plugin/timezone";
import "dayjs/plugin/utc";
import "dayjs/locale/en";
dayjs.locale("en");

export const transformToWebhook = (webhook: WebhookAPIResponse) => {
  // very simplistic way to determine it, but works for now with the limited options we offer
  const scheduleType =
    webhook.schedule?.due_times?.length === 1
      ? "weekly"
      : webhook.schedule?.due_times?.length === 7
        ? "daily"
        : "6_hours";
  const transformedWebhook: Webhook = {
    id: webhook.webhook_id,
    name: webhook.name,
    active: webhook.active,
    data: webhook.webhook.job_template.include_influencer_data
      ? "influencer"
      : "attribution",
    schedule: {
      type: scheduleType,
      dueTimes: webhook.schedule.due_times,
    },
    webhook: {
      webhookUrl: webhook.webhook.webhook_url,
      formatVersion: webhook.webhook.format_version,
      hmacSecret: webhook.webhook.hmac_secret,
      jobTemplate: {
        ushapeBorder: webhook.webhook.job_template.ushape_border,
        dateRange: webhook.webhook.job_template.date_range,
        attributionMethod: webhook.webhook.job_template.attribution_method,
        attributionWindow: String(
          webhook.webhook.job_template.attribution_window
        ) as Webhook["webhook"]["jobTemplate"]["attributionWindow"],
        reportType: webhook.webhook.job_template.report_type,
        utcOffset: webhook.webhook.job_template.utc_offset,
        includeInfluencerData:
          webhook.webhook.job_template.include_influencer_data,
        includeNewvsreturning:
          webhook.webhook.job_template.include_newvsreturning,
        includeAdNames: webhook.webhook.job_template.include_ad_names,
        useDaily: webhook.webhook.job_template.use_daily,
        touchpoints: webhook.webhook.job_template.touchpoints,
        csids: webhook.webhook.job_template.csids,
      },
    },
  };
  return transformedWebhook;
};

export const transformToWebhooks = (data: WebhooksAPIResponse) => {
  const webhooks: Array<Webhook> = data.map((webhook) =>
    transformToWebhook(webhook)
  );
  return webhooks;
};

export const getDueTimesFromScheduleName = (
  schedule: Webhook["schedule"]["type"]
) => {
  const dueTimes: Webhook["schedule"]["dueTimes"] = [];
  // daily/weekly webhooks should ideally run between 7-10 AM GMT+2 (5-8 AM UTC) where load on system is low
  // also webhooks shouldn't run between 22PM - 2AM GMT+2 (20-00 UTC) because we do database maintainance on thursday during that time
  if (schedule === "weekly") {
    const randomNumberBetween7And10 = Math.random() * (4 - 1) + 5;
    const nextMorning = dayjs()
      .add(1, "day")
      .startOf("day")
      .add(randomNumberBetween7And10, "hours");

    const time = nextMorning.format("HH:mm:ss");
    const weekday = nextMorning.format("dddd").toLowerCase();
    dueTimes.push({ time, weekday });
  } else if (schedule === "daily") {
    const randomNumberBetween7And10 = Math.random() * (4 - 1) + 5;
    const nextMorning = dayjs()
      .add(1, "day")
      .startOf("day")
      .add(randomNumberBetween7And10, "hours");
    for (const daysToAdd of Array.from({ length: 7 }, (v, i) => i)) {
      const currentDate = nextMorning.add(daysToAdd, "days");
      const time = currentDate.format("HH:mm:ss");
      const weekday = currentDate.format("dddd").toLowerCase();
      dueTimes.push({ time, weekday });
    }
  } else if (schedule === "6_hours") {
    // this schedule can only run between +-2.15-3.45AM GMT+2 (0.15-1.45 AM UTC), else we will fall into the maintainance window on thursday
    const randomNumber = Math.random() * (1.75 - 0.25) + 0.25;
    const thisMorning = dayjs().startOf("day").add(randomNumber, "hours");
    // we can fit 4 runs per day with a 6 hour schedule
    for (const hoursToAdd of Array.from({ length: 7 * 4 }, (v, i) => i * 6)) {
      const currentDate = thisMorning.add(hoursToAdd, "hours");
      const time = currentDate.format("HH:mm:ss");
      const weekday = currentDate.format("dddd").toLowerCase();
      dueTimes.push({ time, weekday });
    }
  }
  return dueTimes;
};

const generateHmac = () => {
  const array = new Uint32Array(10);
  crypto.getRandomValues(array);
  return Buffer.from(array.buffer).toString("base64");
};

export const transformFormDataToAPIWebhook = ({
  formData,
  webhook,
}: {
  formData: WebhooksFormType;
  webhook?: Webhook;
}) => {
  const dueTimes =
    formData.schedule === webhook?.schedule?.type
      ? webhook.schedule.dueTimes
      : getDueTimesFromScheduleName(formData.schedule);
  if (
    formData.touchpoints.includes("referred") &&
    !formData.touchpoints.includes("referral")
  ) {
    formData.touchpoints.push("referral");
  }
  const webhookData: WebhookAPIResponse = {
    name: "Webhook",
    active: true,
    schedule: {
      due_times: dueTimes,
    },
    webhook: {
      format_version: "v1",
      hmac_secret: webhook?.webhook?.hmacSecret ?? generateHmac(),
      webhook_url: formData.webhookUrl,
      job_template: {
        attribution_method: formData.attributionModel,
        attribution_window: parseInt(`${formData.attributionWindow}`),
        csids: formData.csids,
        date_range: formData.dateRange.replaceAll("_", ""),
        include_influencer_data: formData.includeInfluencerData,
        include_newvsreturning: formData.includeNewvsreturning,
        include_ad_names: true,
        ushape_border: 0.1,
        report_type: formData.reportTime,
        touchpoints: formData.touchpoints,
        use_daily: formData.useDaily,
        utc_offset: formData.utcOffset,
      },
    },
  } as WebhookAPIResponse;

  if (webhook) webhookData.webhook_id = webhook.id;
  return webhookData;
};

export const transformToWebhookSubmission = (
  data: WebhookSubmissionAPIResponse
) => {
  const submission: WebhookSubmission = {
    webhookUrl: data.webhook_url,
    formatId: data.format_id,
    formatVersion: data.format_version,
    hmacSecret: data.hmac_secret,
    hookOrigin: data.hook_origin,
    hookReferer: data.hook_referer,
    hookUserAgent: data.hook_user_agent,
    hs512Signature: data.hs512_signature,
    manuallyTriggered: data.manually_triggered,
    statusCode: data.status_code,
    submissionDate: data.submission_date,
  };
  return submission;
};

export const transformToWebhookSubmissions = (
  data: WebhookSubmissionsAPIResponse
) => {
  const submissions: Array<WebhookSubmission> = data.map((submission) =>
    transformToWebhookSubmission(submission)
  );
  return submissions;
};
